import axios, { AxiosError } from "axios"
import { AUTHENTICATION_API_SERVICE_DOMAIN, DOCUMENTS_API_SERVICE_DOMAIN, LIST_PATH, USER_PATH } from "lib"
import { ICharts } from "model/ChartsModel"
import { IAprobarORechazar } from "model/EmialAprobarORechazar"

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })
const intanceUsers = axios.create({ baseURL: AUTHENTICATION_API_SERVICE_DOMAIN })

export const wsObtenerCharts = async () => {
    try {
        const result = await intance.get(`/charts`)
        return result.data as ICharts
    } catch (error:any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}