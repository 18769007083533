import { Fab, Tooltip, Grid, Icon, InputAdornment, Paper, TextField, Table, TableContainer, TableHead, TableRow, TableCell, TableSortLabel, TableBody, IconButton, Typography, MenuItem } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import CurrencyInput from 'components/CurrencyInput/CurrencyInput'
import { ManagementContext } from 'context'
import { useTableModel } from 'hooks'
import { FIELDS } from 'lib'
import { RETENTION_TAX_COLUMNS } from 'lib/constants/tax'
import React from 'react'
import { ITax, ITaxType } from 'types'
import { IRetentionTax } from 'types/retention'

type Props = {
    taxes: IRetentionTax[]
    onAdd: (tax: IRetentionTax) => void
    onEdit: (tax: IRetentionTax) => void
    onDelete: (tax: IRetentionTax) => void
}

const RetentionTaxes = (props: Props) => {
    const { onAdd, taxes: retentiontaxes, onDelete } = props
    const { taxes, taxTypes, loading } = React.useContext(ManagementContext)
    const { createSortHandler, order, orderBy, rows } = useTableModel()
    const [newTax, setNewTax] = React.useState({ taxBase: 0 } as IRetentionTax)
    const [type, setType] = React.useState<ITaxType | undefined>(undefined)

    const getRetentionTaxes = React.useCallback((): ITax[] => {
        const retentionTaxTypes = taxTypes.filter(t => t.forRetention).map(t => t.taxId)
        return type === undefined
            ? taxes.filter(t => retentionTaxTypes.includes(t.taxTypeId))
            : taxes.filter(t => (retentionTaxTypes.includes(t.taxTypeId) && t.taxTypeId === type.taxId))
    }, [taxes, taxTypes, type])

    const total = React.useMemo(() => retentiontaxes.map(r => r.taxBase).reduce((a, b) => (a + b), 0), [retentiontaxes])

    return (
        <React.Fragment>
            <Paper className="h-full flex flex-col">
                <div className="flex mb-2 overflow-y-auto">
                    <TableContainer>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {RETENTION_TAX_COLUMNS.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={'left'}
                                            padding={"none"}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                            style={{ paddingLeft: 15, paddingTop: 5, paddingBottom: 5 }}
                                        >
                                            <TableSortLabel
                                                style={{ fontSize: "0.85em" }}
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id as any)}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className="hidden">
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        padding={"default"}
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rows(retentiontaxes) as IRetentionTax[]).map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index.toString() + "taxBase"}
                                        >
                                            <TableCell style={{ paddingLeft: 15 }} padding="none" align="left">{row.taxCode}</TableCell>
                                            <TableCell style={{ paddingLeft: 15 }} padding="none" align="left">{taxTypes.find(t => t.taxId === row.taxTypeId)?.name}</TableCell>
                                            <TableCell style={{ paddingLeft: 15 }} padding="none" align="left">{row.description}</TableCell>
                                            <TableCell style={{ paddingLeft: 15 }} padding="none" align="left">{row.percentage.toFixed(2) + "%"}</TableCell>
                                            <TableCell style={{ paddingLeft: 15 }} padding="none" align="left">{row.taxBase}</TableCell>
                                            <TableCell style={{ paddingLeft: 15 }} padding="none" align="left">{((row.taxBase * (row.percentage / 100))).toFixed(2)}</TableCell>
                                            <TableCell padding="none" align="right">
                                                <Tooltip style={{ marginLeft: 10 }} arrow title="Eliminar detalle">
                                                    <IconButton onClick={() => onDelete(row)} size="small">
                                                        <Icon color="primary" fontSize="small">close</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className='flex w-full justify-end px-6 py-2'>
                    <Typography
                        style={{ fontWeight: 800, opacity: 0.75, letterSpacing: 0.25 }}
                        color="textSecondary"
                        variant="body2"
                    >
                        {`Total retenido: ${total.toFixed(2)}`}
                    </Typography>
                </div>
                <Grid
                    component={"form"}
                    container
                    spacing={1}
                    justifyContent="flex-end"
                    style={{ paddingTop: 15, paddingBottom: 15, alignItems: "center" }}
                    onSubmit={(e: React.FormEvent) => {
                        e.preventDefault()
                        onAdd(newTax)
                        setNewTax({ taxBase: 0 } as IRetentionTax)
                    }}
                >
                    <Grid
                        item
                        xs={3}
                    >
                        <TextField
                            select
                            label="Tipo de impuesto"
                            value={type?.name ?? undefined}
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={(e) => {
                                const value = e.target.value
                                setType(taxTypes.find(t => t.taxId.toString() === value.toString()))
                            }}
                        >
                            {taxTypes.filter(t => t.forRetention).map((option) => (
                                <MenuItem key={option.taxId} value={option.taxId}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                    >
                        <Autocomplete
                            options={getRetentionTaxes()}
                            loadingText="Cargando..."
                            noOptionsText="No existen impuestos registrados"
                            getOptionLabel={(tax) => tax.description}
                            fullWidth
                            loading={loading}
                            value={newTax}
                            openOnFocus
                            size="small"
                            onChange={(_, value) => setNewTax(current => ({ ...current, ...value }))}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    variant="outlined"
                                    fullWidth
                                    label={"Impuesto"}
                                />
                            )}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={2}
                    >
                        <TextField
                            variant="outlined"
                            size="small"
                            label={FIELDS.taxBase.name}
                            onFocus={event => { event.target.select() }}
                            required
                            fullWidth
                            value={newTax.taxBase ?? "0"}
                            onChange={(e) => setNewTax(current => ({
                                ...current,
                                taxBase: parseFloat(e.target.value)
                            }))}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon fontSize="small" color="action">attach_money</Icon>
                                    </InputAdornment>
                                ),
                                inputComponent: CurrencyInput as any,
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        justifyContent="center"
                        style={{ display: "flex" }}
                    >
                        <Tooltip arrow title="Agregar nuevo impuesto">
                            <Fab type='submit' size='small' color="primary">
                                <Icon>add</Icon>
                            </Fab>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    )
}

export default RetentionTaxes