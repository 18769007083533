import {
    LinearProgress,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { wsObtenerArchivosDraft, wsObtenerFileDraft } from "api/APIDraft"
import {
    DocumentPicker,
    FixedLoadingIndicator,
    LabelDisplayedRows,
    ServerErrorHandler
} from "components"
import { AuthContext, DocumentContext, ManagementContext } from "context"
import {
    DOCUMENT_TITLE,
    DOCUMENT_TYPES,
    DRAFTS_TABLE_COLUMNS,
    FIELDS,
    MEDIUM_ROWS_PER_PAGINATION_OPTIONS,
    ROOT_TITLE
} from "lib"
import moment from 'moment'
import React from "react"
import { Helmet } from "react-helmet"
import { useHistory } from "react-router"
import { IArchivo, IDocument, IDocumentAdditionalInfo, IMessageConfig } from "types"

interface Props {
    initialCode?: string
    initialState?: number
    initialHistoryType?: number
    typePath?: string
    redirectOnApprove?: string
    redirectOnReject?: string
    hideState?: boolean
    hideType?: boolean
    hideProcess?: boolean
    forEmission?: boolean
    forReception?: boolean
    showCounts?: boolean
    issuingCompany?: boolean
    receivingCompany?: boolean
    countPath?: string
    createRetentionOnly?: boolean
}

const DraftsDashboard = (props: Props) => {
    const {
        initialCode,
        issuingCompany,
        initialState,
        initialHistoryType,
        receivingCompany,
        createRetentionOnly
    } = props
    const {
        documents,
        company,
        documentsModel,
        documentTypes,
        totalDocuments,
        getDocumentsByInitialFilters,
        getNextDraftsByFilters,
    } = React.useContext(DocumentContext)
    const { user } = React.useContext(AuthContext)
    const { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage, } = documentsModel
    const history = useHistory()
    const { refreshIdentificationTypes, refreshTaxes, refreshTaxTypes, identificationTypes, taxTypes, taxes } = React.useContext(ManagementContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info", })
    const [openPicker, setOpenPicker] = React.useState<boolean>(false)
    const [openFilters, setOpenFilters] = React.useState<boolean>(false)

    const handleNewPage = async (pageNumber: number, offset: number) => {
        try {
            setLoading(true)
            await getNextDraftsByFilters(user?.userId!, offset, pageNumber)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(true)
        }
    }

    const obtenerPathEdicion = (document: IDocument) => {
        if (document.documentTypeId == 13) {
            return (`/document/new-document/employee-reimbursement`)
        } else {
            return (`/document/new-document/cash-reimbursement`)
        }
    }

    const obtenerTipoDeDocumento = (document: IDocument) => {
        if (document.documentTypeId == 13) {
            return DOCUMENT_TYPES.find(d => d.type === "employee-reimbursement")!
        } else {
            return DOCUMENT_TYPES.find(d => d.type === "cash-reimbursement")!
        }
    }

    const handleSelect = async (document: IDocument) => {
        try {
            setLoading(true)
            if (identificationTypes.length === 0) { refreshIdentificationTypes() }
            if (taxTypes.length === 0) { refreshTaxTypes() }
            if (taxes.length === 0) { refreshTaxes() }
            const file: IDocumentAdditionalInfo = await wsObtenerFileDraft(document.documentId);
            const archivos: IArchivo[] = await wsObtenerArchivosDraft(document.documentId);
            console.log(archivos, 'ARCHIVOS')
            history.push({
                state: { document: document, file: file, type: obtenerTipoDeDocumento(document), archivos: transformarArchivosAFiles(archivos) },
                pathname: obtenerPathEdicion(document),
            })
            setLoading(false)
        } catch (error) {
            console.log(error)
            setMessageConfig({ open: true, message: "No se pudo obtener información del documento selecionado...", severity: "error" })
            setLoading(false)
        }
    }

    const transformarArchivosAFiles = (archivos: IArchivo[] | undefined) => {
        const files: File[] = [];
        if (archivos !== undefined && archivos.length > 0) {
            archivos.forEach((archivo) => {
                const blob = new Blob([archivo.content], { type: archivo.type });
                const file = new File([blob], archivo.nombre, { type: archivo.type });
                files.push(file);
            });
        }
        return files;
    }

    const getModel = React.useCallback((): any[] => { return (initialLoading ? [] : documents) }, [documents, initialLoading])


    React.useEffect(() => {
        const initScreen = async () => {
            try {
                setInitialLoading(true)
                await getNextDraftsByFilters(user?.userId!, 20, 0)
                handleChangePage(undefined, 0)
                setInitialLoading(false)
            } catch (error) {
                setInitialLoading(false)
                setError(true)
            }
        }
        initScreen()
    }, [initialCode, getDocumentsByInitialFilters, history, initialState, handleChangePage, issuingCompany, company, initialHistoryType, receivingCompany])
    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${DOCUMENT_TITLE}`}</title>
            </Helmet>
            <div className="flex h-full flex-col mt-4 overflow-hidden">
                {
                    initialLoading &&
                    <LinearProgress />
                }
                <div className="flex flex-grow overflow-auto">
                    <TableContainer>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {(DRAFTS_TABLE_COLUMNS).map((headCell) => (
                                        (
                                            (createRetentionOnly && headCell.id !== FIELDS.processStatus.key) || !createRetentionOnly
                                        ) &&
                                        <TableCell
                                            key={headCell.id}
                                            align={"left"}
                                            padding={"default"}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : "asc"}
                                                onClick={createSortHandler(headCell.id as any)}
                                                style={{ fontSize: "0.85em", width: (headCell.id === FIELDS.companyName.key || headCell.id === FIELDS.receiverName.key) ? 320 : (headCell.id === FIELDS.flowStatus.key || headCell.id === FIELDS.processStatus.key) ? 250 : "max-content" }}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className="hidden">
                                                        {order === "desc"
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell padding={"default"} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rows(getModel()) as IDocument[]).map(
                                    (row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.documentId.toString()}
                                                className="cursor-pointer"
                                                onClick={(loading || initialLoading) ? undefined : () => handleSelect(row)}
                                            >
                                                <TableCell className="truncate" component="th" scope="row" padding="none">
                                                    {documentTypes.find(d => d.documentTypeId === row.documentTypeId)?.documentName}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {/* Se añade una hora a la fecha, ya que el servidor de producción tiene una hora adelantada
                                                    y el documentDate en la base tiene como hora 00:00:00 -5, y el servidor tiene +1 */}
                                                    {moment(new Date(row.documentDate)).add(1, "hour").format('DD-MM-YYYY').toString()}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {row.authorizationDate ? moment(new Date(row.authorizationDate).toISOString()).format('DD-MM-YYYY').toString() : "---"}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {issuingCompany ? row.receiverRuc : row.companyRuc}
                                                </TableCell>
                                                <TableCell className="truncate" padding="default" align="left">
                                                    {issuingCompany ? row.receiverName : row.companyName}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    En Borrador
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    <TablePagination
                        rowsPerPageOptions={MEDIUM_ROWS_PER_PAGINATION_OPTIONS}
                        component="div"
                        count={totalDocuments}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={async (event, pageNumber) => {
                            if (pageNumber > page && ((pageNumber + 1) * rowsPerPage) > getModel().length && getModel().length < totalDocuments) {
                                await handleNewPage(pageNumber, rowsPerPage)
                            }
                            handleChangePage(event, pageNumber)
                        }}
                        onRowsPerPageChange={async (event) => {
                            if (parseInt(event.target.value, 10) > rowsPerPage && parseInt(event.target.value, 10) > getModel().length && getModel().length < totalDocuments) {
                                await handleNewPage(page, parseInt(event.target.value, 10))
                            }
                            handleChangeRowsPerPage(event as any)
                        }}
                        labelRowsPerPage="Documentos por página"
                        labelDisplayedRows={LabelDisplayedRows}
                    />
                </div>
            </div >
            <Snackbar
                open={messageConfig.open}
                autoHideDuration={6000}
                onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Alert
                    variant="filled"
                    onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                    severity={messageConfig.severity}
                >
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading} />
            <DocumentPicker
                open={openPicker}
                onClose={() => setOpenPicker(false)}
            />
            <ServerErrorHandler
                error={error}
                onSuccess={() => setError(false)}
                tryAgain={getDocumentsByInitialFilters}
            />
        </Paper >
    )
}

export default DraftsDashboard