import axios, { AxiosError } from "axios"
import { DOCUMENTS_API_SERVICE_DOMAIN } from "lib"

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })

export const getTemplatesEmail = async () => {
    try {
        const result = await intance.get('/templates-mail')
        return result.data
    } catch (error:any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}
