export const REPORT_TYPES = [
    {
        name: "Simple",
        id: 1,
        type: "state-types-documentDate-receptionDate",
        key: "simple"
    },
    {
        name: "Impuestos",
        id: 2,
        type: "year-month",
        key: "tax"
    },
    {
        name: "ATS",
        id: 3,
        type: "year-month",
        key: "ats"
    },
]

export const RETENTION_REPORT_TYPES = [
    {
        name: "Reporte por Estados",
        id: 1,
        type: "state-documentDate-receptionDate",
        key: "state"
    },
    {
        name: "Reporte por Contabilizar",
        id: 2,
        type: "documentDate-receptionDate",
        key: "retention"
    },
    {
        name: "Reporte Contabilizado",
        id: 3,
        type: "documentDate-receptionDate",
        key: "accounting"
    },
    {
        name: "Reporte reclamo de montos",
        id: 4,
        type: "documentDate-receptionDate",
        key: "reclaim"
    },
    {
        name: "Reporte RET TOTAL V",
        id: 5,
        type: "documentDate-receptionDate",
        key: "test1"
    },
    {
        name: "Reporte CONTABILIZAR NUEVO",
        id: 6,
        type: "documentDate-receptionDate",
        key: "test2"
    },
    {
        name: "Reporte Consolidacion AUX vs CONT",
        id: 7,
        type: "year-month",
        key: "conciliar"
    },
    {
        name: "OTC Sumarizado de Retenciones",
        id: 8,
        type: "documentDate-receptionDate",
        key: "otc"
    },
    {
        name: "RTR Detallado de Retenciones",
        id: 9,
        type: "documentDate-receptionDate",
        key: "rtr"
    },
]

export const REPORT_CONDITIONS_IDS = [1, 2, 4, 5, 6, 18, 19]
export const RETENTION_REPORT_CONDITIONS_IDS = [2, 5, 27, 4, 24]
export const REPORT_TYPES_IDS = [1, 10, 12, 13, 14, 41]