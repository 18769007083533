import React from 'react'
import { Button, Divider, Grid, Icon, Popover, Snackbar, Switch, TextField, Typography } from '@material-ui/core'
import { deleteSupplierFileByIdService, FIELDS } from 'lib'
import { IMenu, IMessageConfig, ISupplier, IUploadDocumentFile, SupplierFileMap, SupplierFileType, SupplierUploadedFileMap } from 'types'
import { Alert } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import FileUploadDialog from 'components/FileUploadDialog/FileUploadDialog'
import moment from 'moment'

interface Props {
    handleChange: (key: string, value: any) => void
    supplier: ISupplier
    supplierFileMap: SupplierFileMap
    setSupplierFileMap: React.Dispatch<React.SetStateAction<SupplierFileMap>>
    supplierUploadedFileMap: SupplierUploadedFileMap
    onSuccessfulDelete?: () => void
    edit?: boolean
}

const SupplierFilesForm = (props: Props) => {
    const { supplier, handleChange, setSupplierFileMap, supplierUploadedFileMap, onSuccessfulDelete, edit } = props
    const [menu, setMenu] = React.useState<IMenu>({ anchorEl: null, selected: undefined })
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const [selectedTag, setSelectedTag] = React.useState<SupplierFileType | undefined>()
    const handleAccept = (files: IUploadDocumentFile[]) => {
        if (selectedTag) {
            setSupplierFileMap(current => ({
                ...current, 
                [selectedTag]: current[selectedTag].concat(files) 
            }))
        }
    }
    const handleDelete = React.useCallback(async (supplierFileId: number) => {
        await deleteSupplierFileByIdService(supplierFileId)
        setMenu({ selected: undefined, anchorEl: null })
    }, [])

    return (
        <div className="w-full h-auto flex flex-wrap justify-between items-start">
            <Grid container spacing={1} alignItems={"center"}>
            <Grid item container spacing={1} alignItems={"center"} style={{ paddingTop: 8}}>
                    <Grid item xs={3} style={{display:"flex", height: "100%"}} alignItems={"center"}>
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography color="textSecondary" variant="body2" align="center" style={{ fontWeight: 800, letterSpacing: 0.5, fontSize: 12}}>
                            Fecha Desde
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography color="textSecondary" variant="body2" align="center" style={{ fontWeight: 800, letterSpacing: 0.5, fontSize: 12}}>
                            Fecha Hasta
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography color="textSecondary" variant="body2" align="center" style={{ fontWeight: 800, letterSpacing: 0.5, fontSize: 12}}>
                            Correo
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container spacing={1} alignItems={"center"} style={{ paddingTop: 8}}>
                    <Grid item xs={3} style={{display:"flex", height: "100%"}} alignItems={"center"}>
                        <Typography color="textSecondary" variant="body2" style={{ fontWeight: 800, letterSpacing: 0.5, fontSize: 13}}>
                            Documentos básicos   
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{ height: 40 }} className="flex flex-row items-center">
                            <Button
                                className="h-full"
                                variant="outlined"
                                disableElevation
                                fullWidth
                                size="small"
                                component="span"
                                onClick={() => {
                                    setSelectedTag("basic-documents")
                                }}
                            >
                                <div className="flex-grow flex pl-1 truncate" style={{ textTransform: "none", fontSize: "0.9rem" }}>
                                    <p className="truncate" style={{ maxWidth: 180 }}>
                                        {`${supplierUploadedFileMap["basic-documents"].length} archivos`}
                                    </p>
                                </div>
                                <Divider orientation="vertical" />
                                <Icon fontSize="small" color={"action"} className="mx-2">publish</Icon>
                            </Button>
                        </div>    
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                </Grid>
                <Grid item container spacing={1} alignItems={"center"}>
                    <Grid item xs={3} style={{display:"flex", height: "100%"}} alignItems={"center"}>
                        <Typography color="textSecondary" variant="body2" style={{ fontWeight: 800, letterSpacing: 0.5, fontSize: 13}}>
                            Código de Conducta   
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                    <div style={{ height: 40 }} className="flex flex-row items-center">
                        <Button
                            className="h-full"
                            variant="outlined"
                            disableElevation
                            fullWidth
                            size="small"
                            component="span"
                            onClick={() => {
                                setSelectedTag("account-code")
                            }}
                        >
                            <div className="flex-grow flex pl-1 truncate" style={{ textTransform: "none", fontSize: "0.9rem" }}>
                                <p className="truncate" style={{ maxWidth: 180 }}>
                                    {`${supplierUploadedFileMap["account-code"].length} archivos`}
                                </p>
                            </div>
                            <Divider orientation="vertical" />
                            <Icon fontSize="small" color={"action"} className="mx-2">publish</Icon>
                        </Button>
                    </div>   
                    </Grid>
                    <Grid item xs={2}>
                        <KeyboardDatePicker
                            className="date-input"
                            autoOk
                            invalidDateMessage={FIELDS.invalidDate.name}
                            InputAdornmentProps={{ position: "start", style: { margin: 0 } }}
                            value={supplier[FIELDS.fileMetaData.key]?.["account-code"]?.[FIELDS.startDate.key] ? moment(supplier[FIELDS.fileMetaData.key]?.["account-code"]?.[FIELDS.startDate.key], "DD/MM/YYYY")?.toDate() : null}
                            onChange={(date) => {
                                handleChange(FIELDS.fileMetaData.key, 
                                    {
                                        ...supplier.fileMetaData, 
                                        "account-code": {
                                            ...supplier.fileMetaData?.["account-code"], startDate: moment(date).format("DD/MM/YYYY")
                                            }
                                        }
                                    )
                            }}
                            inputVariant="outlined"
                            fullWidth
                            emptyLabel={FIELDS.emptyDate.name}
                            required
                            size="small"
                            variant="inline"
                            format="dd/MM/yyyy"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <KeyboardDatePicker
                            className="date-input"
                            autoOk
                            invalidDateMessage={FIELDS.invalidDate.name}
                            InputAdornmentProps={{ position: "start", style: { margin: 0 } }}
                            value={supplier[FIELDS.fileMetaData.key]?.["account-code"]?.[FIELDS.endDate.key] ? moment(supplier[FIELDS.fileMetaData.key]?.["account-code"]?.[FIELDS.endDate.key], "DD/MM/YYYY")?.toDate() : null}
                            onChange={(date) => {
                                handleChange(FIELDS.fileMetaData.key, 
                                    {
                                        ...supplier.fileMetaData, 
                                        "account-code": {
                                            ...supplier.fileMetaData?.["account-code"], endDate: moment(date).format("DD/MM/YYYY")
                                            }
                                        }
                                    )
                            }}
                            inputVariant="outlined"
                            fullWidth
                            emptyLabel={FIELDS.emptyDate.name}
                            required
                            size="small"
                            variant="inline"
                            format="dd/MM/yyyy"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            size="small"
                            variant="outlined"
                            value={supplier[FIELDS.fileMetaData.key]?.["account-code"]?.[FIELDS.email.key]}
                            onChange={(e) => {
                                handleChange(FIELDS.fileMetaData.key, 
                                    {
                                        ...supplier.fileMetaData, 
                                        "account-code": {
                                            ...supplier.fileMetaData?.["account-code"], email: e.target.value
                                            }
                                        }
                                    )
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={1} alignItems={"center"}>
                    <Grid item xs={2} style={{display:"flex", height: "100%"}} alignItems={"center"}>
                        <Typography color="textSecondary" variant="body2" style={{ fontWeight: 800, letterSpacing: 0.5, fontSize: 13}}>
                            Contrato   
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Switch
                            checked={Boolean(supplier[FIELDS.antiCorruptionAgreement.key])}
                            onChange={(e) => handleChange(FIELDS.antiCorruptionAgreement.key, e.target.checked ? "true" : "")}
                            size="small"
                            color="primary"    
                        />
                    </Grid>
                    <Grid item xs={3}>
                    <div style={{ height: 40 }} className="flex flex-row items-center">
                        <Button
                            className="h-full"
                            variant="outlined"
                            disableElevation
                            fullWidth
                            size="small"
                            component="span"
                            onClick={() => {
                                setSelectedTag("contract")
                            }}
                            disabled={!Boolean(supplier[FIELDS.antiCorruptionAgreement.key])}
                        >
                            <div className="flex-grow flex pl-1 truncate" style={{ textTransform: "none", fontSize: "0.9rem" }}>
                                <p className="truncate" style={{ maxWidth: 180 }}>
                                    {`${supplierUploadedFileMap["contract"].length} archivos`}
                                </p>
                            </div>
                            <Divider orientation="vertical" />
                            <Icon fontSize="small" color={"action"} className="mx-2">publish</Icon>
                        </Button>
                    </div>   
                    </Grid>
                    <Grid item xs={2}>
                        <KeyboardDatePicker
                            className="date-input"
                            autoOk
                            invalidDateMessage={FIELDS.invalidDate.name}
                            InputAdornmentProps={{ position: "start", style: { margin: 0 } }}
                            value={supplier[FIELDS.fileMetaData.key]?.["contract"]?.[FIELDS.startDate.key] ? moment(supplier[FIELDS.fileMetaData.key]?.["contract"]?.[FIELDS.startDate.key], "DD/MM/YYYY")?.toDate() : null}
                            onChange={(date) => {
                                handleChange(FIELDS.fileMetaData.key, 
                                    {
                                        ...supplier.fileMetaData, 
                                        "contract": {
                                            ...supplier.fileMetaData?.["contract"], startDate: moment(date).format("DD/MM/YYYY")
                                            }
                                        }
                                    )
                            }}
                            inputVariant="outlined"
                            fullWidth
                            emptyLabel={FIELDS.emptyDate.name}
                            required
                            size="small"
                            variant="inline"
                            format="dd/MM/yyyy"
                            disabled={!Boolean(supplier[FIELDS.antiCorruptionAgreement.key])}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <KeyboardDatePicker
                            className="date-input"
                            autoOk
                            invalidDateMessage={FIELDS.invalidDate.name}
                            InputAdornmentProps={{ position: "start", style: { margin: 0 } }}
                            value={supplier[FIELDS.fileMetaData.key]?.["contract"]?.[FIELDS.endDate.key] ? moment(supplier[FIELDS.fileMetaData.key]?.["contract"]?.[FIELDS.endDate.key], "DD/MM/YYYY")?.toDate() : null}
                            onChange={(date) => {
                                handleChange(FIELDS.fileMetaData.key, 
                                    {
                                        ...supplier.fileMetaData, 
                                        "contract": {
                                            ...supplier.fileMetaData?.["contract"], endDate: moment(date).format("DD/MM/YYYY")
                                            }
                                        }
                                    )
                            }}
                            inputVariant="outlined"
                            fullWidth
                            emptyLabel={FIELDS.emptyDate.name}
                            required
                            size="small"
                            variant="inline"
                            format="dd/MM/yyyy"
                            disabled={!Boolean(supplier[FIELDS.antiCorruptionAgreement.key])}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            size="small"
                            variant="outlined"
                            disabled={!Boolean(supplier[FIELDS.antiCorruptionAgreement.key])}
                            value={supplier[FIELDS.fileMetaData.key]?.["contract"]?.[FIELDS.email.key]}
                            onChange={(e) => {
                                handleChange(FIELDS.fileMetaData.key, 
                                    {
                                        ...supplier.fileMetaData, 
                                        "contract": {
                                            ...supplier.fileMetaData?.["contract"], email: e.target.value
                                            }
                                        }
                                    )
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={1} alignItems={"center"}>
                    <Grid item xs={2} style={{display:"flex", height: "100%"}} alignItems={"center"}>
                        <Typography color="textSecondary" variant="body2" style={{ fontWeight: 800, letterSpacing: 0.5, fontSize: 13}}>
                            Due Diligence  
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Switch
                            checked={Boolean(supplier[FIELDS.dueDilligenceAgreement.key])}
                            onChange={(e) => handleChange(FIELDS.dueDilligenceAgreement.key, e.target.checked ? "true" : "")}
                            size="small"
                            color="primary"    
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{ height: 40 }} className="flex flex-row items-center">
                            <Button
                                className="h-full"
                                variant="outlined"
                                disableElevation
                                fullWidth
                                size="small"
                                component="span"
                                disabled={!Boolean(supplier[FIELDS.dueDilligenceAgreement.key])}
                                onClick={() => {
                                    setSelectedTag("due-diligence")
                                }}
                            >
                                <div className="flex-grow flex pl-1 truncate" style={{ textTransform: "none", fontSize: "0.9rem" }}>
                                    <p className="truncate" style={{ maxWidth: 180 }}>
                                        {`${supplierUploadedFileMap["due-diligence"].length} archivos`}
                                    </p>
                                </div>
                                <Divider orientation="vertical" />
                                <Icon fontSize="small" color={"action"} className="mx-2">publish</Icon>
                            </Button>
                        </div>   
                    </Grid>
                    <Grid item xs={2}>
                        <KeyboardDatePicker
                            className="date-input"
                            autoOk
                            invalidDateMessage={FIELDS.invalidDate.name}
                            InputAdornmentProps={{ position: "start", style: { margin: 0 } }}
                            value={supplier[FIELDS.fileMetaData.key]?.["due-diligence"]?.[FIELDS.startDate.key] ? moment(supplier[FIELDS.fileMetaData.key]?.["due-diligence"]?.[FIELDS.startDate.key], "DD/MM/YYYY")?.toDate() : null}
                            onChange={(date) => {
                                handleChange(FIELDS.fileMetaData.key, 
                                    {
                                        ...supplier.fileMetaData, 
                                        "due-diligence": {
                                            ...supplier.fileMetaData?.["due-diligence"], startDate: moment(date).format("DD/MM/YYYY")
                                            }
                                        }
                                    )
                            }}
                            inputVariant="outlined"
                            fullWidth
                            emptyLabel={FIELDS.emptyDate.name}
                            required
                            size="small"
                            variant="inline"
                            format="dd/MM/yyyy"
                            disabled={!Boolean(supplier[FIELDS.dueDilligenceAgreement.key])}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <KeyboardDatePicker
                            className="date-input"
                            autoOk
                            invalidDateMessage={FIELDS.invalidDate.name}
                            InputAdornmentProps={{ position: "start", style: { margin: 0 } }}
                            value={supplier[FIELDS.fileMetaData.key]?.["due-diligence"]?.[FIELDS.endDate.key] ? moment(supplier[FIELDS.fileMetaData.key]?.["due-diligence"]?.[FIELDS.endDate.key], "DD/MM/YYYY")?.toDate() : null}
                            onChange={(date) => {
                                handleChange(FIELDS.fileMetaData.key, 
                                    {
                                        ...supplier.fileMetaData, 
                                        "due-diligence": {
                                            ...supplier.fileMetaData?.["due-diligence"], endDate: moment(date).format("DD/MM/YYYY")
                                            }
                                        }
                                    )
                            }}
                            inputVariant="outlined"
                            fullWidth
                            emptyLabel={FIELDS.emptyDate.name}
                            required
                            size="small"
                            variant="inline"
                            format="dd/MM/yyyy"
                            disabled={!Boolean(supplier[FIELDS.dueDilligenceAgreement.key])}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            size="small"
                            variant="outlined"
                            disabled={!Boolean(supplier[FIELDS.dueDilligenceAgreement.key])}
                            value={supplier[FIELDS.fileMetaData.key]?.["due-diligence"]?.[FIELDS.email.key]}
                            onChange={(e) => {
                                handleChange(FIELDS.fileMetaData.key, 
                                    {
                                        ...supplier.fileMetaData, 
                                        "due-diligence": {
                                            ...supplier.fileMetaData?.["due-diligence"], email: e.target.value
                                            }
                                        }
                                    )
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={1} alignItems={"center"}>
                    <Grid item xs={2} style={{display:"flex", height: "100%"}} alignItems={"center"}>
                        <Typography color="textSecondary" variant="body2" style={{ fontWeight: 800, letterSpacing: 0.5, fontSize: 13}}>
                            {/* {FIELDS.requirePo.name} */}
                            Órden de compra
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Switch
                            checked={Boolean(supplier[FIELDS.requirePo.key])}
                            onChange={(e) => handleChange(FIELDS.requirePo.key, e.target.checked)}
                            size="small"
                            color="primary"    
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{ height: 40 }} className="flex flex-row items-center"></div>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                </Grid>
                {
                    edit &&
                    <Grid item container spacing={1} alignItems={"center"}>
                    <Grid item xs={2} style={{display:"flex", height: "100%"}} alignItems={"center"}>
                        <Typography color="textSecondary" variant="body2" style={{ fontWeight: 800, letterSpacing: 0.5, fontSize: 13}}>
                            {FIELDS.activeAlt.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Switch
                            checked={Boolean(supplier[FIELDS.active.key])}
                            onChange={(e) => handleChange(FIELDS.active.key, e.target.checked)}
                            size="small"
                            color="primary"    
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{ height: 40 }} className="flex flex-row items-center"></div>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                </Grid>
                }
            </Grid>
            <FileUploadDialog
                open={Boolean(selectedTag)}
                onClose={() => {
                    setSelectedTag(undefined)
                }}
                singleFile={selectedTag === "account-code" || selectedTag === "due-diligence"}
                hideDescription
                onAccept={handleAccept}
                supplierUploadedFiles={selectedTag ? supplierUploadedFileMap[selectedTag] : []}
                onSuccessfulDelete={onSuccessfulDelete}
            />
            <Popover
                open={Boolean(menu.anchorEl)}
                anchorEl={menu.anchorEl}
                onClose={() => setMenu({ selected: undefined, anchorEl: null })}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="flex flex-col p-4 items-center justify-center">
                    <Typography>
                        {"¿Estás seguro de eliminar este archivo?"}
                    </Typography>
                    <div className="mt-3">
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            style={{ marginRight: 5 }}
                            onClick={() => handleDelete(menu.selected.supplierFileId)}
                        >
                            {"Si"}
                        </Button>
                        <Button
                            size="small"
                            color="primary"
                            onClick={() => setMenu({ selected: undefined, anchorEl: null })}
                        >
                            {"No"}
                        </Button>
                    </div>
                </div>
            </Popover>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default SupplierFilesForm
