import { Button, CircularProgress, TextField, Typography, Icon, IconButton, FormControlLabel, FormGroup, Switch, Divider, MenuItem, Select, Input, List, Avatar, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Snackbar, Box } from '@material-ui/core'
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab'
import { PhoneInput } from 'components'
import { ManagementContext } from 'context'
import { CONTACT_TYPES, createCustomerService, editCustomerService, EDIT_PATH, FIELDS } from 'lib'
import React from 'react'
import { useLocation, useHistory } from 'react-router'
import { IContact, ICustomer, IMessageConfig } from 'types'
import isEmail from 'validator/lib/isEmail'

interface Props {
    onSuccess: () => void
    onError: (message?: string) => void
    onClose: () => void
}

const CustomerForm = (props: Props) => {
    const location = useLocation()
    const history = useHistory()
    const { onError, onSuccess, onClose } = props
    const { categories, identificationTypes, taxPlans, taxTypes } = React.useContext(ManagementContext)
    const initialInput = React.useRef<any>(null)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [editIndex, setEditIndex] = React.useState<number>(-1)
    const [customer, setCustomer] = React.useState<ICustomer>({ active: true, contacts: [] as any } as ICustomer)
    const [contact, setContact] = React.useState<IContact>({ active: true } as IContact)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const handleSumbit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setLoading(true)
            if (!(location.state as any)?.edit) {
                await createCustomerService({
                    identification: customer.identification,
                    identificationType: customer.identificationType.identificationTypeId,
                    companyName: customer.companyName,
                    commercialName: customer.commercialName,
                    specialTaxpayer: customer.specialTaxpayer,
                    keepAccounts: customer.keepAccounts,
                    active: customer.active,
                    categoryId: customer.category?.categoryId,
                    contacts: customer.contacts
                })
            } else {
                const edited = {
                    identification: customer.identification,
                    identificationType: customer.identificationType.identificationTypeId,
                    companyName: customer.companyName,
                    commercialName: customer.commercialName,
                    specialTaxpayer: customer.specialTaxpayer,
                    keepAccounts: customer.keepAccounts,
                    active: customer.active,
                    categoryId: customer.category?.categoryId,
                    contacts: customer.contacts
                }
                await editCustomerService(customer.customerId, { ...edited })
            }
            setLoading(false)
            if (!(location.state as any)?.edit) {
                setCustomer({ active: true, contacts: [] as any } as ICustomer)
                initialInput.current?.focus()
            }
            onSuccess()
        } catch (error: any) {
            setLoading(false)
            onError(error.message ?? undefined)
        }
    }
    React.useEffect(() => {
        if ((location.state as any)?.edit) {
            if ((location.state as any)?.values) {
                setCustomer((location.state as any)?.values)
            }
        }
        else {
            history.replace(location.pathname.replace(EDIT_PATH, ""))
        }
    }, [location.state, history, location.pathname])


    const addedEmail = (contact: IContact) => { 
        console.log(customer.contacts);
        return customer.contacts !== null && Boolean(customer.contacts.map(c => c.email).includes(contact.email)) 
    }

    const handleContact = (e: React.FormEvent) => {
        e.preventDefault()
        if (addedEmail(contact) && editIndex === -1) {
            setMessageConfig({ open: true, severity: "error", message: "Correo ya agregado" })
            return
        }
        if (!isEmail(contact.email)) {
            setMessageConfig({ open: true, severity: "error", message: "Ingresa un email válido" })
            return
        }
        if(customer.contacts !== null){
            setCustomer(current => ({ ...current, contacts: editIndex !== -1 ? [...current.contacts].map((c, i) => i === editIndex ? contact : c) : [...current.contacts, contact] }))
        }else{
            setCustomer(current => ({ ...current, contacts: [contact] }))
        }
        setContact({ active: true } as IContact)
        setEditIndex(-1)
    }

    const handleDeleteContact = (contact: IContact) => {
        setCustomer(current => ({ ...current, contacts: current.contacts.filter(c => c.email !== contact.email) }))
        setEditIndex(-1)
    }

    const handleEditContact = (contact: IContact, index: number) => {
        setContact(contact)
        setEditIndex(index)
    }

    const handleCancelEdit = () => {
        setContact({ active: true } as IContact)
        setEditIndex(-1)
    }

    return (
        <div className="p-4">
            <div className="flex items-center justify-between">
                <Typography variant="body2" style={{ fontWeight: 600, letterSpacing: 0.5 }}>
                    {(location.state as any)?.edit ? "Editar cliente" : "Nuevo cliente"}
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <Icon fontSize="small">close</Icon>
                </IconButton>
            </div>
            <div className="pt-4 flex flex-col" style={{ width: '100%' }}>
                <div className="flex w-full">
                    <form onSubmit={handleSumbit} className="w-1/2 pb-24 px-4 relative">
                        <TextField
                            inputRef={initialInput}
                            autoFocus
                            size="small"
                            label={FIELDS.identification.name}
                            variant="outlined"
                            fullWidth
                            required
                            value={customer.identification ?? ""}
                            onChange={(e) => setCustomer(current => ({ ...current, identification: e.target.value }))}
                        />
                        <Autocomplete
                            options={identificationTypes}
                            getOptionLabel={(identificationType) => identificationType.name}
                            fullWidth
                            value={customer.identificationType ? customer.identificationType : null}
                            openOnFocus
                            size="small"
                            style={{ marginTop: 10 }}
                            onChange={(e, selectedValue) => {
                                if (selectedValue) {
                                    setCustomer(current => ({ ...current, identificationType: selectedValue }))
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    label={"Tipo de identificación"}
                                    required
                                />
                            )}
                        />
                        <Autocomplete
                            options={categories}
                            getOptionLabel={(category) => category.name}
                            fullWidth
                            value={customer.category ? customer.category : null}
                            openOnFocus
                            size="small"
                            style={{ marginTop: 10 }}
                            onChange={(e, selectedValue) => {
                                if (selectedValue) {
                                    setCustomer(current => ({ ...current, category: selectedValue }))
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    label={"Categoría"}
                                />
                            )}
                        />
                        {
                            customer.category && customer.category.taxPlan &&
                            <Alert
                                style={{ marginTop: 10, paddingTop: 4, marginBottom: 8 }}
                                icon={<Icon>paid</Icon>}
                                severity="warning"
                            >
                                <AlertTitle style={{ fontSize: "1em" }}>{`Plan de impuestos: ${customer.category.taxPlan.name}`}</AlertTitle>
                                <Box
                                    display={"flex"}
                                    flexDirection="column"
                                >
                                    <Typography
                                        variant='caption'
                                        style={{
                                            fontWeight: 900,
                                            fontSize: "0.7em",
                                            opacity: 0.5
                                        }}
                                    >
                                        {`Impuestos: `}
                                    </Typography>
                                    {
                                        Boolean(customer.category) && taxPlans.find(t => t.taxPlanId === customer.category!.taxPlan.taxPlanId)?.taxCodes?.map(tax => {
                                            const taxType = taxTypes.find(taxType => taxType.taxId === tax.taxTypeId)
                                            return (
                                                <Typography
                                                    variant='caption'
                                                    style={{
                                                        fontWeight: 600,
                                                        fontSize: "0.9em"
                                                    }}
                                                >
                                                    {`${taxType?.name ?? ""} ${taxType?.forRetention ? tax.taxCode : tax.jbaCode} - ${tax.description}`}
                                                </Typography>
                                            )
                                        })
                                    }
                                </Box>
                            </Alert>
                        }
                        <TextField
                            size="small"
                            label={FIELDS.companyName.name}
                            variant="outlined"
                            fullWidth
                            required
                            style={{ marginTop: 10 }}
                            value={customer.companyName ?? ""}
                            onChange={(e) => setCustomer(current => ({ ...current, companyName: e.target.value }))}
                        />
                        <TextField
                            size="small"
                            label={FIELDS.comercialName.name}
                            variant="outlined"
                            fullWidth
                            required
                            style={{ marginTop: 10 }}
                            value={customer.commercialName ?? ""}
                            onChange={(e) => setCustomer(current => ({ ...current, commercialName: e.target.value }))}
                        />
                        <TextField
                            size="small"
                            label={FIELDS.specialTaxpayer.name}
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 10 }}
                            value={customer.specialTaxpayer ?? ""}
                            onChange={(e) => setCustomer(current => ({ ...current, specialTaxpayer: e.target.value }))}
                        />
                        <FormGroup className="p-2 mt-2" row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        size="small"
                                        checked={Boolean(customer.active)}
                                        onChange={(e) => setCustomer(current => ({ ...current, active: e.target.checked }))}
                                        name={FIELDS.active.name}
                                    />
                                }
                                label={FIELDS.activeAlt.name}
                            />
                        </FormGroup>
                        <FormGroup className="p-2" row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        size="small"
                                        checked={Boolean(customer.keepAccounts)}
                                        onChange={(e) => setCustomer(current => ({ ...current, keepAccounts: e.target.checked }))}
                                        name={FIELDS.keepAccounts.name}
                                    />
                                }
                                label={FIELDS.keepAccounts.name}
                            />
                        </FormGroup>
                        <div className="flex justify-end pt-3 absolute bottom-0" style={{ left: "182%" }}>
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                disableElevation
                                disabled={loading}
                                type="submit"
                            >
                                {loading ? <CircularProgress style={{ color: "white" }} size={16} className="my-1" thickness={10} /> : (location.state as any)?.edit ? "Guardar" : "Registrar"}
                            </Button>
                        </div>
                    </form>
                    <form onSubmit={handleContact} className="w-1/2 ml-2 pl-4 pr-2 flex flex-col overflow-y-hidden">
                        <div className='flex items-center w-full mb-2'>
                            <Icon color="action">
                                perm_contact_calendar
                            </Icon>
                            <Typography color="textSecondary" variant="body2" style={{ fontWeight: 600, letterSpacing: 0.5, marginLeft: 10 }}>
                                {"Contactos"}
                            </Typography>
                        </div>
                        <Divider />
                        <div className='flex w-full mt-4 justify-between items-center'>
                            <TextField
                                placeholder={FIELDS.name.name}
                                size="small"
                                style={{ width: '48%' }}
                                value={contact.name ?? ""}
                                required
                                onChange={(e) => setContact(current => ({ ...current, name: e.target.value }))}
                            />
                            <Select
                                input={<Input value={contact.type ? CONTACT_TYPES.find(c => c === contact.type) : ""} />}
                                displayEmpty
                                required
                                style={{ width: "48%" }}
                                renderValue={(selected) => {
                                    if (!Boolean(selected)) {
                                        return <span className='opacity-50'>{FIELDS.type.name}</span>
                                    }
                                    return (selected as string)
                                }}
                                value={contact.type ? CONTACT_TYPES.find(c => c === contact.type) : ""}
                                onChange={(e) => setContact(current => ({ ...current, type: (e.target.value as any) }))}
                            >
                                {
                                    CONTACT_TYPES.map(type => (
                                        <MenuItem value={type}>{type}</MenuItem>
                                    ))
                                }
                            </Select>
                        </div>
                        <div className='flex w-full mt-4 justify-between items-center'>
                            <TextField
                                placeholder={FIELDS.email.name}
                                size="small"
                                required
                                style={{ width: '48%' }}
                                value={contact.email ?? ""}
                                onChange={(e) => setContact(current => ({ ...current, email: e.target.value }))}
                            />
                            <TextField
                                placeholder={FIELDS.phoneNumber.name}
                                size="small"
                                required
                                style={{ width: '48%' }}
                                value={contact.phone ?? ""}
                                onChange={(e) => setContact(current => ({ ...current, phone: e.target.value }))}
                                InputProps={{
                                    inputComponent: PhoneInput as any,
                                }}
                            />
                        </div>
                        <div className='flex w-full my-4 justify-between items-center'>
                            <TextField
                                placeholder={FIELDS.description.name}
                                size="small"
                                fullWidth
                                required
                                value={contact.description ?? ""}
                                onChange={(e) => setContact(current => ({ ...current, description: e.target.value }))}
                            />
                        </div>
                        <div className='flex w-full mb-4 justify-between items-center'>
                            <FormGroup style={{ paddingLeft: 10 }} row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            size="small"
                                            required
                                            name={FIELDS.activeAlt.name}
                                            checked={Boolean(contact.active)}
                                            onChange={(e) => setContact(current => ({ ...current, active: e.target.checked }))}
                                        />
                                    }
                                    label={FIELDS.activeAlt.name}
                                />
                            </FormGroup>
                            <div className='flex items-center'>
                                {editIndex !== -1 &&
                                    < Button onClick={handleCancelEdit} size="small">
                                        {"Cancelar"}
                                    </Button>
                                }
                                <Button type="submit" size="small">
                                    <Icon color="action" style={{ marginRight: 10 }} fontSize='small'>{editIndex !== -1 ? "edit" : "add"}</Icon>
                                    {editIndex !== -1 ? "Editar" : "Agregar nuevo"}
                                </Button>
                            </div>
                        </div>
                        <div className='flex flex-grow mb-2 overflow-y-auto' style={{ maxHeight: 200 }}>
                            <List dense style={{ width: "100%", padding: 0, margin: 0 }}>
                                {
                                    (customer.contacts ?? []).map((contact, index) => (
                                        <ListItem style={{ paddingLeft: 0 }} button dense onClick={() => handleEditContact(contact, index)}>
                                            <ListItemAvatar>
                                                <Avatar style={{ width: 32, height: 32, fontSize: "0.9rem" }}>
                                                    {contact.name?.charAt(0).toUpperCase()}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={contact.name}
                                                secondary={contact.email}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => handleDeleteContact(contact)} edge="end">
                                                    <Icon fontSize="small">delete</Icon>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </div>
                    </form>
                </div>
            </div >
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </div >
    )
}

export default CustomerForm
