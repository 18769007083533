import { Typography, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Icon, IconButton, Tooltip, Drawer, Divider, Snackbar, Collapse, Popover, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ConfirmDialog, ReimbursementForm } from 'components'
import { FileContext } from 'context'
import { EXPENSE_ITEMS, FIELDS, formatCurrency, MAX_FILE_SIZE, REIMBURSEMENT_TABLE_COLUMNS } from 'lib'
import moment from 'moment'
import React from 'react'
import { DropzoneInputProps, useDropzone } from 'react-dropzone'
import { IDocument, IDocumentFile, IMessageConfig, IReimbursement, IUploadDocumentFile } from 'types'

interface Props {
    withDetails?: boolean
    reimbursements: IReimbursement[]
    readOnly?: boolean
    onEdit: (newDetail: IReimbursement[]) => void
    files: IDocumentFile[]
    associated: IDocument[]
    documentId: number
    onAddDocumentFiles?: (newDocuments: IDocumentFile[]) => void
    withExpenses?: boolean
    withDelete?: boolean
    onDelete?: (deleted: IReimbursement) => void
    isCashReimbursment?: boolean
}

const headerCellStyle = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2 }
const bodyCellStyle = { fontSize: "0.8em", letterSpacing: 0.2, paddingLeft: 20, paddingRight: 20 }

const Row = (
    { index, row, getStatus, withDetails, edit, readOnly, setEdit, handleUpload, getInputProps, withDelete, onDelete }:
        { index: number, row: IReimbursement, getStatus: (a: string, n: string) => boolean, withDetails?: boolean, edit?: IReimbursement, readOnly?: boolean, setEdit: React.Dispatch<React.SetStateAction<IReimbursement | undefined>>, handleUpload: (a: string) => void, getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T, withDelete?: boolean, onDelete?: (deleted: IReimbursement) => void }
) => {
    const [open, setOpen] = React.useState(false)
    const getExpenseValue = (key: string) => {
        return row.expenses?.find(e => e.type.key === key)?.value ?? 0
    }
    const getNumber=(numberTransform:string|number)=>{
        const isString = typeof numberTransform==='string'
        if(isString){
            return Number(numberTransform as string)
        }
        return numberTransform as number
    }
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    return (
        <React.Fragment>
            <TableRow
                hover
                tabIndex={-1}
                key={"reimbursement" + index.toString()}
            >
                <TableCell width={1}>
                    <Tooltip title="Ver detalles/gastos" arrow>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            <Icon fontSize="small">{open ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell style={bodyCellStyle} component="th" scope="row" padding="none">{`${row.authorizationNumber ?? ""}`}</TableCell>
                <TableCell style={bodyCellStyle} component="th" scope="row" padding="none">
                    <div>
                        {`${row.issuerName ?? ""}`}
                        <br />
                        {`${row.issuerIdentification ?? ""}`}
                    </div>
                </TableCell>
                {/*
                <TableCell style={bodyCellStyle} component="th" scope="row" padding="none">
                    <div>
                        {`${row.receiverName ?? ""}`}
                        <br />
                        {`${row.receiverIdentification ?? ""}`}
                    </div>
                </TableCell>
                */}
                <TableCell style={bodyCellStyle} component="th" scope="row" padding="none">{row.documentNumber}</TableCell>
                <TableCell style={bodyCellStyle} component="th" scope="row" padding="none" align="right">{moment(new Date(row.emissionDate ?? new Date()).toISOString()).format('DD-MM-YYYY').toString()}</TableCell>
                <TableCell style={bodyCellStyle} component="th" scope="row" padding="none" align="right">{formatCurrency(getNumber(row.subtotalNoTaxesObject ?? 0))}</TableCell>
                <TableCell style={bodyCellStyle} component="th" scope="row" padding="none" align="right">{formatCurrency(getNumber(row.subtotalRateZero ?? 0))}</TableCell>
                <TableCell style={bodyCellStyle} component="th" scope="row" padding="none" align="right">{formatCurrency(getNumber(row.subtotalRateTwelve ?? 0))}</TableCell>
                <TableCell style={bodyCellStyle} component="th" scope="row" padding="none" align="right">{formatCurrency(getNumber(row.iva ?? 0))}</TableCell>
                <TableCell style={bodyCellStyle} component="th" scope="row" padding="none" align="right">{formatCurrency(getNumber(row.subtotalRateQuince ?? 0))}</TableCell>
                <TableCell style={bodyCellStyle} component="th" scope="row" padding="none" align="right">{formatCurrency(getNumber(row.iva15 ?? 0))}</TableCell>
                <TableCell component="th" scope="row" padding="none" align="right"> <span>
                    {row.taxSupport ? "S.T." : ""}
                </span></TableCell>
                <TableCell style={bodyCellStyle} align="right">
                    {
                        getStatus(row.authorizationNumber, row.documentNumber) ?
                            <div className="flex items-center">
                                <Icon color="secondary" fontSize="small">check_circle</Icon>
                                {
                                    (edit && !readOnly) &&
                                    <Tooltip arrow title="Editar reembolso">
                                        <IconButton style={{ marginLeft: 10 }} onClick={() => setEdit(row)} size="small">
                                            <Icon style={{ fontSize: "1rem" }} color="action" fontSize="small">edit</Icon>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div>
                            :
                            <div className="flex items-center">
                                <Icon color="error" fontSize="small">cancel</Icon>
                                {
                                    !readOnly &&
                                    <React.Fragment>
                                        <Tooltip arrow title="Subir archivo">
                                            <IconButton onClick={() => handleUpload(row.authorizationNumber)}>
                                                <Icon color="action" fontSize="small">publish</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        {
                                            edit &&
                                            <Tooltip arrow title="Editar reembolso">
                                                <IconButton onClick={() => { setEdit(row) }} size="small">
                                                    <Icon style={{ fontSize: "1rem" }} color="action" fontSize="small">edit</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        <input id={row.authorizationNumber} key={row.authorizationNumber} {...getInputProps()} />
                                    </React.Fragment>
                                }
                            </div>
                    }
                </TableCell>
                {
                    withDelete &&
                    <TableCell style={bodyCellStyle} align="right">
                        <Tooltip title="Eliminar">
                            <IconButton onClick={(e) => { setAnchorEl(e.currentTarget) }} size="small">
                                <Icon fontSize="small">close</Icon>
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                }
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                >
                    <div className="flex flex-col p-4 items-center justify-center">
                        <Typography style={headerCellStyle}>
                            {"¿Estás seguro de eliminar este valor?"}
                        </Typography>
                        <div className="mt-3">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                style={{ marginRight: 5 }}
                                onClick={() => {
                                    if (onDelete) {
                                        onDelete(row)
                                    }
                                    setAnchorEl(null)
                                }}
                            >
                                {"Si"}
                            </Button>
                            <Button
                                size="small"
                                color="primary"
                                onClick={() => setAnchorEl(null)}
                            >
                                {"No"}
                            </Button>
                        </div>
                    </div>
                </Popover>
            </TableRow>
            <TableRow>
                <TableCell colSpan={10} style={{ padding: 0 }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div id={`slip-table-${index}`} className="w-full pl-8 my-2">
                            {
                                withDetails &&
                                <React.Fragment>
                                    <div className="w-full flex">
                                        <Typography color="textSecondary" style={{ fontSize: "0.7em", fontWeight: 600 }} variant="button">{"Detalle"}</Typography>
                                        <div style={{ width: "100%", marginLeft: 50, display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                                            <Paper variant="outlined" className="px-2 py-1 flex flex-col" style={{ minWidth: "23%", flex: 1, marginRight: 8, marginBottom: 4 }}>
                                                <Typography style={{ fontWeight: 900 }} variant="caption">
                                                    {FIELDS.description.name}
                                                </Typography>
                                                <Typography variant="caption" color="textSecondary">
                                                    {row.description}
                                                </Typography>
                                            </Paper>
                                            <Paper variant="outlined" className="px-2 py-1 flex flex-col" style={{ minWidth: "23%", flex: 1, marginRight: 8, marginBottom: 4 }}>
                                                <Typography style={{ fontWeight: 900 }} variant="caption">
                                                    {FIELDS.accounting.name}
                                                </Typography>
                                                <Typography variant="caption" color="textSecondary">
                                                    {row.accounting}
                                                </Typography>
                                            </Paper>
                                            <Paper variant="outlined" className="px-2 py-1 flex flex-col" style={{ minWidth: "23%", flex: 1, marginRight: 8, marginBottom: 4 }}>
                                                <Typography style={{ fontWeight: 900 }} variant="caption">
                                                    {FIELDS.costCenter.name}
                                                </Typography>
                                                <Typography variant="caption" color="textSecondary">
                                                    {row.costCenter?.description}
                                                </Typography>
                                            </Paper>
                                            <Paper variant="outlined" className="px-2 py-1 flex flex-col" style={{ minWidth: "23%", flex: 1, marginRight: 8, marginBottom: 4 }}>
                                                <Typography style={{ fontWeight: 900 }} variant="caption">
                                                    {FIELDS.branch.name}
                                                </Typography>
                                                <Typography variant="caption" color="textSecondary">
                                                    {row.branch?.description}
                                                </Typography>
                                            </Paper>
                                        </div>
                                    </div>
                                    <div className="my-2">
                                        <Divider />
                                    </div>
                                </React.Fragment>
                            }
                            <div className="w-full flex mt-4">
                                <Typography color="textSecondary" style={{ fontSize: "0.7em", fontWeight: 600 }} variant="button">{"Gastos"}</Typography>
                                <div style={{ width: "100%", marginLeft: 50, display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>
                                    {EXPENSE_ITEMS.map(item => (
                                        <Paper variant="outlined" className="px-2 py-1 flex flex-col" style={{ minWidth: "24%", maxWidth: "24%", flex: 1, marginRight: "1%", marginBottom: 4 }}>
                                            <Typography style={{ fontWeight: 900 }} variant="caption">
                                                {item.name}
                                            </Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                {formatCurrency(getExpenseValue(item.key))}
                                            </Typography>
                                        </Paper>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

const ReimbursementData = (props: Props) => {
    const { isCashReimbursment, withDetails, reimbursements, readOnly, onEdit, files, associated, onAddDocumentFiles, documentId, withDelete, onDelete } = props
    const [edit, setEdit] = React.useState<IReimbursement | undefined>(undefined)
    const [openDrawer, setOpenDrawer] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const [authorization, setAuthorization] = React.useState<string | undefined>()
    const [documentFile, setDocumentFile] = React.useState<IUploadDocumentFile | undefined>()
    const { uploadDocumentFiles } = React.useContext(FileContext)
    const handleUpload = (authorizationNumber: string) => {
        setAuthorization(authorizationNumber)
        handleOpenUploader()
    }
    const handleConfirmUpload = async () => {
        if (documentFile) {
            const documentFiles = await uploadDocumentFiles([documentFile], documentId)
            if (onAddDocumentFiles) {
                onAddDocumentFiles(documentFiles)
            }
        }
        return true
    }
    const onDrop = React.useCallback(acceptedFiles => {
        if (acceptedFiles[0]) {
            if ((acceptedFiles[0] as File).size > MAX_FILE_SIZE) {
                setMessageConfig({ message: "El tamaño del archivo excede el máximo permitido", open: true, severity: "error" })
                return
            }
            setDocumentFile({ file: acceptedFiles[0], description: authorization ?? "" })
        }
    }, [authorization])
    const { getInputProps, open: handleOpenUploader } = useDropzone({ onDrop, multiple: false, onFileDialogCancel: () => setAuthorization(undefined) })
    const getStatus = React.useCallback((authorization: string, documentNumber: string) => {
        const inFiles = files?.find(f => f.description === authorization)
        const inReimbursements = reimbursements?.find(r => r.authorizationNumber === authorization && r.documentNumber === documentNumber)
        return Boolean(inFiles) || Boolean(inReimbursements)
    }, [reimbursements, files])
    return (
        <div className="px-8 w-full mt-1">
            <Typography className="pl-2 pt-2" style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                {`Valores enviados a COUPA`}
            </Typography>
            <Paper variant="outlined" className={`mt-3 w-full`} elevation={1}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {
                                    (!withDetails || isCashReimbursment) &&
                                    <TableCell padding={"none"} />
                                }
                                {
                                    (REIMBURSEMENT_TABLE_COLUMNS).filter(r => r.id !== FIELDS.receiverName.key).map((headCell) => (
                                        <TableCell
                                            style={headerCellStyle}
                                            align={
                                                headCell.id === FIELDS.emissionDate.key ||
                                                    headCell.id === FIELDS.subtotal.key ||
                                                    headCell.id === FIELDS.subtotalRateTwelve.key ||
                                                    headCell.id === FIELDS.subtotalRateZero.key ||
                                                    headCell.id === FIELDS.iva.key ||
                                                    headCell.id === FIELDS.detail.key
                                                    ?
                                                    'right' : 'left'
                                            }
                                        >
                                            {headCell.label}
                                        </TableCell>
                                    ))
                                }
                                {
                                    !readOnly &&
                                    <TableCell
                                        padding={"default"}
                                    />
                                }
                                {
                                    withDelete &&
                                    <TableCell
                                        padding={"default"}
                                    />
                                }
                                <TableCell
                                    padding={"default"}
                                />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                reimbursements.map((row, index) => (
                                    <Row
                                        key={"row" + index}
                                        index={index}
                                        getInputProps={getInputProps}
                                        getStatus={getStatus}
                                        handleUpload={handleUpload}
                                        readOnly={readOnly}
                                        row={row}
                                        setEdit={setEdit}
                                        edit={edit}
                                        withDetails={withDetails}
                                        withDelete={withDelete}
                                        onDelete={onDelete}
                                    />
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Drawer onClose={() => setOpenDrawer(false)} anchor="right" open={openDrawer}>
                    <div style={{ width: 600 }} className="h-full flex flex-col">
                        <div className="flex flex-wrap items-center p-4 relative">
                            <IconButton onClick={() => setOpenDrawer(false)} className="absolute">
                                <Icon color="action">
                                    {"chevron_left"}
                                </Icon>
                            </IconButton>
                            <Typography className="uppercase pl-2" color="textSecondary" variant="h6" align="center" style={{ fontWeight: 600, letterSpacing: 0.25 }}>
                                {"Nuevo reembolso"}
                            </Typography>
                        </div>
                        <Divider variant="middle" />
                        <ReimbursementForm
                            withDetails={withDetails}
                            onAdd={(newReimbursement) => {
                                if (edit) {
                                    onEdit(reimbursements.map(r => r === edit ? newReimbursement : r))
                                    setEdit(undefined)
                                    setOpenDrawer(false)
                                }
                            }}
                            onClose={() => { setOpenDrawer(false); setEdit(undefined) }}
                            edit={edit}
                            supplierIdentification=""
                        />
                    </div>
                </Drawer>
            </Paper>
            <ConfirmDialog
                open={Boolean(documentFile?.file && documentFile?.description)}
                onCancel={() => setDocumentFile(undefined)}
                onConfirm={handleConfirmUpload}
                title={`¿Esta seguro de subir archivo para documento con número de autorización ${authorization}?`}
            />
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default ReimbursementData
