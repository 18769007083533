import { DOCUMENT_PATH, DASHBOARD_PATH, NEW_DOCUMENT_PATH, DOCUMENT_VIEW_PATH, DOCUMENT_TYPE_CODES, PURCHASE_SETTLEMENT_PATH, RETENTION_RECEIPT_PATH, DOCUMENT_STATES, ENTERED_PATH, COMPLETED_PATH, PENDING_PATH, REJECTED_PATH, COUPA_ERROR_PATH, UNSEND_COUPA_PATH, COUPA_STATES, CREDIT_NOTE_PATH, REIMBURSEMENT_PATH, DRAFTS_PATH, MYDOCS_PATH } from 'lib'
import { DocumentsDashboard, DocumentView, NewDocument } from 'pages'
import DraftsDashboard from 'pages/Dashboard/DraftsDashboard'
import MyDocumentsDashboard from 'pages/Dashboard/MydocsDashboard'
import React from 'react'
import { Route } from 'react-router-dom'

const Documents = () => {
    return (
        <React.Fragment>
            <Route
                strict
                path={`${DOCUMENT_PATH}${DASHBOARD_PATH}`}
                component={
                    () => <DocumentsDashboard
                        forReception
                        showCounts
                        receivingCompany
                        initialCode={DOCUMENT_TYPE_CODES.companyReception}
                        redirectOnApprove={`${DOCUMENT_PATH}${COMPLETED_PATH}`}
                        redirectOnReject={`${DOCUMENT_PATH}${REJECTED_PATH}`}
                    />
                }
            />
            <Route
                strict
                path={`${DOCUMENT_PATH}${ENTERED_PATH}`}
                component={
                    () => <DocumentsDashboard
                        initialState={DOCUMENT_STATES.entered}
                        initialCode={DOCUMENT_TYPE_CODES.companyReception}
                        hideState
                        receivingCompany
                        forReception
                        redirectOnApprove={`${DOCUMENT_PATH}${COMPLETED_PATH}`}
                        redirectOnReject={`${DOCUMENT_PATH}${REJECTED_PATH}`}
                    />
                }
            />
            <Route
                strict
                path={`${DOCUMENT_PATH}${PENDING_PATH}`}
                component={
                    () =>
                        <DocumentsDashboard
                            initialState={DOCUMENT_STATES.pending}
                            initialCode={DOCUMENT_TYPE_CODES.companyReception}
                            hideState
                            forReception
                            receivingCompany
                            redirectOnApprove={`${DOCUMENT_PATH}${COMPLETED_PATH}`}
                            redirectOnReject={`${DOCUMENT_PATH}${REJECTED_PATH}`}
                        />
                }
            />
            <Route
                strict
                path={`${DOCUMENT_PATH}${COMPLETED_PATH}`}
                component={
                    () =>
                        <DocumentsDashboard
                            initialState={DOCUMENT_STATES.finished}
                            initialCode={DOCUMENT_TYPE_CODES.companyReception}
                            hideState
                            forReception
                            receivingCompany
                            redirectOnApprove={`${DOCUMENT_PATH}${COMPLETED_PATH}`}
                            redirectOnReject={`${DOCUMENT_PATH}${REJECTED_PATH}`}
                        />
                }
            />
            <Route
                strict
                path={`${DOCUMENT_PATH}${REJECTED_PATH}`}
                component={
                    () =>
                        <DocumentsDashboard
                            initialState={DOCUMENT_STATES.rejected}
                            initialCode={DOCUMENT_TYPE_CODES.companyReception}
                            hideState
                            forReception
                            receivingCompany
                            redirectOnApprove={`${DOCUMENT_PATH}${COMPLETED_PATH}`}
                            redirectOnReject={`${DOCUMENT_PATH}${REJECTED_PATH}`}
                        />
                }
            />
            <Route
                strict
                path={`${DOCUMENT_PATH}${CREDIT_NOTE_PATH}`}
                component={
                    () =>
                        <DocumentsDashboard
                            initialCode={DOCUMENT_TYPE_CODES.creditNote}
                            initialState={DOCUMENT_STATES.pending}
                            receivingCompany
                            hideType
                            forReception
                            redirectOnApprove={`${DOCUMENT_PATH}${COMPLETED_PATH}`}
                            redirectOnReject={`${DOCUMENT_PATH}${REJECTED_PATH}`}
                        />
                }
            />
            <Route
                strict
                path={`${DOCUMENT_PATH}${COUPA_ERROR_PATH}`}
                component={
                    () =>
                        <DocumentsDashboard
                            initialCode={DOCUMENT_TYPE_CODES.companyReception}
                            hideState
                            forReception
                            receivingCompany
                            initialState={DOCUMENT_STATES.finished}
                            redirectOnApprove={`${DOCUMENT_PATH}${COMPLETED_PATH}`}
                            redirectOnReject={`${DOCUMENT_PATH}${REJECTED_PATH}`}
                            initialHistoryType={COUPA_STATES.error}
                        />
                }
            />
            <Route
                strict
                path={`${DOCUMENT_PATH}${DRAFTS_PATH}`}
                component={
                    () =>
                        <DraftsDashboard
                            initialCode={DOCUMENT_TYPE_CODES.reimbursements}
                            hideState
                            forReception
                            receivingCompany
                            initialState={DOCUMENT_STATES.draft}
                            redirectOnApprove={`${DOCUMENT_PATH}${COMPLETED_PATH}`}
                            redirectOnReject={`${DOCUMENT_PATH}${REJECTED_PATH}`}
                        />
                }
            />
            <Route
                strict
                path={`${DOCUMENT_PATH}${MYDOCS_PATH}`}
                component={
                    () => <MyDocumentsDashboard
                        forReception
                        showCounts
                        receivingCompany
                        initialCode={DOCUMENT_TYPE_CODES.mydocuments}
                        redirectOnApprove={`${DOCUMENT_PATH}${COMPLETED_PATH}`}
                        redirectOnReject={`${DOCUMENT_PATH}${REJECTED_PATH}`}
                    />
                }
            />
            <Route
                strict
                path={`${DOCUMENT_PATH}${UNSEND_COUPA_PATH}`}
                component={
                    () =>
                        <DocumentsDashboard
                            initialCode={DOCUMENT_TYPE_CODES.companyReception}
                            hideState
                            forReception
                            receivingCompany
                            initialState={DOCUMENT_STATES.finished}
                            redirectOnApprove={`${DOCUMENT_PATH}${COMPLETED_PATH}`}
                            redirectOnReject={`${DOCUMENT_PATH}${REJECTED_PATH}`}
                            initialHistoryType={COUPA_STATES.unsend}
                        />
                }
            />

            <Route
                strict
                path={`${DOCUMENT_PATH}${PURCHASE_SETTLEMENT_PATH}`}
                component={
                    () =>
                        <DocumentsDashboard
                            initialCode={DOCUMENT_TYPE_CODES.purchaseSettlement}
                            issuingCompany
                            hideType
                            forEmission
                            redirectOnApprove={`${DOCUMENT_PATH}${COMPLETED_PATH}`}
                            redirectOnReject={`${DOCUMENT_PATH}${REJECTED_PATH}`}
                        />
                }
            />

            <Route
                strict
                path={`${DOCUMENT_PATH}${RETENTION_RECEIPT_PATH}`}
                component={
                    () =>
                        <DocumentsDashboard
                            initialCode={DOCUMENT_TYPE_CODES.retentionReceipt}
                            issuingCompany
                            hideType
                            forEmission
                            redirectOnApprove={`${DOCUMENT_PATH}${COMPLETED_PATH}`}
                            redirectOnReject={`${DOCUMENT_PATH}${REJECTED_PATH}`}
                        />
                }
            />

            <Route strict path={`${DOCUMENT_PATH}${NEW_DOCUMENT_PATH}`} component={NewDocument} />
            <Route strict path={`${DOCUMENT_PATH}${DOCUMENT_VIEW_PATH}`} component={DocumentView} />

            <Route
                strict
                path={`${DOCUMENT_PATH}${REIMBURSEMENT_PATH}`}
                component={
                    () =>
                        <DocumentsDashboard
                            initialCode={DOCUMENT_TYPE_CODES.reimbursements}
                            forReception
                            receivingCompany
                            hideType
                            redirectOnApprove={`${DOCUMENT_PATH}${COMPLETED_PATH}`}
                            redirectOnReject={`${DOCUMENT_PATH}${REJECTED_PATH}`}
                        />
                }
            />

            {/* <Route strict path={`${DOCUMENT_PATH}${ELECTRONIC_INVOICE_PATH}`} component={() => <DocumentsDashboard initialCode={DOCUMENT_TYPE_CODES.electronicInvoice} />} />
            <Route strict path={`${DOCUMENT_PATH}${FOREIGN_EXPENSES_LIQUIDATION_PATH}`} component={() => <DocumentsDashboard initialCode={DOCUMENT_TYPE_CODES.foreignExpensesLiquidation} typePath={FOREIGN_EXPENSES_LIQUIDATION_PATH} />} />
            <Route strict path={`${DOCUMENT_PATH}${PENDING_FOREIGN_EXPENSES_LIQUIDATION_PATH}`} component={() => <DocumentsDashboard initialCode={DOCUMENT_TYPE_CODES.foreignExpensesLiquidation} initialState={DOCUMENT_STATES.pending} redirectOnApprove={`${DOCUMENT_PATH}${FINISHED_FOREIGN_EXPENSES_LIQUIDATION_PATH}`} />} />
            <Route strict path={`${DOCUMENT_PATH}${FINISHED_FOREIGN_EXPENSES_LIQUIDATION_PATH}`} component={() => <DocumentsDashboard initialCode={DOCUMENT_TYPE_CODES.foreignExpensesLiquidation} initialState={DOCUMENT_STATES.finished} />} />
            <Route strict path={`${DOCUMENT_PATH}${BILL_OF_LADING_PATH}`} component={() => <DocumentsDashboard initialCode={DOCUMENT_TYPE_CODES.billOfLading} typePath={BILL_OF_LADING_PATH} />} />
            <Route strict path={`${DOCUMENT_PATH}${PRINTED_INVOICE_PATH}`} component={() => <DocumentsDashboard initialCode={DOCUMENT_TYPE_CODES.printedInvoice} typePath={PRINTED_INVOICE_PATH} />} />
            
            <Route strict path={`${DOCUMENT_PATH}${FINISHED_ELECTRONIC_INVOICE_PATH}`} component={() => <DocumentsDashboard initialCode={DOCUMENT_TYPE_CODES.electronicInvoice} initialState={DOCUMENT_STATES.finished} />} />
            <Route strict path={`${DOCUMENT_PATH}${PENDING_ELECTRONIC_INVOICE_PATH}`} component={() => <DocumentsDashboard initialCode={DOCUMENT_TYPE_CODES.electronicInvoice} initialState={DOCUMENT_STATES.pending} redirectOnApprove={`${DOCUMENT_PATH}${FINISHED_PRINTED_INVOICE_PATH}`} />} />
            <Route strict path={`${DOCUMENT_PATH}${FINISHED_PRINTED_INVOICE_PATH}`} component={() => <DocumentsDashboard initialCode={DOCUMENT_TYPE_CODES.printedInvoice} initialState={DOCUMENT_STATES.finished} />} />
            <Route strict path={`${DOCUMENT_PATH}${PENDING_PRINTED_INVOICE_PATH}`} component={() => <DocumentsDashboard initialCode={DOCUMENT_TYPE_CODES.printedInvoice} initialState={DOCUMENT_STATES.pending} redirectOnApprove={`${DOCUMENT_PATH}${FINISHED_PRINTED_INVOICE_PATH}`} />} />
            <Route strict path={`${DOCUMENT_PATH}${PENDING_REIMBURSEMENT_INVOICE_PATH}`} component={() => <DocumentsDashboard initialCode={DOCUMENT_TYPE_CODES.reimbursementInvoice} initialState={DOCUMENT_STATES.pending} redirectOnApprove={`${DOCUMENT_PATH}${FINISHED_REIMBURSEMENT_INVOICE_PATH}`} />} />
            <Route strict path={`${DOCUMENT_PATH}${FINISHED_REIMBURSEMENT_INVOICE_PATH}`} component={() => <DocumentsDashboard initialCode={DOCUMENT_TYPE_CODES.reimbursementInvoice} initialState={DOCUMENT_STATES.finished} />} /> */}
        </React.Fragment>
    )
}

export default Documents
