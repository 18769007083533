import { FIELDS } from "lib"

export const USER_TABLE_COLUMNS = [
    { id: FIELDS.name.key, label: FIELDS.name.name },
    { id: FIELDS.email.key, label: FIELDS.email.name },
    { id: FIELDS.identification.key, label: FIELDS.identification.name },
    { id: FIELDS.validUntil.key, label: FIELDS.validUntil.name },
    { id: FIELDS.state.key, label: FIELDS.state.name },
    { id: FIELDS.documentActions.key, label: FIELDS.documentActions.name },
    { id: FIELDS.rejectedActions.key, label: FIELDS.rejectedActions.name },
    { id: FIELDS.refundActions.key, label: FIELDS.refundActions.name },
]