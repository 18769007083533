import axios, { AxiosError } from "axios"
import { DOCUMENTS_API_SERVICE_DOMAIN } from "lib"

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })

export const wsFinalizeInvoiceEmitedManually = async (id : number) => {
    try {
        // const result = await intance.post(`/finalize/invoice?id=${id}`)
        const result = await intance.post(`/finalize/invoice`,undefined,{
            params:{
                id
            }
        })
        return result.data
    } catch (error:any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}
