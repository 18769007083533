import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { RetentionActions, RetentionHeader, RetentionTaxes } from 'components'
import { AuthContext, FileContext } from 'context'
import { ROOT_TITLE, NEW_RETENTION_TITLE, createDocumentService } from 'lib'
import React from 'react'
import { Helmet } from 'react-helmet'
import { IDocument, IMessageConfig } from 'types'
import { IRetentionAdditionalInfo, IRetentionTax } from 'types/retention'

type Props = {}

const NewRetention = (props: Props) => {

    /**
     * Se inicializa el documento asociado para que el autocomplete acepte la mascara
     */
    const { uploadDocumentFiles } = React.useContext(FileContext)
    const [retention, setRetention] = React.useState<IRetentionAdditionalInfo>({ header: { associatedDocument: { serialNumber: "" } }, taxes: [] })
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const [loading, setLoading] = React.useState(false)
    const { user } = React.useContext(AuthContext)

    const handleAdd = (tax: IRetentionTax) => {
        setRetention(current => ({
            ...current,
            taxes: [...current.taxes, tax]
        }))
    }

    const handleEdit = (tax: IRetentionTax) => {
        setRetention(current => ({
            ...current,
            taxes: current.taxes.map(t => t === tax ? tax : t)
        }))
    }

    const handleDelete = (tax: IRetentionTax) => {
        setRetention(current => ({
            ...current,
            taxes: current.taxes.filter(t => t !== tax)
        }))
    }

    const handleSubmit = async () => {
        if (!retention.header.customer) {
            setMessageConfig({
                message: "Se debe seleccionar un cliente",
                open: true,
                severity: "error"
            })
            return
        }
        if (!retention.header.emissionDate) {
            setMessageConfig({
                message: "Selecciona la fecha de emisión de la retención",
                open: true,
                severity: "error"
            })
            return
        }
        if (!retention.header.associatedDocument?.serialNumber) {
            setMessageConfig({
                message: "Selecciona el documento asociado",
                open: true,
                severity: "error"
            })
            return
        }
        if (retention.taxes.length === 0) {
            setMessageConfig({
                message: "Debes agregar un impuesto como mínimo",
                open: true,
                severity: "error"
            })
            return
        }
        if (!retention.header.file) {
            setMessageConfig({
                message: "Debes agregar un archivo de sustento de la retencion",
                open: true,
                severity: "error"
            })
            return
        }
        try {
            setLoading(true)
            const createdDocument: IDocument = await createDocumentService({ header: { ...retention.header, type: "retention" }, taxes: retention.taxes }, user?.userId)
            if (retention.header.file) {
                await uploadDocumentFiles([{ description: "Archivo de respaldo", file: retention.header.file.file }], createdDocument.documentId)
            }
            setMessageConfig({
                message: "Retención creada!",
                open: true,
                severity: "success"
            })
            setRetention({ header: { associatedDocument: { serialNumber: "" }, documentNumber: "" }, taxes: [] })

        } catch (error) {
            setMessageConfig({
                message: "No se pudo crear la retención",
                open: true,
                severity: "error"
            })
        } finally {
            setLoading(false)
        }
    }


    return (
        <div className="flex flex-col h-full overflow-y-hidden">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${NEW_RETENTION_TITLE}`}</title>
            </Helmet>
            <React.Fragment>
                <div className="p-1">
                    <RetentionHeader
                        header={retention.header}
                        onChange={(header) => setRetention(current => ({ ...current, header }))}
                    />
                </div>
                <div className="p-1 flex-grow overflow-y-hidden">
                    <RetentionTaxes
                        taxes={retention.taxes}
                        onAdd={handleAdd}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                </div>
                <RetentionActions
                    loading={loading}
                    onSubmit={handleSubmit}
                />
            </React.Fragment>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default NewRetention