import {
    Chip,
    CircularProgress,
    Divider,
    Paper,
    Typography
} from "@material-ui/core";
import { wsObtenerCharts } from "api/ApiCharts";
import {
    DOCUMENT_TITLE,
    ROOT_TITLE,
} from "lib";
import { ICharts } from "model/ChartsModel";
import React from "react";
import Chart from "react-google-charts";
import { Helmet } from "react-helmet";


export const optionsFacturas = {
    title: "Facturas en espera de Comprobante de Retención",
    chartArea: { width: "70%" },
    colors: ["#b8b8b8", "#fbc02d"],
    hAxis: { minValue: 0, },
    vAxis: { title: "Estados" }
};

export const optionsNotasCredito = {
    title: "Notas de Credito Finalizadas y No Finalizadas",
    chartArea: { width: "70%" },
    colors: ["#b8b8b8", "#fbc02d"],
    hAxis: { minValue: 0, },
    vAxis: { title: "Estados" }
};

export const optionsRetenciones = {
    title: "Retenciones Contabilizadas y Por Contabilizar",
    chartArea: { width: "70%" },
    colors: ["#b8b8b8", "#fbc02d"],
    hAxis: { minValue: 0, },
    vAxis: { title: "Estados" }
};

const DocumentsStatics = () => {

    const [loading, setloading] = React.useState<boolean>(true)

    const [dataFacturas, setDataFacturas] = React.useState<Array<Array<string | number>>>([
        ["Estado", "Conteo"],
        ["Recibido Retención", 0],
        ["Sin Retención", 0]
    ]);

    const [dataNC, setDataNC] = React.useState<Array<Array<string | number>>>([
        ["Estado", "Conteo"],
        ["Finalizadas", 0],
        ["No Finalizadas", 0]
    ]);

    const [dataRetenciones, setDataRetenciones] = React.useState<Array<Array<string | number>>>([
        ["Estado", "Conteo"],
        ["Contabilizadas", 0],
        ["Por Contabilizar", 0]
    ]);

    React.useEffect(() => {
        const fetchChartsData = async () => {
            try {
                const data = await wsObtenerCharts();
                const { anuladas, contabilizadas, enEspera, enProceso, pendientes } = data.facturas
                const { enEspera: ncEspera, finalizadas, pendientes: ncPendientes } = data.notas
                const { contabilizadas: rCont, enEspera: rEspe, enProceso: rPro, pendientes: rPen, rechazadas } = data.retenciones

                setDataFacturas((prev) => {
                    return prev.map((data) => {
                        if (data[0] === 'Estado') {
                            return data
                        }
                        if (data[0] === 'Recibido Retención') {
                            return ["Recibido Retención", contabilizadas]
                        }
                        if (data[0] === 'Sin Retención') {
                            return ["Sin Retención", anuladas + pendientes + enEspera + enProceso]
                        }
                        return data
                    })
                })

                setDataNC((prev) => {
                    return prev.map((data) => {
                        if (data[0] === 'Estado') {
                            return data
                        }
                        if (data[0] === 'Finalizadas') {
                            return ["Finalizadas", finalizadas]
                        }
                        if (data[0] === 'No Finalizadas') {
                            return ["No Finalizadas", ncEspera + ncPendientes]
                        }
                        return data
                    })
                })

                setDataRetenciones((prev) => {
                    return prev.map((data) => {
                        if (data[0] === 'Estado') {
                            return data
                        }
                        if (data[0] === 'Contabilizadas') {
                            return ["Contabilizadas", rCont]
                        }
                        if (data[0] === 'Por Contabilizar') {
                            return ["Por Contabilizar", rechazadas + rPen + rEspe + rPro]
                        }
                        return data
                    })
                })



            } catch (error) {
                console.error('Error al obtener los datos de los gráficos:');
            } finally {
                setloading(false)
            }
        };
        fetchChartsData();
    }, []);


    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0" style={{ overflowY: 'scroll' }}>
            <Helmet>
                <title>{`${ROOT_TITLE} - ${DOCUMENT_TITLE}`}</title>
            </Helmet>
            {loading &&
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress color="primary" />
                    <Typography style={{ marginTop: '20px' }}>Cargando gráficos...</Typography>
                </div>
            }
            {!loading &&
                <>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                        <Divider style={{ flexGrow: 1, backgroundColor: 'gray' }} />
                        <Chip label="RETENCIONES" variant="outlined" style={{
                            margin: '0 30px', color: 'white', backgroundColor: 'red',
                            border: 'none', fontSize: '14px'
                        }} />
                        <Divider style={{ flexGrow: 1, backgroundColor: 'gray' }} />
                    </div>
                    <div className="flex items-center" style={{ marginBottom: "20px", width: "50vw", }}>
                        <Chart
                            chartType="PieChart"
                            width="120%"
                            height="350px"
                            data={dataRetenciones}
                            options={optionsRetenciones}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Divider style={{ flexGrow: 1, backgroundColor: 'gray' }} />
                        <Chip label="FACTURAS" variant="outlined" style={{
                            margin: '0 30px', color: 'white', backgroundColor: 'red',
                            border: 'none', fontSize: '14px'
                        }} />
                        <Divider style={{ flexGrow: 1, backgroundColor: 'gray' }} />
                    </div>
                    <div className="flex items-center" style={{ marginBottom: "20px", width: "50vw", textAlign: 'center', alignContent: 'center' }}>
                        <Chart
                            chartType="PieChart"
                            width="120%"
                            height="350px"
                            data={dataFacturas}
                            options={optionsFacturas}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                        <Divider style={{ flexGrow: 1, backgroundColor: 'gray' }} />
                        <Chip label="NOTAS DE CREDITO" variant="outlined" style={{
                            margin: '0 30px', color: 'white', backgroundColor: 'red',
                            border: 'none', fontSize: '14px'
                        }} />
                        <Divider style={{ flexGrow: 1, backgroundColor: 'gray' }} />
                    </div>
                    <div className="flex items-center" style={{ marginBottom: "20px", width: "50vw", }}>
                        <Chart
                            chartType="PieChart"
                            width="120%"
                            height="350px"
                            data={dataNC}
                            options={optionsNotasCredito}
                        />
                    </div>
                </>
            }
        </Paper>
    );
};

export default DocumentsStatics;
