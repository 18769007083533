import { Paper, Typography, Divider } from '@material-ui/core'
import React from 'react'
import { IDocumentHeader } from 'types'
import moment from 'moment'
import 'moment/locale/es'
import { FIELDS } from 'lib'

moment.locale("es")

interface Props {
    header: IDocumentHeader
}

const textStyle = { fontWeight: 600, fontSize: "0.8em" }

const DocumentDataPrinted = (props: Props) => {
    const { header } = props
    return (
        <Paper variant="outlined" className="p-4 px-6" elevation={1}>
            <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                {"DATOS DEL DOCUMENTO"}
            </Typography>
            <div className="mt-2">
                <div className="my-2">
                    <Divider />
                </div>
                {
                    header.documentNumber &&
                    <div className="flex mb-3 items-center">
                        <Typography style={textStyle}>
                            {`N° ${header.documentNumber}`}
                        </Typography>
                    </div>
                }
                {
                    <div className="flex">
                        <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                            {`Número de autorización:`}
                        </Typography>
                        <Typography style={textStyle} className="pl-2" variant="subtitle2">
                            {"No Disponible"}
                        </Typography>
                    </div>
                }
                {
                    header.emissionDate &&
                    <div className="flex">
                        <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                            {FIELDS.emissionDate.name}
                        </Typography>
                        <Typography style={textStyle} className="pl-2" variant="subtitle2">
                            {moment(new Date(header.emissionDate).toISOString()).format('DD-MM-YYYY').toString()}
                        </Typography>
                    </div>
                }
                {
                    <div className="flex">
                        <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                            {FIELDS.authorizationDate.name}
                        </Typography>
                        <Typography style={textStyle} className="pl-2" variant="subtitle2">
                            {"No Disponible"}
                        </Typography>
                    </div>
                }
                {
                    header.receptionDate &&
                    <div className="flex">
                        <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                            {FIELDS.receptionDate.name}
                        </Typography>
                        <Typography style={textStyle} className="pl-2" variant="subtitle2">
                            {moment(new Date(header.receptionDate).toISOString()).format('DD-MM-YYYY').toString()}
                        </Typography>
                    </div>
                }
                {
                    header.expiresDate &&
                    <div className="flex">
                        <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                            {FIELDS.expiresDate.name}
                        </Typography>
                        <Typography style={textStyle} className="pl-2" variant="subtitle2">
                            {moment(new Date(header.expiresDate).toISOString()).format('DD-MM-YYYY').toString()}
                        </Typography>
                    </div>
                }
                {
                    header?.environment &&
                    <div className="flex">
                        <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                            {`Ambiente:`}
                        </Typography>
                        <Typography style={textStyle} className="pl-2" variant="subtitle2">
                            {header?.environment}
                        </Typography>
                    </div>
                }
                {
                    header?.associatedDocumentNumber &&
                    <div className="flex">
                        <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                            {`Número de factura:`}
                        </Typography>
                        <Typography style={textStyle} className="pl-2" variant="subtitle2">
                            {header?.associatedDocumentNumber}
                        </Typography>
                    </div>
                }

                {
                    header?.associatedDocumentDate &&
                    <div className="flex">
                        <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                            {`Fecha de factura:`}
                        </Typography>
                        <Typography style={textStyle} className="pl-2" variant="subtitle2">
                        {moment(new Date(header.associatedDocumentDate).toISOString()).format('DD-MM-YYYY').toString()}
                        </Typography>
                    </div>
                }
            </div>
        </Paper>
    )
}

export default DocumentDataPrinted
