import { Chip, Icon, Paper, Typography } from '@material-ui/core'
import { DocumentContext } from 'context'
import { COMPLETED_PATH, COUPA_ERROR_PATH, DOCUMENT_PATH, ENTERED_PATH, PENDING_PATH, REJECTED_PATH, UNSEND_COUPA_PATH } from 'lib'
import React from 'react'
import CountUp from 'react-countup'
import { useHistory } from 'react-router-dom'

interface Props {
    countPath?: string
}

const CountDocuments = React.memo((props: Props) => {
    const { countPath } = props
    const { count } = React.useContext(DocumentContext)
    const { completed, rejected, pending, total, entered, error, unsend } = count
    const [recount, setRecount] = React.useState<number>(0)
    const history = useHistory()


    React.useEffect(() => {
        setRecount(Math.random())
    }, [])

    return (
        <div className="flex items-center w-full pb-4 justify-between">
            <Paper
                className="px-4 py-3 flex flex-grow mr-2 items-center justify-start relative overflow-hidden cursor-pointer"
                style={{
                    backgroundColor: "#ffd54f",
                    flex: 1
                }}
                elevation={0}
                onClick={() => history.push(`${countPath ?? DOCUMENT_PATH}${ENTERED_PATH}`)}
            >
                <div className="glass-md flex items-center justify-center p-2">
                    <Icon className="opacity-50">arrow_upward</Icon>
                </div>
                <div className="ml-4">
                    <CountUp start={recount} duration={1} end={entered}>
                        {({ countUpRef }) => (
                            <div>
                                <Typography
                                    color="textSecondary"
                                    variant="h5"
                                    style={{ fontWeight: 900 }}
                                >
                                    <span ref={countUpRef} />
                                </Typography>
                            </div>
                        )}
                    </CountUp>
                    <Typography
                        variant="caption"
                        className="opacity-75 font-bold"
                    >
                        {"Ingresados"}
                    </Typography>
                </div>
            </Paper>
            <Paper
                className="px-4 py-3 flex flex-grow mr-2 items-center justify-start relative overflow-hidden cursor-pointer"
                style={{
                    // backgroundColor: "#0288d1",
                    backgroundColor: "#fbc02d",
                    flex: 1
                }}
                elevation={0}
                onClick={() => history.push(`${countPath ?? DOCUMENT_PATH}${PENDING_PATH}`)}
            >
                <div className="glass-md flex items-center justify-center p-2">
                    <Icon className="opacity-50">timelapse</Icon>
                </div>
                <div className="ml-4">
                    <CountUp start={recount} duration={1} end={pending}>
                        {({ countUpRef }) => (
                            <div>
                                <Typography
                                    color="textSecondary"
                                    variant="h5"
                                    style={{ fontWeight: 900 }}
                                >
                                    <span ref={countUpRef} />
                                </Typography>
                            </div>
                        )}
                    </CountUp>
                    <Typography
                        variant="caption"
                        className="opacity-75 font-bold"
                    >
                        {"Pendientes"}
                    </Typography>
                </div>
            </Paper>
            <Paper
                className="px-4 py-3 flex flex-grow mr-2 items-center justify-start relative overflow-hidden cursor-pointer"
                style={{
                    // backgroundColor: "#00897b",
                    backgroundColor: "#f9a825",
                    flex: 1
                }}
                elevation={0}
                onClick={() => history.push(`${countPath ?? DOCUMENT_PATH}${REJECTED_PATH}`)}
            >
                <div className="glass-md flex items-center justify-center p-2">
                    <Icon className="opacity-50">close</Icon>
                </div>
                <div className="ml-4">
                    <CountUp start={recount} duration={1} end={rejected}>
                        {({ countUpRef }) => (
                            <div>
                                <Typography
                                    color="textSecondary"
                                    variant="h5"
                                    style={{ fontWeight: 900 }}
                                >
                                    <span ref={countUpRef} />
                                </Typography>
                            </div>
                        )}
                    </CountUp>
                    <Typography
                        variant="caption"
                        className="opacity-75 font-bold"
                    >
                        {"Rechazados"}
                    </Typography>
                </div>
            </Paper>
            <Paper
                className="px-4 py-3 flex flex-grow mr-2 items-center justify-start relative overflow-hidden cursor-pointer"
                style={{
                    // backgroundColor: "#303f9f",
                    backgroundColor: "#ffa000",
                    flex: 1.75,
                    maxWidth: "50%"
                }}
                elevation={0}
                onClick={(e) => {
                    e.stopPropagation()
                    history.push(`${countPath ?? DOCUMENT_PATH}${COMPLETED_PATH}`)
                }}
            >
                <div className="glass-md flex items-center justify-center p-2">
                    <Icon className="opacity-50">flag</Icon>
                </div>
                <div className="ml-4">
                    <CountUp start={recount} duration={1} end={completed}>
                        {({ countUpRef }) => (
                            <div>
                                <Typography
                                    color="textSecondary"
                                    variant="h5"
                                    style={{ fontWeight: 900 }}
                                >
                                    <span ref={countUpRef} />
                                </Typography>
                            </div>
                        )}
                    </CountUp>
                    <Typography
                        variant="caption"
                        className="opacity-75 font-bold"
                    >
                        {"Finalizados"}
                    </Typography>
                </div>
                <div className="flex px-6 flex-grow flex-col">
                    <Chip
                        style={{ width: '100%', fontSize: '0.75em' }}
                        variant="outlined"
                        size="small"
                        label={`Error: ${error}`}
                        onClick={(e) => {
                            e.stopPropagation()
                            history.push(`${countPath ?? DOCUMENT_PATH}${COUPA_ERROR_PATH}`)
                        }}
                    />
                    <Chip
                        style={{ width: '100%', marginTop: 6, fontSize: '0.75em' }}
                        variant="outlined"
                        size="small"
                        label={`No enviados: ${unsend}`}
                        onClick={(e) => {
                            e.stopPropagation()
                            history.push(`${countPath ?? DOCUMENT_PATH}${UNSEND_COUPA_PATH}`)
                        }}
                    />
                </div>
            </Paper>
            <Paper
                className="px-4 py-3 flex items-center justify-start relative overflow-hidden"
                style={{
                    // backgroundColor: "#303f9f",
                    backgroundColor: "#ff8f00",
                }}
                elevation={0}
            >
                <div className="mx-4">
                    <CountUp start={recount} duration={1} end={total}>
                        {({ countUpRef }) => (
                            <div>
                                <Typography
                                    color="textSecondary"
                                    variant="h5"
                                    style={{ fontWeight: 900 }}
                                    align="center"
                                >
                                    <span ref={countUpRef} />
                                </Typography>
                            </div>
                        )}
                    </CountUp>
                    <Typography
                        variant="body1"
                        className="opacity-75"
                        align="center"
                    >
                        {"Total"}
                    </Typography>
                </div>
            </Paper>
        </div>
    )
})

export default CountDocuments
