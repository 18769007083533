import { Checkbox, Typography, TextField, Icon, InputAdornment, IconButton, Tooltip, Button, CircularProgress } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { FIELDS, REPORT_CONDITIONS_IDS, REPORT_TYPES_IDS } from 'lib'
import React from 'react'
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { uploadATSFileService } from 'lib/utils/api/upload'
import { DocumentContext } from 'context'
import { KeyboardDatePicker, DatePicker } from '@material-ui/pickers'
import { IDocumentReportParams } from 'types'
import { FileUploadDialog } from 'components'

type ParamsType = "year-month" | "state-types-documentDate-receptionDate"

interface Props {
    type: ParamsType | string
    disabled: boolean
    reportKey: string
    onChange: (key: string, value: any) => void
    params: IDocumentReportParams
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const mediumInputStyle = { width: "49%" }

const ReportParams = (props: Props) => {
    const { type, disabled, params, reportKey, onChange } = props
    const { documentTypes, historyTypes } = React.useContext(DocumentContext)
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const allTypesSelected = React.useCallback(() => {
        return documentTypes.filter(d => REPORT_TYPES_IDS.includes(d.documentTypeId)).length === documentTypes.filter(d => params.simple.types.includes(d.documentTypeId)).length && documentTypes.filter(d => params.simple.types.includes(d.documentTypeId)).length !== 0
    }, [documentTypes, params.simple.types])
    const allHistoryTypesSelected = React.useCallback(() => {
        return historyTypes.filter(c => REPORT_CONDITIONS_IDS.includes(c.historyTypeId)).length === params.simple.conditions.length && params.simple.conditions.length !== 0
    }, [historyTypes, params.simple.conditions])
    const uploadATSFile = async (files) => {
        setLoading(true)
        await uploadATSFileService(files[0].file)
        setLoading(false)
    }
    return (
        <div className="w-full flex flex-wrap pt-4">
            {
                type === "state-types-documentDate-receptionDate" &&
                <React.Fragment>
                    <div className="w-full flex items-center mb-2">
                        <Autocomplete
                            multiple
                            options={documentTypes.filter(d => REPORT_TYPES_IDS.includes(d.documentTypeId))}
                            disableCloseOnSelect
                            getOptionLabel={(option) => (option.documentName)}
                            fullWidth
                            disabled={disabled}
                            value={documentTypes.filter(d => params.simple.types.includes(d.documentTypeId))}
                            openOnFocus
                            limitTags={2}
                            classes={{ input: "autocomplete-p-s" }}
                            size="small"
                            style={{ ...mediumInputStyle, width: "100%" }}
                            ChipProps={{ size: "small", style: { maxWidth: "auto" } }}
                            onChange={(_, selectedValue) => {
                                onChange(
                                    reportKey,
                                    { ...params.simple, types: selectedValue.map(s => s.documentTypeId) }
                                )
                            }}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8, height: 35 }}
                                        checked={Boolean(params.simple.types.includes(option.documentTypeId))}
                                        size="small"
                                    />
                                    <Typography variant="body2">
                                        {option.documentName}
                                    </Typography>
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    label={FIELDS.documentType.name}
                                />
                            )}
                        />
                        <Tooltip arrow title={allTypesSelected() ? "Quitar todos" : "Selecionar todos"}>
                            <IconButton
                                onClick={() => {
                                    onChange(
                                        reportKey,
                                        { ...params.simple, types: allTypesSelected() ? [] : documentTypes.filter(d => REPORT_TYPES_IDS.includes(d.documentTypeId)).map(d => d.documentTypeId) }
                                    )
                                }}
                            >
                                <Icon fontSize="small">{allTypesSelected() ? "indeterminate_check_box" : "check_box_outline_blank"}</Icon>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className="w-full flex items-center mb-2">
                        <Autocomplete
                            multiple
                            options={historyTypes.filter(c => REPORT_CONDITIONS_IDS.includes(c.historyTypeId))}
                            disableCloseOnSelect
                            getOptionLabel={(option) => (option.name)}
                            fullWidth
                            disabled={disabled}
                            value={historyTypes.filter(c => params.simple.conditions.includes(c.historyTypeId))}
                            openOnFocus
                            limitTags={3}
                            classes={{ input: "autocomplete-p-s" }}
                            size="small"
                            style={{ ...mediumInputStyle, width: "100%" }}
                            ChipProps={{ size: "small" }}
                            onChange={(_, selectedValue) => {
                                onChange(
                                    reportKey,
                                    { ...params.simple, conditions: selectedValue.map(c => c.historyTypeId) }
                                )
                            }}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8, height: 35 }}
                                        checked={Boolean(params.simple.conditions.includes(option.historyTypeId))}
                                        size="small"
                                    />
                                    <Typography variant="body2">
                                        {option.name}
                                    </Typography>
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    label={FIELDS.flowStatus.name}
                                    autoComplete="off"
                                />
                            )}
                        />
                        <Tooltip arrow title={allHistoryTypesSelected() ? "Quitar todos" : "Selecionar todos"}>
                            <IconButton
                                onClick={() => {
                                    onChange(
                                        reportKey,
                                        { ...params.simple, conditions: allHistoryTypesSelected() ? [] : historyTypes.filter(c => REPORT_CONDITIONS_IDS.includes(c.historyTypeId)).map(h => h.historyTypeId) }
                                    )
                                }}
                            >
                                <Icon fontSize="small">{allHistoryTypesSelected() ? "indeterminate_check_box" : "check_box_outline_blank"}</Icon>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div
                        className="flex justify-between mb-2 flex items-start"
                        style={{ ...mediumInputStyle, width: "100%" }}
                    >
                        <KeyboardDatePicker
                            value={params.simple.documentStart}
                            helperText={FIELDS.documentDate.name}
                            FormHelperTextProps={{ style: { margin: 0 } }}
                            onChange={(date) => {
                                onChange(
                                    reportKey,
                                    { ...params.simple, documentStart: date }
                                )
                            }}
                            style={{ ...mediumInputStyle, marginTop: 0, marginRight: 10 }}
                            emptyLabel={FIELDS.emptyDate.name}
                            inputVariant="outlined"
                            fullWidth
                            invalidDateMessage={"Fecha inválida"}
                            disabled={disabled}
                            size="small"
                            label={FIELDS.startDate.name}
                            variant="inline"
                            format="dd/MM/yyyy"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon fontSize="small" color="action">event</Icon>
                                    </InputAdornment>
                                ),
                            }}
                            onBlur={() => {
                                if (!params.simple.documentStart || isNaN(params.simple.documentStart.getTime())) {
                                    onChange(
                                        reportKey,
                                        { ...params.simple, documentStart: new Date() }
                                    )
                                }
                            }}
                        />
                        <KeyboardDatePicker
                            value={params.simple.documentEnd}
                            onChange={(date) => {
                                onChange(
                                    reportKey,
                                    { ...params.simple, documentEnd: date }
                                )
                            }}
                            style={{ ...mediumInputStyle, marginTop: 0 }}
                            emptyLabel={FIELDS.emptyDate.name}
                            inputVariant="outlined"
                            fullWidth
                            invalidDateMessage={"Fecha inválida"}
                            disabled={disabled}
                            size="small"
                            label={FIELDS.endDate.name}
                            variant="inline"
                            format="dd/MM/yyyy"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon fontSize="small" color="action">event</Icon>
                                    </InputAdornment>
                                ),
                            }}
                            onBlur={() => {
                                if (!params.simple.documentEnd || isNaN(params.simple.documentEnd.getTime())) {
                                    onChange(
                                        reportKey,
                                        { ...params.simple, documentEnd: new Date() }
                                    )
                                }
                            }}
                        />
                        <Tooltip arrow title={"Reiniciar fechas"}>
                            <IconButton
                                onClick={() => {
                                    onChange(
                                        reportKey,
                                        { ...params.simple, documentEnd: new Date(), documentStart: new Date() }
                                    )
                                }}
                            >
                                <Icon fontSize="small">{"clear_all"}</Icon>
                            </IconButton>
                        </Tooltip>
                    </div>
                </React.Fragment>
            }
            {
                type === "year-month" &&
                <div className="flex items-start w-full">
                    <DatePicker
                        views={["year", "month"]}
                        value={params[reportKey].yearMonth}
                        onChange={(date) => {
                            onChange(
                                reportKey,
                                { yearMonth: date }
                            )
                        }}
                        FormHelperTextProps={{ style: { margin: 0 } }}
                        style={{ ...mediumInputStyle, marginTop: 0 }}
                        emptyLabel={FIELDS.emptyDate.name}
                        inputVariant="outlined"
                        fullWidth
                        disabled={disabled}
                        // maxDate={filters.documentRange.end}
                        size="small"
                        helperText={FIELDS.yearMonth.name}
                        variant="inline"
                        format="MMMM/yyyy"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon fontSize="small" color="action">event</Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {
                        reportKey === "ats" &&
                        <div className="ml-4 pt-2">
                            {loading ?
                                <CircularProgress color="primary" size={27} thickness={12} />
                                :
                                <Button
                                    disableElevation
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    onClick={() => setOpen(true)}
                                    style={{ paddingLeft: "0.85rem" }}
                                >
                                    {"Subir formato excel"}
                                    <Icon fontSize="small" className="ml-1">
                                        upload
                                    </Icon>
                                </Button>
                            }
                            <FileUploadDialog
                                open={open}
                                onClose={() => setOpen(false)}
                                onAccept={(files) => uploadATSFile(files)}
                                singleFile
                                hideDescription
                            />
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default ReportParams
