import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { FIELDS } from 'lib'
import { DOCUMENTS_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { DOCUMENT_FILE_PATH, DOWNLOAD_PATH, PDF_PATH, PURCHASE_ORDER_PATH, } from '../../constants/paths'

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })
const formDataConfig: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

export const uploadDocumentFileByDocumentIdService = async (file: any, documentId: any, description: any) => {
    const formData = new FormData()
    formData.append(FIELDS.documentId.key, documentId.toString())
    formData.append(FIELDS.file.key, file)
    formData.append(FIELDS.description.key, description)
    try {
        const result = await intance.post(DOCUMENT_FILE_PATH, formData, formDataConfig)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const uploadDocumentFileByDocumentIdServiceReimbursementInit = async (file: any, documentId: any, description: any) => {
    const formData = new FormData()
    formData.append(FIELDS.documentId.key, documentId.toString())
    formData.append(FIELDS.file.key, file)
    formData.append(FIELDS.description.key, description)
    try {
        const result = await intance.post(DOCUMENT_FILE_PATH+'/init', formData, formDataConfig)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const uploadPurchaseOrderDocumentFileByDocumentIdService = async (file: any, documentId: any, description: any) => {
    const formData = new FormData()
    formData.append(FIELDS.documentId.key, documentId.toString())
    formData.append(FIELDS.file.key, file)
    formData.append(FIELDS.tag.key, description)
    try {
        const result = await intance.post(DOCUMENT_FILE_PATH + PURCHASE_ORDER_PATH, formData, formDataConfig)
        return result.data
    } catch (error: any) {
        console.log(JSON.stringify(error))
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const downloadDocumentFileByIdService = async (documentFileId: any) => {
    try {
        const result = await intance.get(DOCUMENT_FILE_PATH + `/${documentFileId}`, { responseType: "blob" })
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const downloadPdfByDocumentFileId = async (documentFileId: any) => {
    try {
        const result = await intance.get(DOCUMENT_FILE_PATH + DOWNLOAD_PATH + PDF_PATH + `/${documentFileId}`, { responseType: "blob" })
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const deleteDocumentFileByIdService = async (documentFileId: any) => {
    try {
        const result = await intance.delete(DOCUMENT_FILE_PATH + `/${documentFileId}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}