import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, Dialog, Divider, Grid, Icon, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import { Alert, Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { InvoiceInput } from 'components'
import { ManagementContext } from 'context'
import { useGetDocuments } from 'hooks'
import { DOCUMENT_TYPE_IDS, FIELDS, MAX_FILE_SIZE, VOUCHER_MASK, VOUCHER_SIZE } from 'lib'
import React, { Fragment, useState } from 'react'
import { conformToMask } from 'react-text-mask'
import { ICustomer, IDocumentFile, IMessageConfig } from 'types'
import { IRetentionAdditionalInfoHeader } from 'types/retention'
import NotResultsImage from 'assets/img/notresults.png'

type Props = {
    header: IRetentionAdditionalInfoHeader
    onChange: (header: IRetentionAdditionalInfoHeader) => void
}

const inputStyle = { marginBottom: 7.5, marginTop: 7.5 }

const RetentionHeader = (props: Props) => {
    const { header, onChange } = props
    const [expanded, setExpanded] = React.useState<boolean>(true)
    const [disableDocumentDate, setDisableDocumentDate] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const { customersNewRetention, customersFilter, loadInitialCustomersWithFilterNoPage, setCustomersFilter, setContextLoading } = React.useContext(ManagementContext)
    const { getDocuments, documents, clear } = useGetDocuments()
    const [clienteSeleccionado, setclienteSeleccionado] = useState<ICustomer | undefined>(undefined)

    const handleFilter = async () => {
        if (customersFilter.length >= 3) {
            try {
                setContextLoading(true)
                setclienteSeleccionado(undefined)
                await loadInitialCustomersWithFilterNoPage()
                setContextLoading(false)
            } catch (error) {
                setclienteSeleccionado(undefined)
                setContextLoading(false)
            }
        } else {
            alert('Ingresa minimo 3 caracteres para realizar la busqueda')
        }
    }

    // DIALOGO FULL WIDTH CLIENTES
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };

    const clienteSeleccionadoColor = (row: ICustomer) => {
        if (clienteSeleccionado !== undefined) {
            if (clienteSeleccionado.customerId == row.customerId) {
                return true
            } else {
                return false
            }
        } else {
            return false;
        }
    }

    const seleccionarCliente = () => {
        onChange({
            ...header,
            customer: clienteSeleccionado ?? undefined
        })
        if (clienteSeleccionado) {
            getDocuments([DOCUMENT_TYPE_IDS.electronicInvoice, DOCUMENT_TYPE_IDS.printedInvoice], clienteSeleccionado.identification)
            handleClose()
        } else {
            clear()
            handleClose()
        }
        handleClose()
    }

    return (
        <React.Fragment>
            <Accordion expanded={expanded} onChange={() => setExpanded(current => !current)}>
                <AccordionSummary
                    style={{ marginRight: 10 }}
                    expandIcon={<Icon>expand_more</Icon>}
                >
                    <div className="flex items-center">
                        <Icon color="action">short_text</Icon>
                        <Typography className="pl-2" style={{ fontWeight: 600, letterSpacing: 0.5 }} color="textSecondary" variant="body2">{"Datos de la retención"}</Typography>

                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2} wrap="wrap">
                        <Grid item xs={4}>
                            <TextField
                                size="small"
                                label={'Cliente'}
                                style={{ ...inputStyle, width: "100%" }}
                                variant="outlined"
                                required
                                value={header.customer?.commercialName}
                                onClick={handleClickOpen}
                            />
                            {/*
                            <Autocomplete
                                options={customers}
                                style={inputStyle}
                                getOptionLabel={(option) => `${option.identification} - ${option.companyName}`}
                                size="small"
                                openOnFocus
                                noOptionsText="No se encontraron clientes registrados..."
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            label={FIELDS.customer.name}
                                            variant="outlined"
                                            required
                                        />
                                }
                                value={header.customer || null}
                                onChange={(_, value) => {
                                    onChange({
                                        ...header,
                                        customer: value ?? undefined
                                    })
                                    if (value) {
                                        getDocuments([DOCUMENT_TYPE_IDS.electronicInvoice, DOCUMENT_TYPE_IDS.printedInvoice], value.identification)
                                    } else {
                                        clear()
                                    }
                                }}
                            />
                            */}
                            <TextField
                                size="small"
                                label={FIELDS.documentNumber.name}
                                style={{ ...inputStyle, width: "100%" }}
                                variant="outlined"
                                InputProps={{
                                    inputComponent: InvoiceInput as any,
                                }}
                                required
                                value={header.documentNumber}
                                onChange={(e) => {
                                    const value = e.target.value
                                    onChange({
                                        ...header,
                                        documentNumber: value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <KeyboardDatePicker
                                style={inputStyle}
                                className="date-input"
                                autoOk
                                invalidDateMessage={FIELDS.invalidDate.name}
                                InputAdornmentProps={{ position: "start", style: { margin: 0 } }}
                                value={header.emissionDate ?? null}
                                onChange={(date) => {
                                    onChange({
                                        ...header,
                                        emissionDate: date ?? undefined
                                    })
                                }}
                                inputVariant="outlined"
                                fullWidth
                                emptyLabel={FIELDS.emptyDate.name}
                                required
                                size="small"
                                label={FIELDS.emissionDate.name}
                                variant="inline"
                                format="dd/MM/yyyy"
                                maxDate={new Date()}
                            />
                            <Grid item>
                                <div style={inputStyle} className="flex flex-row items-center">
                                    <label htmlFor="approval-button-file" className="w-full">
                                        <Button
                                            style={{ height: 40, justifyContent: "flex-start" }}
                                            variant="outlined"
                                            disableElevation
                                            fullWidth
                                            size="small"
                                            component="span"
                                            disabled={Boolean(header.file?.file)}
                                        >
                                            <div className="flex-grow flex pl-1 truncate" style={{ textTransform: "none", fontSize: "0.9rem" }}>
                                                <p className="truncate" style={{ maxWidth: 200 }}>
                                                    {header.file ? header.file?.file.name : "Archivo"}
                                                </p>
                                            </div>
                                            <Divider orientation="vertical" />
                                            {header.file ? "" : "Subir archivo"}
                                            {!header.file &&
                                                <Icon fontSize="small" color={Boolean(header.file) ? "disabled" : "action"} className="mx-2">publish</Icon>
                                            }
                                        </Button>
                                    </label>
                                    {
                                        header.file &&
                                        <Tooltip arrow title="Eliminar archivo">
                                            <IconButton onClick={() => onChange({ ...header, file: undefined })} style={{ marginLeft: 8 }}>
                                                <Icon fontSize="small" color="action">
                                                    close
                                                </Icon>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </div>
                                <input
                                    accept="application/pdf"
                                    className="hidden"
                                    id="approval-button-file"
                                    type="file"
                                    multiple={false}
                                    onChange={(e) => {
                                        if (e.target.files !== null && e.target.files[0]) {
                                            if (e.target.files[0].size > MAX_FILE_SIZE) {
                                                setMessageConfig({ message: "El tamaño del archivo excede el máximo permitido", open: true, severity: "error" })
                                                return
                                            }
                                            onChange({ ...header, file: { file: e.target.files[0] } as IDocumentFile })
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider orientation='vertical' />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            direction="column"
                            spacing={2}
                            style={{ maxWidth: "100%", flexGrow: 1 }}
                        >
                            <Autocomplete
                                options={documents}
                                style={inputStyle}
                                getOptionLabel={(option) => conformToMask(option.serialNumber, VOUCHER_MASK, { guide: false }).conformedValue}
                                size="small"
                                openOnFocus
                                freeSolo
                                inputValue={header.associatedDocument?.serialNumber?.length === VOUCHER_SIZE ? conformToMask(header.associatedDocument?.serialNumber, VOUCHER_MASK, { guide: false }).conformedValue : (header.associatedDocument?.serialNumber ?? undefined)}
                                onInputChange={(e, value) => {
                                    if (value.length === VOUCHER_SIZE) {
                                        const document = documents.find(d => d.serialNumber === value)
                                        if (document) {
                                            onChange({
                                                ...header,
                                                associatedDocument: {
                                                    serialNumber: document.serialNumber,
                                                    emissionDate: document.documentDate,
                                                    documentId: document.documentId
                                                }
                                            })
                                            setDisableDocumentDate(true)
                                            return
                                        }
                                    }
                                    value = value.replaceAll("-", "")
                                    if (value.length > VOUCHER_SIZE) {
                                        value = value.substring(0, VOUCHER_SIZE)
                                    }
                                    onChange({
                                        ...header,
                                        associatedDocument: {
                                            serialNumber: value,
                                            emissionDate: undefined,
                                            documentId: undefined
                                        }
                                    })
                                    setDisableDocumentDate(false)
                                }}
                                noOptionsText="No se encontraron documentos emitidos a ese cliente..."
                                renderInput={
                                    (params) =>
                                        <TextField
                                            {...params}
                                            label={FIELDS.associatedDocument.name}
                                            variant="outlined"
                                            required
                                        />
                                }
                                onChange={(_, value, reason) => {
                                    onChange({
                                        ...header,
                                        associatedDocument: {
                                            serialNumber: typeof value === "string" ? value : value?.serialNumber ?? "",
                                            emissionDate: typeof value === "string" ? undefined : value?.documentDate ?? undefined,
                                            documentId: typeof value === "string" ? undefined : value?.documentId ?? undefined
                                        }
                                    })
                                    if (typeof value === "object") {
                                        setDisableDocumentDate(true)
                                    }
                                    if (reason === "clear" || reason === "remove-option") {
                                        setDisableDocumentDate(false)
                                    }
                                }}
                            />
                            <KeyboardDatePicker
                                style={inputStyle}
                                className="date-input"
                                autoOk
                                invalidDateMessage={FIELDS.invalidDate.name}
                                InputAdornmentProps={{ position: "start", style: { margin: 0 } }}
                                value={header.associatedDocument?.emissionDate ?? null}
                                onChange={(e) => {
                                    onChange({
                                        ...header,
                                        associatedDocument: {
                                            ...header.associatedDocument,
                                            emissionDate: e ?? undefined,
                                            serialNumber: header.associatedDocument?.serialNumber ?? ""
                                        }
                                    })
                                }}
                                inputVariant="outlined"
                                fullWidth
                                emptyLabel={FIELDS.emptyDate.name}
                                required
                                size="small"
                                label={FIELDS.documentEmissionDate.name}
                                variant="inline"
                                format="dd/MM/yyyy"
                                maxDate={new Date()}
                                disabled={disableDocumentDate}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <AppBar style={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography style={{ marginLeft: 2 }} variant="h6" component="div">
                            Seleccion de Cliente
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Box style={{ display: 'flex', justifyContent: 'start', margin: '30px' }}>
                    <TextField
                        onChange={(e) => setCustomersFilter(e.target.value)}
                        size="small"
                        label={'Buscar por razon social o identificación'}
                        style={{ ...inputStyle, width: "100%" }}
                        variant="outlined"
                    />
                    <Button color="primary" variant="contained" size="small" style={{ marginLeft: '20px', borderRadius: '50%', padding: '0px' }}>
                        <IconButton color="inherit" onClick={handleFilter} aria-label="close" style={{ margin: '0px' }}>
                            <SearchIcon />
                        </IconButton>
                    </Button>
                </Box>
                {customersNewRetention !== undefined && customersNewRetention.length > 0 &&
                    <Box style={{ display: 'flex', justifyContent: 'center', margin: '0px 30px', flexDirection: 'column', alignContent: 'center' }}>
                        <Fragment>
                            <Box>
                                <h2>Seleccione el cliente en el siguiente listado:</h2>
                            </Box>
                            <Paper style={{ width: '100%', overflow: 'hidden', marginTop: '20px' }} >
                                <TableContainer style={{ maxHeight: 350 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell key={1} align={'left'} style={{ paddingTop: '3px', paddingBottom: '3px', backgroundColor: '#bababa', color: 'white' }}>{'Razon Social'}</TableCell>
                                                <TableCell key={1} align={'left'} style={{ paddingTop: '3px', paddingBottom: '3px', backgroundColor: '#bababa', color: 'white' }}>{'Identificacion'}</TableCell>
                                                <TableCell key={1} align={'left'} style={{ paddingTop: '3px', paddingBottom: '3px', backgroundColor: '#bababa', color: 'white' }}>{'Tipo Identificacion'}</TableCell>
                                                <TableCell key={1} align={'left'} style={{ paddingTop: '3px', paddingBottom: '3px', backgroundColor: '#bababa', color: 'white' }}>{'Estado'}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {customersNewRetention?.map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} onClick={() => { setclienteSeleccionado(row) }} style={{ cursor: 'pointer' }}>
                                                        <TableCell align="left" style={{ padding: '0px 16px', backgroundColor: clienteSeleccionadoColor(row) ? 'yellow' : 'white' }}>{row.commercialName}</TableCell>
                                                        <TableCell align="left" style={{ padding: '0px 16px', backgroundColor: clienteSeleccionadoColor(row) ? 'yellow' : 'white' }}>{row.identification}</TableCell>
                                                        <TableCell align="left" style={{ padding: '0px 16px', backgroundColor: clienteSeleccionadoColor(row) ? 'yellow' : 'white' }}>{row.identificationType?.name}</TableCell>
                                                        <TableCell align="left" style={{ padding: '0px 16px', backgroundColor: clienteSeleccionadoColor(row) ? 'yellow' : 'white' }}>
                                                            <span className={row.active ? "active-state-label" : "inactive-state-label"}>{row.active ? "Activo" : "Inactivo"}</span>
                                                        </TableCell>

                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Fragment>
                        <Grid container justify="flex-end" style={{ marginTop: '20px' }}>
                            <Grid item>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    onClick={handleClose}
                                >
                                    {"Cancelar"}
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { seleccionarCliente() }}
                                    disabled={clienteSeleccionado == undefined}
                                    disableElevation
                                    style={{ marginLeft: '10px' }}
                                >
                                    {"Seleccionar"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                }
                {customersNewRetention == undefined || customersNewRetention.length == 0 &&
                    <Box style={{ display: 'flex', justifyContent: 'center', margin: '0px 30px', flexDirection: 'column', alignContent: 'center' }}>
                        <Paper elevation={0} style={{ border: '1px solid #C4C4C4', borderRadius: '5px', padding: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={NotResultsImage} style={{ width: '20vw', height: 'auto' }}></img>
                            {customersFilter == '' ?
                                <h1 style={{ marginTop: '10px' }}>{'Ingresa el parametro de busqueda para conocer los resultados'}</h1>
                                :
                                <h1 style={{ marginTop: '10px' }}>{'No existen resultado para: '}<span style={{color:'red'}}>{customersFilter}</span>{' Intente con otra busqueda'} </h1>
                            }
                        </Paper>
                    </Box>
                }
            </Dialog>
        </React.Fragment>
    )
}

export default RetentionHeader