import { FIELDS } from "lib"

export const SUPPPLIER_TABLE_COLUMNS = [
    { id: FIELDS.comercialName.key, label: FIELDS.comercialName.name },
    { id: FIELDS.identification.key, label: FIELDS.identification.name },
    { id: FIELDS.identificationType.key, label: FIELDS.identificationType.name },
    { id: FIELDS.keepAccounting.key, label: FIELDS.keepAccounting.name },
    { id: FIELDS.requirePo.key, label: FIELDS.requirePo.name },
    { id: FIELDS.taxPlansCount.key, label: FIELDS.taxPlansCount.name },
    { id: FIELDS.active.key, label: FIELDS.activeAlt.name },
]

export const SUPPLIER_TABS = [
    { key: 0, label: FIELDS.generalInfo.name },
    { key: 1, label: FIELDS.sriInfo.name },
    { key: 2, label: FIELDS.paymentInfo.name },
    { key: 3, label: FIELDS.bankInfo.name },
    { key: 4, label: FIELDS.taxesInfo.name },
    { key: 5, label: FIELDS.filesInfo.name }
]

export const SUPPLIER_TYPES = [
    {
      value: 'Retenciones',
    },
    {
      value: 'Órdenes de Compra',
    },
    {
      value: 'Ambos',
    },
  ]

export const SUPPLIER_CONTACT_TYPES = [
    { name: 'Notificaciones de Pago', key: 0 },
    { name: 'Rechazos de Facturas', key: 1 },
    { name: 'Envío de Comprobantes de Retención Electrónicos', key: 2 },
    { name: 'Anulación de Comprobantes de Retención Electrónicos', key: 3 },
    { name: 'Gerente Financiero', key: 4 },
    { name: 'Conciliación de Cuenta', key: 5 },
    { name: 'Ejecutivo de Cuenta', key: 6 },
  ]