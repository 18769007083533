import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FileUploaderProgress } from 'components'
import { uploadDocumentFileByDocumentIdService, uploadDocumentFileByDocumentIdServiceReimbursementInit, uploadPurchaseOrderDocumentFileByDocumentIdService, uploadSupplierFileService } from 'lib'
import React from 'react'
import { IDocumentFile, IMessageConfig, ISupplierFileRequest, IUploadDocumentFile } from 'types'

interface Props {
    uploadDocumentFiles: (files: IUploadDocumentFile[], documentId: number) => Promise<IDocumentFile[]>
    uploadDocumentFilesReimbursementInit: (files: IUploadDocumentFile[], documentId: number) => Promise<IDocumentFile[]>
    uploadPurchaseOrderFiles: (files: IUploadDocumentFile[], documentId: number) => Promise<IDocumentFile[]>
    uploadSupplierFilesByTag: (filesByTag: ISupplierFileRequest[], supplierId: number) => Promise<boolean>
}

export const FileContext = React.createContext({} as Props)

const FileProvider: React.FC = ({ children }) => {
    const [total, setTotal] = React.useState<number>(0)
    const [success, setSuccess] = React.useState<number>(0)
    const [error, setError] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const [open, setOpen] = React.useState<boolean>(false)
    const uploadDocumentFiles = async (files: IUploadDocumentFile[], documentId: number): Promise<IDocumentFile[]> => {
        setTotal(files.length)
        setOpen(true)
        const uploadedFiles: IDocumentFile[] = []
        try {
            for (const upload of files) {
                const response = await uploadDocumentFileByDocumentIdService(upload.file, documentId, upload.description)
                setSuccess(current => current + 1)
                uploadedFiles.push(response)
            }
            setOpen(false)
            setMessageConfig({ open: true, message: "Archivos subidos correctamente!", severity: "success" })
            setSuccess(0)
            return uploadedFiles
        } catch (error) {
            setError(true)
            setOpen(false)
            //setMessageConfig({ open: true, message: "No se pudo subir los archivos", severity: "error" })
            return uploadedFiles
        }
    }

    const uploadDocumentFilesReimbursementInit = async (files: IUploadDocumentFile[], documentId: number): Promise<IDocumentFile[]> => {
        setTotal(files.length)
        setOpen(true)
        const uploadedFiles: IDocumentFile[] = []
        try {
            for (const upload of files) {
                const response = await uploadDocumentFileByDocumentIdServiceReimbursementInit(upload.file, documentId, upload.description)
                setSuccess(current => current + 1)
                uploadedFiles.push(response)
            }
            setOpen(false)
            setMessageConfig({ open: true, message: "Archivos subidos correctamente!", severity: "success" })
            setSuccess(0)
            return uploadedFiles
        } catch (error) {
            setError(true)
            setOpen(false)
            //setMessageConfig({ open: true, message: "No se pudo subir los archivos", severity: "error" })
            return uploadedFiles
        }
    }

    const uploadPurchaseOrderFiles = async (files: IUploadDocumentFile[], documentId: number): Promise<IDocumentFile[]> => {
        setTotal(files.length)
        setOpen(true)
        const uploadedFiles: IDocumentFile[] = []
        try {
            for (const upload of files) {
                const response = await uploadPurchaseOrderDocumentFileByDocumentIdService(upload.file, documentId, upload.description)
                setSuccess(current => current + 1)
                uploadedFiles.push(response)
            }
            setOpen(false)
            setMessageConfig({ open: true, message: "Archivos subidos correctamente!", severity: "success" })
            setSuccess(0)
            return uploadedFiles
        } catch (error) {
            setError(true)
            setOpen(false)
            setMessageConfig({ open: true, message: "No se pudo subir los archivos", severity: "error" })
            return uploadedFiles
        }
    }

    const uploadSupplierFilesByTag = async (filesByTag: ISupplierFileRequest[], supplierId: number): Promise<boolean> => {
        if (filesByTag.length === 0) { return true }
        setTotal(filesByTag.length)
        setOpen(true)
        try {
            for (const fileByTag of filesByTag) {
                await uploadSupplierFileService(supplierId, fileByTag.tag, fileByTag.file)
                setSuccess(current => current + 1)
            }
            setOpen(false)
            setMessageConfig({ open: true, message: "Archivos subidos correctamente!", severity: "success" })
            setSuccess(0)
            return true
        } catch (error) {
            setError(true)
            setOpen(false)
            setMessageConfig({ open: true, message: "No se pudo subir los archivos", severity: "error" })
            return false
        }
    }
    return (
        <FileContext.Provider
            value={{
                uploadDocumentFiles,
                uploadSupplierFilesByTag,
                uploadPurchaseOrderFiles,
                uploadDocumentFilesReimbursementInit
            }}
        >
            {children}
            {
                open &&
                <FileUploaderProgress
                    error={error}
                    success={success}
                    total={total}
                />
            }
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </FileContext.Provider>
    )
}

export default FileProvider
