import {
  Button,
  Popover,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core"
import { deleteSupplierFileByIdService, FIELDS } from "lib"
import React from "react"
import { IAccountType, IMenu, IMessageConfig, ISupplier, ISupplierFile } from "types"
import { Alert } from "@material-ui/lab"

interface Props {
  handleChange: (key: string, value: any) => void;
  supplier: ISupplier
  accountTypes: IAccountType[]
  rucFile: ISupplierFile
  setRucFile: React.Dispatch<ISupplierFile>
  appointmentFile: ISupplierFile
  setAppointmentFile: React.Dispatch<ISupplierFile>
}

const inputStyle: React.CSSProperties = { width: "49%", marginBottom: 15 };

const SupplierSRIForm = (props: Props) => {
  const { handleChange, supplier, rucFile, setRucFile, appointmentFile, setAppointmentFile, } = props
  const [menu, setMenu] = React.useState<IMenu>({ anchorEl: null, selected: undefined })
  const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
  const handleDelete = async (supplierFileId: number) => {
    await deleteSupplierFileByIdService(supplierFileId)
    setMenu({ selected: undefined, anchorEl: null })
    if (supplierFileId === rucFile.supplierFileId) {
      setRucFile({} as ISupplierFile)
    }
    if (supplierFileId === appointmentFile.supplierFileId) {
      setAppointmentFile({} as ISupplierFile)
    }
  }

  return (
    <div className="w-full h-auto flex flex-wrap justify-between items-start">
      <TextField
        size="small"
        label={FIELDS.specialTaxpayer.name}
        variant="outlined"
        style={inputStyle}
        value={supplier[FIELDS.specialTaxpayer.key]}
        onChange={(e) =>
          handleChange(FIELDS.specialTaxpayer.key, e.target.value)
        }
      />
      <TextField
        size="small"
        label={FIELDS.activity.name}
        variant="outlined"
        style={inputStyle}
        value={supplier[FIELDS.activity.key]}
        onChange={(e) => handleChange(FIELDS.activity.key, e.target.value)}
      />
      {/*Archivo RUC*/}
      {/*
      <div
        style={{ ...inputStyle, height: 40 }}
        className="flex flex-row items-center"
      >
        <label htmlFor="rucFile-button-file" className="w-full h-full">
          <Button
            variant="outlined"
            className="h-full"
            disableElevation
            fullWidth
            size="small"
            component="span"
            disabled={Boolean(rucFile.file)}
          >
            <div
              className="flex-grow flex pl-1 truncate"
              style={{ textTransform: "none", fontSize: "0.9rem" }}
            >
              <p className="truncate" style={{ maxWidth: 250 }}>
                {rucFile.file ? rucFile.file.name : "Archivo del Ruc"}
              </p>
            </div>
            <Divider orientation="vertical" />
            {rucFile.file ? "" : "Subir archivo"}
            {
              !rucFile.file &&
              <Icon
                fontSize="small"
                color={Boolean(rucFile.file) ? "disabled" : "action"}
                className="mx-2"
              >
                publish
              </Icon>
            }
          </Button>
        </label>
        {rucFile.supplierFileId && (
          <Tooltip arrow title="Descargar">
            <IconButton
              style={{ marginLeft: 8 }}
              onClick={() => handleDownload(rucFile)}
            >
              <Icon fontSize="small" color="action">
                get_app
              </Icon>
            </IconButton>
          </Tooltip>
        )}
        {rucFile.file && (
          <Tooltip arrow title="Eliminar archivo">
            <IconButton
              onClick={(e) => {
                if (rucFile.supplierFileId) {
                  setMenu({ selected: rucFile, anchorEl: e.currentTarget })
                } else {
                  setRucFile({} as ISupplierFile)
                }
              }}
              style={{ marginLeft: 8 }}
            >
              {" "}
              <Icon fontSize="small" color="action">
                close
              </Icon>
            </IconButton>
          </Tooltip>
        )}
      </div>
      <input
        accept="image/*,application/pdf"
        className="hidden"
        id="rucFile-button-file"
        type="file"
        multiple={false}
        onChange={(e) => {
          if (e.target.files !== null && e.target.files[0]) {
            if (e.target.files[0].size > MAX_FILE_SIZE) {
              setMessageConfig({ message: "El tamaño del archivo excede el máximo permitido", open: true, severity: "error" })
              return
            }
            setRucFile({ file: e.target.files[0] })
          }
        }}
      />
      */}
      {/*Nombramiento */}
      {/*
      <div
        style={{ ...inputStyle, height: 40 }}
        className="flex flex-row items-center"
      >
        <label htmlFor="appointmentFile-button-file" className="w-full h-full">
          <Button
            variant="outlined"
            className="h-full"
            disableElevation
            fullWidth
            size="small"
            component="span"
            disabled={Boolean(appointmentFile.file)}
          >
            <div
              className="flex-grow flex pl-1 truncate"
              style={{ textTransform: "none", fontSize: "0.9rem" }}
            >
              <p className="truncate" style={{ maxWidth: 250 }}>
                {appointmentFile.file
                  ? appointmentFile.file?.name
                  : "Archivo Nombramiento"}
              </p>
            </div>
            <Divider orientation="vertical" />
            {appointmentFile.file ? "" : "Subir archivo"}
            {
              !appointmentFile.file &&
              <Icon
                fontSize="small"
                color={Boolean(appointmentFile.file) ? "disabled" : "action"}
                className="mx-2"
              >
                publish
              </Icon>
            }
          </Button>
        </label>
        {appointmentFile.supplierFileId && (
          <Tooltip arrow title="Descargar">
            <IconButton
              style={{ marginLeft: 8 }}
              onClick={() => handleDownload(appointmentFile)}
            >
              <Icon fontSize="small" color="action">
                get_app
              </Icon>
            </IconButton>
          </Tooltip>
        )}
        {appointmentFile.file && (
          <Tooltip arrow title="Eliminar archivo">
            <IconButton
              onClick={(e) => {
                if (appointmentFile.supplierFileId) {
                  setMenu({ selected: appointmentFile, anchorEl: e.currentTarget })
                } else {
                  setAppointmentFile({} as ISupplierFile)
                }
              }}
              style={{ marginLeft: 8 }}
            >
              <Icon fontSize="small" color="action">
                close
              </Icon>
            </IconButton>
          </Tooltip>
        )}
      </div>
      <input
        accept="image/*,application/pdf"
        className="hidden"
        id="appointmentFile-button-file"
        type="file"
        multiple={false}
        onChange={(e) => {
          if (e.target.files !== null && e.target.files[0]) {
            if (e.target.files[0].size > MAX_FILE_SIZE) {
              setMessageConfig({ message: "El tamaño del archivo excede el máximo permitido", open: true, severity: "error" })
              return
            }
            setAppointmentFile({ file: e.target.files[0] })
          }
        }}
      />
      */}
      <Popover
        open={Boolean(menu.anchorEl)}
        anchorEl={menu.anchorEl}
        onClose={() => setMenu({ selected: undefined, anchorEl: null })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="flex flex-col p-4 items-center justify-center">
          <Typography>
            {"¿Estás seguro de eliminar este archivo?"}
          </Typography>
          <div className="mt-3">
            <Button
              size="small"
              color="primary"
              variant="contained"
              style={{ marginRight: 5 }}
              onClick={() => handleDelete(menu.selected.supplierFileId)}
            >
              {"Si"}
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => setMenu({ selected: undefined, anchorEl: null })}
            >
              {"No"}
            </Button>
          </div>
        </div>
      </Popover>
      <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
          {messageConfig.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default SupplierSRIForm;
