import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Icon, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, Snackbar, TextField, Tooltip, Typography } from '@material-ui/core'
import { Alert, Autocomplete, createFilterOptions } from '@material-ui/lab'
import { FIELDS, formatCurrency, MAX_FILE_SIZE, PURCHASE_ORDER_STATUS } from 'lib'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { InvoiceInput, AccountInfoDialog as AccountingDialog, CurrencyInput, DocumentFiles } from 'components'
import countryList from 'iso-3166-country-list'
import { IAccountInfo, IDocumentFile, IHeaderInputs, IIdentificationType, IMessageConfig, ISupplier } from 'types'
import BLInput from 'components/BLInput/BLInput'
import { useGetExceptions, useSimplifiedPurchaseOrders, useValidCreationDate } from 'hooks'

interface Props {
    inputs: IHeaderInputs
    onChange: (key: string, value: any) => void
    onChangeAccountInfo: (value: any) => void
    header: any
    requireAccountInfo?: boolean
    accountInfo?: IAccountInfo
    loading: boolean
    suppliers: ISupplier[]
    supplierCallback?: boolean,
    onChangeSupplier?: (supplier: ISupplier) => void
    identificationTypes: IIdentificationType[]
    requiredPo?: boolean
    type: string
}

const inputStyle = { width: "48%", marginBottom: 7.5, marginTop: 7.5 }
const inputStylePurchase = { width: "78%", marginBottom: 7.5, marginTop: 7.5 }

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: ISupplier) => option.identification + option.companyName,
})

const INITIAL_CONDITIONS = [PURCHASE_ORDER_STATUS.finished.conditionId.toString()]

const DocumentHeader = (props: Props) => {
    const { inputs, onChange: handleChange, header, loading, suppliers, supplierCallback, onChangeSupplier, identificationTypes, requireAccountInfo, onChangeAccountInfo: handleChangeAccountInfo, accountInfo, requiredPo, type } = props
    const [expanded, setExpanded] = React.useState<boolean>(true)
    const [registeredSupplier, setRegisteredSupplier] = React.useState<boolean>(true)
    const [fiscalDocument, setFiscalDocument] = React.useState<boolean>(true)
    const [openAccountingDialog, setOpenAccountingDialog] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const { isValid: canCreateDocument } = useValidCreationDate()
    const { exceptions } = useGetExceptions()
    const handleRegisteredSupplier = (_: any, checked: boolean) => {
        setRegisteredSupplier(checked)
        if (checked) {
            delete header[FIELDS.supplierId.key]
            delete header[FIELDS.identificationType.key]
            delete header[FIELDS.name.key]
            delete header[FIELDS.country.key]
        } else {
            delete header[FIELDS.supplier.key]
        }
    }
    const handleFiscalDocument = (_: any, checked: boolean) => {
        setFiscalDocument(checked)
        if (checked) {
            delete header[FIELDS.documentNumber.key]
        } else {
            delete header[FIELDS.invoiceNumber.key]
        }
    }
    const isReimbursement = React.useCallback(() => {
        return type === "employee-reimbursement" || type === "cash-reimbursement"
    }, [type])

    const getSuppliers = React.useCallback(() => {
        if (canCreateDocument !== undefined) {
            const supplierIds = exceptions.map(e => e.supplierId)
            return canCreateDocument ? suppliers : suppliers.filter(s => supplierIds.includes(s.supplierId))
        }
        return []
    }, [canCreateDocument, suppliers, exceptions])

    React.useEffect(() => {
        setRegisteredSupplier(true)
        setFiscalDocument(true)
    }, [props.inputs])

    React.useEffect(() => {
        if (type === "employee-reimbursement" || type === "cash-reimbursement") {
            handleChange(FIELDS.emissionDate.key, new Date())
        }
    }, [type, handleChange])

    const { accountingInfoPurchaseOrders: purchaseOrders } = useSimplifiedPurchaseOrders({ conditions: INITIAL_CONDITIONS, receiverRuc: header?.supplier?.identification ?? "" })

    //////// DIALOGO DE DOCUMENTOS SUBIDOS
     const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const eliminarArchivo = (index) => {
        const archivosActuales = header[FIELDS.files.key] || [];
        const nuevosArchivos = [...archivosActuales.slice(0, index), ...archivosActuales.slice(index + 1)];
        handleChange('files', nuevosArchivos);
    }; 
    //////// DIALOGO DE DOCUMENTOS SUBIDOS


    return (
        <React.Fragment>
            <Accordion expanded={expanded} onChange={() => setExpanded(current => !current)}>
                <AccordionSummary
                    style={{ marginRight: 10 }}
                    expandIcon={<Icon>expand_more</Icon>}
                >
                    <div className="flex items-center">
                        <Icon color="action">short_text</Icon>
                        <Typography className="pl-2" style={{ fontWeight: 600, letterSpacing: 0.5 }} color="textSecondary" variant="body2">{"Datos del documento"}</Typography>
                        {
                            (canCreateDocument !== undefined && !canCreateDocument) &&
                            <Alert severity="error" style={{ padding: "0px 16px", marginLeft: "16px" }}>
                                <Typography className="pl-2" variant="caption">
                                    {"Solo se puede emitir documentos a proveedores marcados como excepciones."}
                                </Typography>
                            </Alert>
                        }
                    </div>
                    {
                        (inputs.toggleDocument || inputs.toggleSupplier) &&
                        <div className="ml-8 items-center flex">
                            {
                                inputs.toggleSupplier &&
                                <FormControlLabel
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    control={
                                        <Checkbox
                                            checked={registeredSupplier}
                                            onChange={handleRegisteredSupplier}
                                            name="checkedB"
                                            color="primary"
                                            size="small"
                                        />
                                    }
                                    label={FIELDS.registeredSupplier.name}
                                />
                            }
                            {
                                inputs.toggleDocument &&
                                <FormControlLabel
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    control={
                                        <Checkbox
                                            checked={fiscalDocument}
                                            onChange={handleFiscalDocument}
                                            name="checkedB"
                                            color="primary"
                                            size="small"
                                        />
                                    }
                                    label={FIELDS.fiscalDocument.name}
                                />
                            }
                        </div>
                    }
                </AccordionSummary>
                <AccordionDetails>
                    <div className="w-full flex flex-wrap justify-start">
                        <div style={{ width: isReimbursement() ? "54%" : "62%", height: "min-content" }} className="flex flex-wrap justify-between">
                            {
                                isReimbursement() && false &&
                                <Typography className="w-full" style={{ fontSize: "0.8em", fontWeight: 800, width: "100%", opacity: 0 }}>{`_`}</Typography>
                            }
                            {
                                !registeredSupplier &&
                                <div className="w-full items-center flex justify-between flex-wrap">
                                    <Autocomplete
                                        options={identificationTypes}
                                        style={inputStyle}
                                        getOptionLabel={(option) => option.name}
                                        size="small"
                                        openOnFocus
                                        noOptionsText="No se encontraron tipos de identificación registrados..."
                                        renderInput={
                                            (params) =>
                                                <TextField
                                                    {...params}
                                                    label={FIELDS.identificationType.name}
                                                    variant="outlined"
                                                    required
                                                />
                                        }
                                        value={header[FIELDS.identificationType.key] ? header[FIELDS.identificationType.key] : null}
                                        onChange={(_, value) => handleChange(FIELDS.identificationType.key, value)}
                                    />
                                    <TextField
                                        size="small"
                                        label={FIELDS.supplierId.name}
                                        style={inputStyle}
                                        variant="outlined"
                                        required
                                        value={header[FIELDS.supplierId.key] ? header[FIELDS.supplierId.key] : ""}
                                        onChange={(e) => handleChange(FIELDS.supplierId.key, e.target.value)}
                                    />
                                    <TextField
                                        size="small"
                                        label={FIELDS.name.name}
                                        style={inputStyle}
                                        variant="outlined"
                                        required
                                        value={header[FIELDS.name.key] ? header[FIELDS.name.key] : ""}
                                        onChange={(e) => handleChange(FIELDS.name.key, e.target.value)}
                                    />
                                    <Autocomplete
                                        options={countryList.names}
                                        style={inputStyle}
                                        size="small"
                                        openOnFocus
                                        noOptionsText="No se encontraron paices registrados..."
                                        renderInput={
                                            (params) =>
                                                <TextField
                                                    {...params}
                                                    label={FIELDS.country.name}
                                                    variant="outlined"
                                                    required
                                                />
                                        }
                                        value={header[FIELDS.country.key] ? header[FIELDS.country.key] : null}
                                        onChange={(_, value) => handleChange(FIELDS.country.key, value)}
                                    />
                                </div>
                            }
                            {
                                (inputs.toggleSupplier ? registeredSupplier : inputs.supplier) &&
                                <Autocomplete
                                    loading={loading}
                                    loadingText="Cargando..."
                                    options={getSuppliers()}
                                    clearText="Borrar"
                                    getOptionLabel={(option) => `${option.identification} - ${option.companyName}`}
                                    filterOptions={filterOptions}
                                    noOptionsText={canCreateDocument === undefined ? "Cargando..." : "No existen proveedores registrados o no hay excepciones..."}
                                    style={inputStyle}
                                    size="small"
                                    openOnFocus
                                    renderInput={
                                        (params) =>
                                            <TextField
                                                autoFocus
                                                {...params}
                                                label={!isReimbursement() ? FIELDS.supplier.name : "Empleado"}
                                                variant="outlined"
                                                required
                                            />
                                    }
                                    value={header[FIELDS.supplier.key] ?? null}
                                    onChange={(_, value) => { handleChange(FIELDS.supplier.key, value); if (onChangeSupplier && value) { onChangeSupplier(value) } }}
                                />
                            }
                            {
                                inputs.creditNoteNumber &&
                                <TextField
                                    size="small"
                                    label={FIELDS.creditNoteNumber.name ?? ""}
                                    style={inputStyle}
                                    variant="outlined"
                                    InputProps={{
                                        inputComponent: InvoiceInput as any,
                                    }}
                                    required
                                    value={header[FIELDS.creditNoteNumber.key] ? header[FIELDS.creditNoteNumber.key] : ""}
                                    onChange={(e) => handleChange(FIELDS.creditNoteNumber.key, e.target.value)}
                                />
                            }
                            {
                                (inputs.toggleDocument ? (fiscalDocument) : inputs.invoiceNumber) &&
                                <TextField
                                    size="small"
                                    label={FIELDS.invoiceNumber.name ?? ""}
                                    style={inputStyle}
                                    variant="outlined"
                                    InputProps={{
                                        inputComponent: InvoiceInput as any,
                                    }}
                                    required
                                    value={header[FIELDS.invoiceNumber.key] ? header[FIELDS.invoiceNumber.key] : ""}
                                    onChange={(e) => handleChange(FIELDS.invoiceNumber.key, e.target.value)}
                                />
                            }
                            {
                                inputs.purchaseSettlement &&
                                <TextField
                                    size="small"
                                    label={FIELDS.purchaseSettlement.name}
                                    style={inputStyle}
                                    variant="outlined"
                                    InputProps={{
                                        inputComponent: InvoiceInput as any,
                                    }}
                                    required
                                    value={header[FIELDS.purchaseSettlement.key] ? header[FIELDS.purchaseSettlement.key] : ""}
                                    onChange={(e) => handleChange(FIELDS.purchaseSettlement.key, e.target.value)}
                                />
                            }
                            {
                                (!fiscalDocument && inputs.toggleDocument) &&
                                <TextField
                                    size="small"
                                    label={FIELDS.documentNumber.name}
                                    style={inputStyle}
                                    variant="outlined"
                                    InputProps={{
                                        inputComponent: InvoiceInput as any,
                                    }}
                                    required
                                    value={header[FIELDS.documentNumber.key] ? header[FIELDS.documentNumber.key] : ""}
                                    onChange={(e) => handleChange(FIELDS.documentNumber.key, e.target.value)}
                                />
                            }
                            {
                                inputs.documentNumber &&
                                <TextField
                                    size="small"
                                    label={FIELDS.documentNumber.name}
                                    style={inputStyle}
                                    variant="outlined"
                                    required
                                    value={header[FIELDS.documentNumber.key] ? header[FIELDS.documentNumber.key] : ""}
                                    onChange={(e) => handleChange(FIELDS.documentNumber.key, e.target.value)}
                                />
                            }
                            {
                                inputs.trackingNumber &&
                                <TextField
                                    size="small"
                                    label={FIELDS.trackingNumber.name}
                                    style={inputStyle}
                                    inputProps={{
                                        style: {
                                            textAlign: 'right'
                                        }
                                    }}
                                    InputProps={{
                                        inputComponent: BLInput as any
                                    }}
                                    variant="outlined"
                                    required
                                    value={header[FIELDS.trackingNumber.key] ? header[FIELDS.trackingNumber.key] : ""}
                                    onChange={(e) => handleChange(FIELDS.trackingNumber.key, e.target.value)}
                                />
                            }
                            {
                                inputs.blNumber &&
                                <TextField
                                    size="small"
                                    label={FIELDS.billOfLadingNumber.name}
                                    style={inputStyle}
                                    variant="outlined"
                                    InputProps={{
                                        inputComponent: BLInput as any,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {"_BL"}
                                            </InputAdornment>
                                        )
                                    }}
                                    required
                                    value={header[FIELDS.billOfLadingNumber.key] ? header[FIELDS.billOfLadingNumber.key] : ""}
                                    onChange={(e) => handleChange(FIELDS.billOfLadingNumber.key, e.target.value)}
                                />
                            }
                            {
                                inputs.shipment &&
                                <TextField
                                    size="small"
                                    label={FIELDS.shipment.name}
                                    style={inputStyle}
                                    variant="outlined"
                                    inputProps={{
                                        style: {
                                            textTransform: "uppercase"
                                        }
                                    }}
                                    value={header[FIELDS.shipment.key] ? header[FIELDS.shipment.key] : ""}
                                    onChange={(e) => handleChange(FIELDS.shipment.key, e.target.value)}
                                />
                            }
                            {
                                inputs.consolidated &&
                                <TextField
                                    size="small"
                                    label={FIELDS.consolidated.name}
                                    style={inputStyle}
                                    variant="outlined"
                                    value={header[FIELDS.consolidated.key] ? header[FIELDS.consolidated.key] : ""}
                                    onChange={(e) => handleChange(FIELDS.consolidated.key, e.target.value)}
                                />
                            }
                            {
                                inputs.authorizationNumber &&
                                <TextField
                                    size="small"
                                    label={FIELDS.authorizationNumber.name}
                                    disabled={supplierCallback}
                                    style={inputStyle}
                                    variant="outlined"
                                    required
                                    value={header[FIELDS.authorizationNumber.key] ? header[FIELDS.authorizationNumber.key] : ""}
                                    onChange={(e) => handleChange(FIELDS.authorizationNumber.key, e.target.value)}
                                />
                            }
                            {
                                inputs.operationalReference &&
                                <TextField
                                    size="small"
                                    label={FIELDS.operationalReference.name}
                                    disabled={supplierCallback}
                                    style={inputStyle}
                                    variant="outlined"
                                    value={accountInfo?.operationalReference ?? ""}
                                    onChange={(e) => handleChangeAccountInfo({ ...accountInfo, operationalReference: e.target.value })}
                                />
                            }
                            {
                                inputs.purchaseOrder &&
                                <Autocomplete
                                    style={inputStyle}
                                    loading={loading}
                                    loadingText="Cargando..."
                                    options={purchaseOrders}
                                    getOptionLabel={option => (option?.serialNumber ?? "")}
                                    size="small"
                                    freeSolo
                                    openOnFocus
                                    inputValue={accountInfo?.purchaseOrder?.serialNumber ?? ""}
                                    noOptionsText="No existen ordenes de compra registradas..."
                                    value={purchaseOrders.find(p => p.serialNumber === accountInfo?.purchaseOrder?.serialNumber) ?? null}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label={FIELDS.purchaseOrder.name}
                                            required={requiredPo}
                                            onChange={(e) => {
                                                handleChangeAccountInfo({ ...accountInfo, purchaseOrder: { serialNumber: e.target.value } })
                                            }}
                                        />
                                    )}
                                    onChange={(_, selectedValue) => {
                                        if (selectedValue && typeof selectedValue !== "string") {
                                            handleChangeAccountInfo({
                                                ...accountInfo,
                                                purchaseOrder: selectedValue
                                            })
                                        }
                                    }}
                                />
                            }
                            {
                                inputs.file && type !== "employee-reimbursement" && type !== "cash-reimbursement" &&
                                <React.Fragment>
                                    <div style={inputStyle} className="flex flex-row items-center">
                                        <label htmlFor="approval-button-file" className="w-full">
                                            <Button
                                                style={{ height: 40, textAlign: 'center' }}
                                                variant="outlined"
                                                disableElevation
                                                fullWidth
                                                size="small"
                                                component="span"
                                                disabled={Boolean(header.file?.file)}
                                            >
                                                <Divider orientation="vertical" />
                                                {header.file ? "" : "Subir archivos"}
                                                {!header.file &&
                                                    <Icon fontSize="small" color={Boolean(header.file) ? "disabled" : "action"} className="mx-2">publish</Icon>
                                                }
                                            </Button>
                                        </label>
                                        <IconButton type='button' onClick={handleClickOpen} style={{ marginLeft: 8, backgroundColor: '#e0e0e0', borderRadius: '10px', padding: '10px' }}>
                                            <Icon fontSize="small" color="action" style={{ padding: '0px' }}>
                                                visibility
                                            </Icon>
                                        </IconButton>
                                    </div>
                                    <input
                                        accept="application/pdf"
                                        className="hidden"
                                        id="approval-button-file"
                                        type="file"
                                        multiple={true}
                                        onChange={(e) => {
                                            if (e.target.files !== null && e.target.files.length > 0) {
                                                const archivosArray = Array.from(e.target.files);
                                                const archivoExcedeTamano = archivosArray.some((archivo) => archivo.size > MAX_FILE_SIZE);
                                                if (archivoExcedeTamano) {
                                                    setMessageConfig({
                                                        message: "El tamaño de uno o más archivos excede el máximo permitido",
                                                        open: true,
                                                        severity: "error",
                                                    });
                                                    return;
                                                }
                                                const archivosActuales = header[FIELDS.files.key] || [];
                                                const nuevosArchivos = [...archivosActuales, ...archivosArray];
                                                console.log(nuevosArchivos,'NUEVOS')
                                                handleChange('files', nuevosArchivos);
                                            }
                                        }}
                                    />
                                </React.Fragment>
                             }
                            {
                                isReimbursement() &&
                                <KeyboardDatePicker
                                    style={inputStyle}
                                    className="date-input"
                                    autoOk
                                    invalidDateMessage={FIELDS.invalidDate.name}
                                    InputAdornmentProps={{ position: "start", style: { margin: 0 } }}
                                    value={header[FIELDS.emissionDate.key] ?? null}
                                    onChange={(e) => handleChange(FIELDS.emissionDate.key, e)}
                                    inputVariant="outlined"
                                    fullWidth
                                    emptyLabel={FIELDS.emptyDate.name}
                                    required
                                    size="small"
                                    label={FIELDS.emissionDate.name}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    maxDate={new Date()}
                                />
                            }
                            {
                                (requireAccountInfo && isReimbursement()) &&
                                <React.Fragment>
                                    <Button
                                        style={{ ...inputStyle, fontSize: "0.9em", justifyContent: "flex-start", textTransform: "none", height: 40 }}
                                        size="small"
                                        variant="outlined"
                                        disableElevation
                                        onClick={() => setOpenAccountingDialog(true)}
                                    >
                                        <Icon fontSize="small" color="action" className="ml-1 mr-2">info</Icon>
                                        {"Contabilización"}
                                    </Button>
                                    <AccountingDialog
                                        open={openAccountingDialog}
                                        infoOnly
                                        accountInfo={accountInfo}
                                        onClose={() => setOpenAccountingDialog(false)}
                                        onConfirm={(accInfo) => handleChangeAccountInfo(accInfo)}
                                        defaultOffice={type === "foreign-expenses-liquidation" ? "02" : "01"}
                                        defaultTaxSupport={"01"}
                                    />
                                </React.Fragment>
                            }
                        </div>
                        {
                            isReimbursement() && false &&
                            <React.Fragment>
                                <div className="mx-4">
                                    <Divider orientation="vertical" />
                                </div>
                                <div className="flex flex-wrap justify-between" style={{ maxWidth: "40%", height: "min-content", width: "40%" }}>

                                    <div className="w-full">
                                        <Typography
                                            style={{ fontSize: "0.8em", fontWeight: 800, width: "100%" }}
                                            align="right"
                                        >
                                            {"Sustentos tributarios"}
                                        </Typography>
                                        <div className="w-full flex justify-between py-3">
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    {FIELDS.subtotalNoTaxesObject.name}
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                >
                                                    {formatCurrency(header.reimbursementSummary?.taxSupport.subtotalNoTaxesObject)}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    {FIELDS.subtotalRateZero.name}
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                >
                                                    {formatCurrency(header.reimbursementSummary?.taxSupport.subtotalRateZero)}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    {FIELDS.subtotalRateTwelve.name}
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                >
                                                    {formatCurrency(header.reimbursementSummary?.taxSupport.subtotalRateTwelve)}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    {FIELDS.iva.name}
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                >
                                                    {formatCurrency(header.reimbursementSummary?.taxSupport.iva)}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    {FIELDS.total.name}
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                >
                                                    {formatCurrency(header.reimbursementSummary?.taxSupport.total)}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <Typography
                                            style={{ fontSize: "0.8em", fontWeight: 800, width: "100%" }}
                                            align="right"
                                        >
                                            {"Sustentos no tributarios"}
                                        </Typography>
                                        <div className="w-full flex justify-between py-3">
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    {FIELDS.subtotalNoTaxesObject.name}
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                >
                                                    {formatCurrency(header.reimbursementSummary?.nonTaxesSupport.subtotalNoTaxesObject)}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    {FIELDS.subtotalRateZero.name}
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                >
                                                    {formatCurrency(header.reimbursementSummary?.nonTaxesSupport.subtotalRateZero)}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    {FIELDS.subtotalRateTwelve.name}
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                >
                                                    {formatCurrency(header.reimbursementSummary?.nonTaxesSupport.subtotalRateTwelve)}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    {FIELDS.iva.name}
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                >
                                                    {formatCurrency(header.reimbursementSummary?.nonTaxesSupport.iva)}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    {FIELDS.total.name}
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    style={{ fontSize: "0.75em", fontWeight: 800, width: "100%" }}
                                                >
                                                    {formatCurrency(header.reimbursementSummary?.nonTaxesSupport.total)}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        {
                            !isReimbursement() &&
                            <div className="mx-4">
                                <Divider orientation="vertical" />
                            </div>
                        }
                        <div className="flex flex-wrap justify-between" style={{ maxWidth: "32%", height: "min-content", width: "32%" }}>
                            {
                                (inputs.emissionDate && !isReimbursement()) &&
                                <KeyboardDatePicker
                                    style={isReimbursement() ? { ...inputStyle, width: "100%" } : inputStyle}
                                    className="date-input"
                                    autoOk
                                    invalidDateMessage={FIELDS.invalidDate.name}
                                    InputAdornmentProps={{ position: "start", style: { margin: 0 } }}
                                    value={header[FIELDS.emissionDate.key] ?? null}
                                    onChange={(e) => handleChange(FIELDS.emissionDate.key, e)}
                                    inputVariant="outlined"
                                    fullWidth
                                    emptyLabel={FIELDS.emptyDate.name}
                                    required
                                    size="small"
                                    label={FIELDS.emissionDate.name}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    maxDateMessage="La fecha máxima no puede ser posterior al día de hoy"
                                    onBlur={() => {
                                        if (!header[FIELDS.emissionDate.key] || isNaN(new Date(header[FIELDS.emissionDate.key]).getTime())) {
                                            handleChange(FIELDS.emissionDate.key, new Date())
                                            return
                                        }
                                        if (!isNaN(new Date(header[FIELDS.emissionDate.key]).getTime()) && new Date(header[FIELDS.emissionDate.key]).getTime() > new Date().getTime()) {
                                            handleChange(FIELDS.emissionDate.key, new Date())
                                            return
                                        }
                                    }}
                                />
                            }
                            {
                                inputs.purchaseStatus &&
                                <TextField
                                    size="small"
                                    label={FIELDS.purchaseStatus.name}
                                    style={inputStylePurchase}
                                    variant="outlined"
                                    required
                                    value={header[FIELDS.purchaseStatus.key] ? header[FIELDS.purchaseStatus.key] : ""}
                                    onChange={(e) => handleChange(FIELDS.purchaseStatus.key, e.target.value)}
                                />
                            }
                            {
                                inputs.receptionDate &&
                                <KeyboardDatePicker
                                    className="date-input"
                                    style={inputStyle}
                                    autoOk
                                    InputAdornmentProps={{ position: "start", style: { margin: 0 } }}
                                    invalidDateMessage={FIELDS.invalidDate.name}
                                    value={header[FIELDS.receptionDate.key] ?? null}
                                    emptyLabel={FIELDS.emptyDate.name}
                                    onChange={(e) => handleChange(FIELDS.receptionDate.key, e)}
                                    inputVariant="outlined"
                                    fullWidth
                                    size="small"
                                    label={FIELDS.receptionDate.name}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                />
                            }
                            {
                                inputs.createDate &&
                                <KeyboardDatePicker
                                    className="date-input"
                                    required
                                    autoOk
                                    style={inputStylePurchase}
                                    invalidDateMessage={FIELDS.invalidDate.name}
                                    InputAdornmentProps={{ position: "start", style: { margin: 0 } }}
                                    value={header[FIELDS.createDate.key] ?? null}
                                    emptyLabel={FIELDS.emptyDate.name}
                                    onChange={(e) => handleChange(FIELDS.createDate.key, e)}
                                    inputVariant="outlined"
                                    fullWidth
                                    size="small"
                                    label={FIELDS.createDate.name}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                />
                            }
                            {
                                inputs.expiresDate &&
                                <KeyboardDatePicker
                                    className="date-input"
                                    autoOk
                                    disabled={supplierCallback}
                                    invalidDateMessage={FIELDS.invalidDate.name}
                                    InputAdornmentProps={{ position: "start", style: { margin: 0 } }}
                                    style={inputStyle}
                                    value={header[FIELDS.expiresDate.key] ?? null}
                                    emptyLabel={FIELDS.emptyDate.name}
                                    onChange={(e) => handleChange(FIELDS.expiresDate.key, e)}
                                    inputVariant="outlined"
                                    fullWidth
                                    size="small"
                                    label={FIELDS.expiresDate.name}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                />
                            }
                            {
                                inputs.invoiceDate &&
                                <KeyboardDatePicker
                                    className="date-input"
                                    required
                                    autoOk
                                    style={inputStyle}
                                    invalidDateMessage={FIELDS.invalidDate.name}
                                    InputAdornmentProps={{ position: "start", style: { margin: 0 } }}
                                    value={header[FIELDS.invoiceDate.key] ?? null}
                                    emptyLabel={FIELDS.emptyDate.name}
                                    onChange={(e) => handleChange(FIELDS.invoiceDate.key, e)}
                                    inputVariant="outlined"
                                    fullWidth
                                    size="small"
                                    label={FIELDS.invoiceDate.name}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                />
                            }
                            {
                                (requireAccountInfo && !isReimbursement()) &&
                                <React.Fragment>
                                    <Button
                                        style={{ ...inputStyle, fontSize: "0.9em", justifyContent: "flex-start", textTransform: "none", height: 40 }}
                                        size="small"
                                        variant="outlined"
                                        disableElevation
                                        onClick={() => setOpenAccountingDialog(true)}
                                    >
                                        <Icon fontSize="small" color="action" className="ml-1 mr-2">info</Icon>
                                        {"Contabilización"}
                                    </Button>
                                    <AccountingDialog
                                        open={openAccountingDialog}
                                        infoOnly
                                        accountInfo={accountInfo}
                                        onClose={() => setOpenAccountingDialog(false)}
                                        onConfirm={(accInfo) => handleChangeAccountInfo(accInfo)}
                                        defaultOffice={type === "foreign-expenses-liquidation" ? "02" : "01"}
                                        defaultTaxSupport={"01"}
                                    />
                                </React.Fragment>
                            }
                            {
                                inputs.advance &&
                                <TextField
                                    size="small"
                                    label={FIELDS.advance.name}
                                    style={{ ...inputStyle, marginLeft: 26 }}
                                    variant="outlined"
                                    onFocus={event => { event.target.select() }}
                                    value={header[FIELDS.advance.key] ? header[FIELDS.advance.key] : ""}
                                    onChange={(e) => handleChange(FIELDS.advance.key, e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon fontSize="small" color="action">attach_money</Icon>
                                            </InputAdornment>
                                        ),
                                        inputComponent: CurrencyInput as any,
                                    }}
                                />
                            }
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
            >
                <DialogTitle style={{ fontSize: '9px' }}>Archivos Cargados</DialogTitle>
                <DialogContent>
                    {header[FIELDS.files.key] && header[FIELDS.files.key].length > 0 ?
                        <DialogContentText style={{ paddingBottom: '0px' }}>
                            <List style={{ paddingBottom: '0px' }}>
                                {header[FIELDS.files.key].map((archivo, index) => (
                                    <div key={index}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <Tooltip arrow title="Eliminar archivo">
                                                        <IconButton onClick={() => eliminarArchivo(index)}>
                                                            <Icon fontSize="small" color="default" style={{ color: 'white' }}>
                                                                delete_forever
                                                            </Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={archivo.name}
                                                secondary="Archivo PDF"
                                                primaryTypographyProps={{ style: { color: 'black' } }} // Color negro para el nombre del archivo
                                                secondaryTypographyProps={{ style: { color: 'gray', fontSize: '10px' } }} // Color gris para la fecha
                                            />
                                        </ListItem>
                                        {index < header[FIELDS.files.key].length - 1 && <Divider variant="middle" />}
                                    </div>
                                ))}
                            </List>
                        </DialogContentText>
                        :
                        <DialogContentText style={{ paddingBottom: '0px', textAlign: 'center' }}>
                            No se han cargado archivos.
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions style={{ paddingTop: '0px', paddingRight: '20px', paddingBottom: '20px' }}>
                    <Button variant='contained' onClick={handleClose}>ACEPTAR</Button>
                </DialogActions>
            </Dialog> 
        </React.Fragment>
    )
}

DocumentHeader.defaultProps = {
    inputs: {
        invoiceNumber: true,
        supplier: false,
        authorizationNumber: false,
        expiresDate: false,
        emissionDate: true,
        receptionDate: true,
        tip: false,
        employeeOrCustodian: false,
        documentNumber: false
    }
}

export default DocumentHeader
