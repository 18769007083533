import { MenuItem, TextField } from '@material-ui/core'
import { FIELDS } from 'lib'
import React from 'react'
import { IAccountType, ISupplier } from 'types'

interface Props {
    handleChange: (key: string, value: any) => void
    supplier: ISupplier
    accountTypes: IAccountType[]
}

const inputStyle: React.CSSProperties = { width: "49%", marginBottom: 15 }

const SupplierTaxesForm = (props: Props) => {
    const { handleChange, supplier, accountTypes } = props
    return (
        <div className="w-full h-auto flex flex-wrap justify-between items-start">
            <TextField
                value={supplier[FIELDS.supplierGroup.key]}
                label={FIELDS.supplierGroup.name}
                onChange={(e) => handleChange(FIELDS.supplierGroup.key, e.target.value)}
                style={inputStyle}
                variant="outlined"
                size="small"
                select
            >
                {accountTypes.map((accountTypeT) => {
                    return <MenuItem key={accountTypeT.accountTypeId} value={accountTypeT.description}>{accountTypeT.description}</MenuItem>
                })}
            </TextField>
            <TextField
                size="small"
                label={FIELDS.ivaType.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.ivaType.key]}
                onChange={(e) => handleChange(FIELDS.ivaType.key, e.target.value)}
            />
            <TextField
                size="small"
                label={FIELDS.purchaseLiquidation.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.purchaseLiquidation.key]}
                onChange={(e) => handleChange(FIELDS.purchaseLiquidation.key, e.target.value)}
            />
            <TextField
                size="small"
                label={FIELDS.serviceType.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.serviceType.key]}
                onChange={(e) => handleChange(FIELDS.serviceType.key, e.target.value)}
            />
            <TextField
                size="small"
                label={FIELDS.supplierType.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.supplierType.key]}
                onChange={(e) => handleChange(FIELDS.supplierType.key, e.target.value)}
            />
            <TextField
                size="small"
                label={FIELDS.taxpayerType.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.taxpayerType.key]}
                onChange={(e) => handleChange(FIELDS.taxpayerType.key, e.target.value)}
            />
            <TextField
                size="small"
                label={FIELDS.sourceTaxRetention.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.sourceTaxRetention.key]}
                onChange={(e) => handleChange(FIELDS.sourceTaxRetention.key, e.target.value)}
            />
            <TextField
                size="small"
                label={FIELDS.ivaTaxRetention.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.ivaTaxRetention.key]}
                onChange={(e) => handleChange(FIELDS.ivaTaxRetention.key, e.target.value)}
            />
            <TextField
                size="small"
                label={FIELDS.sourceTaxRetentionJba.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.sourceTaxRetentionJba.key]}
                onChange={(e) => handleChange(FIELDS.sourceTaxRetentionJba.key, e.target.value)}
            />
            <TextField
                size="small"
                label={FIELDS.ivaTaxRetentionJba.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.ivaTaxRetentionJba.key]}
                onChange={(e) => handleChange(FIELDS.ivaTaxRetentionJba.key, e.target.value)}
            />
            <TextField
                size="small"
                label={FIELDS.supplierJba.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.supplierJba.key]}
                onChange={(e) => handleChange(FIELDS.supplierJba.key, e.target.value)}
            />
            <TextField
                size="small"
                label={FIELDS.legalRepresentative.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.legalRepresentative.key]}
                onChange={(e) => handleChange(FIELDS.legalRepresentative.key, e.target.value)}
            />
        </div>
    )
}

export default SupplierTaxesForm