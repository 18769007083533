import { Button, CircularProgress, Divider, Typography, Icon, IconButton, Tabs, Tab, Snackbar, } from '@material-ui/core'
import { useValues } from 'hooks'
import {
    createSupplierService, editSupplierService, EDIT_PATH, FIELDS, getSupplierFileByIdService, getSupplierFilesWithTag, getSuppliersFileService, SUPPLIER_TABS, validateSupplierFields
} from 'lib'
import React from 'react'
import { useLocation, useHistory } from 'react-router'
import { IMessageConfig, ISupplier, ISupplierFile, SupplierFileMap, SupplierUploadedFileMap } from 'types'
import { AuthContext, FileContext, ManagementContext } from 'context'
import { grey } from '@material-ui/core/colors'
import { SupplierBankForm, SupplierFilesForm, SupplierGeneralForm, SupplierPaymentForm, SupplierSRIForm, SupplierTaxesForm } from 'components'
import { Alert } from '@material-ui/lab'


interface Props {
    onSuccess: () => void
    onError: () => void
    onClose: () => void
}

interface TabPanelProps {
    children?: React.ReactNode
    index: any
    value: any
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
            className={value === index ? "w-full px-8" : "hidden"}
        >
            {value === index && (
                <div className="w-full h-full">
                    {children}
                </div>
            )}
        </div>
    )
}

const SupplierForm = (props: Props) => {
    const { uploadSupplierFilesByTag } = React.useContext(FileContext)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const [rucFile, setRucFile] = React.useState<ISupplierFile>({} as ISupplierFile)
    const [appointmentFile, setAppointmentFile] = React.useState<ISupplierFile>({} as ISupplierFile)
    const [bankCertificateFile, setBankCertificateFile] = React.useState<ISupplierFile>({} as ISupplierFile)
    const [supplierUploadedFileMap, setSupplierUploadedFileMap] = React.useState<SupplierUploadedFileMap>({
        "account-code": [],
        "basic-documents": [],
        "contract": [],
        "due-diligence": []
    })
    const [supplierFileMap, setSupplierFileMap] = React.useState<SupplierFileMap>({
        "account-code": [],
        "basic-documents": [],
        "contract": [],
        "due-diligence": []
    })
    const location = useLocation()
    const { onError, onSuccess, onClose } = props
    const history = useHistory()
    const { accountTypes, identificationTypes } = React.useContext(ManagementContext)
    const { user } = React.useContext(AuthContext)
    const initialInput = React.useRef<any>(null)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [value, setValue] = React.useState<number>(0)
    const { values: supplier, updateValue: handleChange, updateValues } = useValues()
    const handleSumbit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const validationResult = validateSupplierFields({ ...supplier })
        if (!validationResult.valid) {
            setValue(validationResult.tab ?? value)
            setMessageConfig({ message: "Debe llenar los campos requeridos (*)", open: true, severity: "error" })
            return
        }
        try {
            setLoading(true)
            if (!(location.state as any)?.edit) {
                const createdSupplier: ISupplier = await createSupplierService({ 
                    ...supplier, 
                    active: true, 
                    createdBy: user?.userId, 
                    comercialName: supplier.companyName,
                    requirePo: Boolean(supplier.requirePo), 
                    relatedPart: Boolean(supplier.relatedPart), 
                    updateTaxBase: Boolean(supplier.updateTaxBase), 
                    updateTotal: Boolean(supplier.updateTotal), 
                    contacts: JSON.stringify(supplier.contacts),
                    fileMetaData: JSON.stringify(supplier.fileMetaData)
                })
                const supplierFiles = getSupplierFilesWithTag(supplierFileMap, supplierUploadedFileMap)
                uploadSupplierFilesByTag(supplierFiles, createdSupplier.supplierId)
            } else {
                const edited = { ...supplier }
                delete edited[FIELDS.supplierId.key]
                await editSupplierService(supplier[FIELDS.supplierId.key], { 
                    ...edited, 
                    comercialName: supplier.companyName,
                    contacts: JSON.stringify(edited.contacts),
                    fileMetaData: JSON.stringify(edited.fileMetaData)
                })
                const supplierFiles = getSupplierFilesWithTag(supplierFileMap, supplierUploadedFileMap)
                uploadSupplierFilesByTag(supplierFiles, supplier.supplierId)
                onClose()
            }
            setLoading(false)
            if (!(location.state as any)?.edit) {
                updateValues({
                    companyName: "", identification: "", identificationType: "", address: "",
                    comercialName: "", keepAccounting: "", specialTaxpayer: "", active: true,
                    activity: "", phoneNumber: "", city: "", paymentTerms: "", email: "",
                    contact: "", createdBy: "", legalRepresentative: "",
                    accountExecutiveName: "", accountExecutiveRole: "", accountExecutiveEmail: "",
                    accountExecutiveSupervisor: "", supervisorRole: "", supervisorEmail: "",
                    documentsEmail: "", bankName: "", bankAccountType: "",
                    bankAccountNumber: "", swiftCode: "", codeOfConductAgreement: "",
                    antiCorruptionAgreement: "", dueDilligenceAgreement: "", supplierGroup: "",
                    ivaType: "", purchaseLiquidation: "", serviceType: "",
                    supplierType: "", taxpayerType: "", sourceTaxRetention: "",
                    ivaTaxRetention: "", sourceTaxRetentionJba: "",
                    ivaTaxRetentionJba: "", supplierJba: "", supplierModule: "",
                } as ISupplier)
                initialInput.current?.focus()
            }
            onSuccess()
        } catch (error) {
            setLoading(false)
            onError()
        }
    }
    const handleGetSupplierFiles = async () => {
        const files: ISupplierFile[] = await getSuppliersFileService(supplier.supplierId)
        for (let i = 0; i < files.length; i++) {
            const file = await getSupplierFileByIdService(files[i].supplierFileId ?? -1)
            files[i] = { ...files[i], file: { ...file, name: files[i].fileName } }
        }
        const map:SupplierUploadedFileMap = {
            "account-code": [],
            "basic-documents": [],
            "contract": [],
            "due-diligence": []
        }
        for (const supplierFile of files) {
            if (supplierFile.tag?.includes("account-code")) {
                map["account-code"].push(supplierFile)
            }
            if (supplierFile.tag?.includes("basic-documents")) {
                map["basic-documents"].push(supplierFile)
            }  
            if (supplierFile.tag?.includes("contract")) {
                map["contract"].push(supplierFile)
            }  
            if (supplierFile.tag?.includes("due-diligence")) {
                map["due-diligence"].push(supplierFile)
            }  
        }
        setSupplierUploadedFileMap(map)
    }
    React.useEffect(() => {
        if ((location.state as any)?.edit) {
            if ((location.state as any)?.values) {
                updateValues({
                    ...(location.state as any)?.values,
                    // se usa JSON parse porque esta guardado como string
                    contacts: JSON.parse((location.state as any)?.values?.contacts ?? "{}"),
                    fileMetaData: JSON.parse((location.state as any)?.values?.fileMetaData ?? "{}"),
                })
            }
            if ((location.state as any)?.files) {
                const supplierFiles: ISupplierFile[] = (location.state as any)?.files
                if (supplierFiles.length > 0) {
                    const map:SupplierUploadedFileMap = {
                        "account-code": [],
                        "basic-documents": [],
                        "contract": [],
                        "due-diligence": []
                    }
                    for (const supplierFile of supplierFiles) {
                        if (supplierFile.tag?.includes("account-code")) {
                            map["account-code"].push(supplierFile)
                        }
                        if (supplierFile.tag?.includes("basic-documents")) {
                            map["basic-documents"].push(supplierFile)
                        }  
                        if (supplierFile.tag?.includes("contract")) {
                            map["contract"].push(supplierFile)
                        }  
                        if (supplierFile.tag?.includes("due-diligence")) {
                            map["due-diligence"].push(supplierFile)
                        }  
                    }
                    setSupplierUploadedFileMap(map)
                }
            }
        } else {
            history.replace(location.pathname.replace(EDIT_PATH, ""))
        }
    }, [location.state, updateValues, history, location.pathname])
    return (
        <form className="flex w-full h-full" onSubmit={handleSumbit}>
            <div className="flex flex-col" style={{ width: 180, maxWidth: 180, backgroundColor: grey[50], height: 480, maxHeight: 480 }}>
                <div className="flex flex-grow items-center">
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={(_: any, newValue: number) => setValue(newValue)}
                        className="w-full"
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        {SUPPLIER_TABS.map(tab => (<Tab label={tab.label} />))}
                    </Tabs>
                </div>
            </div>
            <div className="flex flex-grow flex-col">
                <div className="flex py-5 items-center w-full justify-end pr-8">
                    <div className="flex items-center flex-grow justify-start pl-8">
                        <Typography className="pr-4 pl-2" variant="body2" style={{ fontWeight: 600, letterSpacing: 0.5 }}>
                            {((location.state as any)?.edit && (location.state as any)?.values) ? "Editar Proveedor" : "Nuevo Proveedor"}
                        </Typography>
                    </div>
                    <IconButton size="small" onClick={onClose}>
                        <Icon fontSize="small">close</Icon>
                    </IconButton>
                </div>
                <div style={{ maxHeight: 320 }} className="flex flex-grow overflow-y-auto pt-2">
                    <TabPanel value={value} index={0}>
                        <SupplierGeneralForm
                            supplier={supplier}
                            accountTypes={accountTypes}
                            handleChange={handleChange}
                            identificationTypes={identificationTypes}
                            edit={Boolean((location.state as any)?.edit) && Boolean((location.state as any)?.values)}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <SupplierSRIForm
                            handleChange={handleChange}
                            supplier={supplier}
                            accountTypes={accountTypes}
                            rucFile={rucFile}
                            setRucFile={setRucFile}
                            appointmentFile={appointmentFile}
                            setAppointmentFile={setAppointmentFile}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <SupplierPaymentForm
                            handleChange={handleChange}
                            supplier={supplier}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <SupplierBankForm
                            handleChange={handleChange}
                            supplier={supplier}
                            bankCertificateFile={bankCertificateFile}
                            setBankCertificateFile={setBankCertificateFile}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <SupplierTaxesForm
                            handleChange={handleChange}
                            supplier={supplier}
                            accountTypes={accountTypes}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <SupplierFilesForm
                            supplier={supplier}
                            handleChange={handleChange}
                            supplierFileMap={supplierFileMap}
                            setSupplierFileMap={setSupplierFileMap}
                            supplierUploadedFileMap={supplierUploadedFileMap}
                            onSuccessfulDelete={handleGetSupplierFiles}
                            edit={Boolean((location.state as any)?.edit) && Boolean((location.state as any)?.values)}
                        />
                    </TabPanel>
                </div>
                <div>
                    <div className="mt-4">
                        <Divider />
                    </div>
                    <div className="flex justify-end items-center pt-4 px-8 h-full">
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            disableElevation
                            disabled={loading}
                            type="submit"
                        >
                            {loading ? <CircularProgress style={{ color: "white" }} size={16} className="my-1" thickness={10} /> : (location.state as any)?.edit ? "Guardar" : "Registrar"}
                        </Button>
                    </div>
                </div>
            </div>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </form>
    )
}
export default SupplierForm
