import { ROOT_PATH, ERROR_PATH, FORBIDDEN_PATH } from 'lib'
import { Login, Forbidden } from 'pages'
import ManualRequest from 'pages/MyDocs/ManualRequest'
import UploadXMLPage from 'pages/UploadXMLs/UploadXMLPage'
import React from 'react'
import { Route } from 'react-router-dom'

const Public = () => {
    return (
        <React.Fragment>
            <Route exact path={`${ROOT_PATH}`} component={Login} />
            <Route exact path={'/manualrequest'} component={ManualRequest} />
            <Route exact path={'/upload/xmls'} component={UploadXMLPage} />
            <Route exact path={`${ERROR_PATH + FORBIDDEN_PATH}`} component={Forbidden} />
        </React.Fragment>
    )
}

export default Public
