import React from "react"
import { Route } from "react-router-dom"
import { AuthContext } from "context"
import { DOCUMENT_PATH, REJECTED_PATH, REPORT_PATH, RETENTION_PATH, SEND_PATH, SUPREPORT_PATH } from "lib"
import { SendDocumentReports, SendRejectedReports, SendRetentionReports, SendSupplierReport } from "pages"

const Reports = () => {
    const { user } = React.useContext(AuthContext)
    return (
        <React.Fragment>
            {
                Boolean(user) &&
                <React.Fragment>
                    <Route strict path={`${REPORT_PATH}${SEND_PATH}${DOCUMENT_PATH}`} component={SendDocumentReports} />
                    <Route strict path={`${REPORT_PATH}${SEND_PATH}${REJECTED_PATH}`} component={SendRejectedReports} />
                    <Route strict path={`${REPORT_PATH}${SEND_PATH}${RETENTION_PATH}`} component={SendRetentionReports} />
                    <Route strict path={`${REPORT_PATH}${SEND_PATH}${SUPREPORT_PATH}`} component={SendSupplierReport} />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Reports
