import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logodhl from '../../assets/img/logo-bg-transparent.svg'
import errorlogo from '../../assets/img/ooppss.png'
import { CircularProgress, TextField, Typography } from '@material-ui/core';
import { IAprobarORechazar } from 'model/EmialAprobarORechazar';
import { wsAprobarORechazarPorMail } from 'api/APIDraft';
import { Alert, Autocomplete } from '@material-ui/lab';
import { IRejectionCause } from 'types';
import { getRejectionCauseService } from 'lib';

function ManualRequest() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const docid = searchParams.get('code');
  const aprobadorid = searchParams.get('key');
  const accion = searchParams.get('act');

  const [rechazoCausa, setRechazoCausa] = useState('');
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState(false);
  const [sincausa, setsincausa] = useState(false);
  const [exitorechazo, setexitorechazo] = useState(false);

  React.useEffect(() => {
    refreshRejectionCauses()
    if (accion == '1') {
      aprobarDirectamente()
    } else {
      setloading(false)
    }
  }, [])

  const aprobarDirectamente = async () => {
    try {
      const objeto: IAprobarORechazar = {
        documento: docid!,
        aprobador: aprobadorid!,
        causa: '',
        accion: 1
      }
      await wsAprobarORechazarPorMail(objeto)
      setloading(false)
    } catch (error) {
      setloading(false)
      seterror(true)
    }
  }

  const [rejectionCauses, setRejectionCauses] = React.useState<IRejectionCause[]>([])
  const [rejectionCause, setRejectionCause] = React.useState<IRejectionCause | null | string>(null)
  const refreshRejectionCauses = React.useCallback(async () => {
    try {
      setRejectionCauses(await getRejectionCauseService())
    } catch (error) {
      throw new Error()
    }
  }, [])
  const valueOfRejection = () => {
    const isstring = typeof rejectionCause === 'string'
    if (rejectionCause === null) {
      return ''
    }
    if (!isstring) {
      return (rejectionCause as IRejectionCause).name
    }

    return (rejectionCause as string)
  }

  const handleRechazar = async () => {
    if (rejectionCause !== null) {
      let texto:string = ''
      if(typeof rejectionCause === 'string'){
        texto = rejectionCause
      }else{
        texto = rejectionCause.name
      }
      try {
        const objeto: IAprobarORechazar = {
          documento: docid!,
          aprobador: aprobadorid!,
          causa: texto,
          accion: 2
        }
        await wsAprobarORechazarPorMail(objeto)
        setsincausa(false)
        setloading(false)
        seterror(false)
        setexitorechazo(true)
      } catch (error) {
        setloading(false)
        setsincausa(false)
        setexitorechazo(false)
        seterror(true)
      }
    } else {
      setsincausa(true)
    }
  };

  return (
    <div style={{ backgroundColor: '#c9c9c9', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

      <div style={{ backgroundColor: 'white', padding: '30px', borderRadius: '10px' }}>
        {loading && (
          <div style={{ textAlign: 'center', padding: '10vh 10vw' }}>
            <CircularProgress />
          </div>
        )}
        {accion === '1' && !loading && !error && (
          <div style={{ textAlign: 'center' }}>
            <img style={{
              width: '40%',
              margin: '0 auto', // Esto centrará la imagen horizontalmente
            }} src={logodhl}></img>
            <p style={{ marginTop: '40px' }}>El documento fue <span style={{ fontWeight: 'bold' }}>APROBADO</span> con éxito.</p>
            {/* <Link to={'https://pruebas.alterbios.com/received'}>
              <button
                type='button'
                style={{
                  backgroundColor: '#f5c800',
                  color: 'black',
                  padding: '8px 20px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  marginTop: '40px'
                }}
              >
                Ir al Inicio
              </button>
            </Link> */}
          </div>
        )}
        {accion === '2' && !loading && !error && exitorechazo && (
          <div style={{ textAlign: 'center' }}>
            <img style={{
              width: '40%',
              margin: '0 auto', // Esto centrará la imagen horizontalmente
            }} src={logodhl}></img>
            <p style={{ marginTop: '40px' }}>El documento fue <span style={{ fontWeight: 'bold' }}>RECHAZADO</span> con éxito.</p>
            {/* <Link to={'https://pruebas.alterbios.com/received'}>
              <button
                type='button'
                style={{
                  backgroundColor: '#f5c800',
                  color: 'black',
                  padding: '8px 20px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  marginTop: '40px'
                }}
              >
                Ir al Inicio
              </button>
            </Link> */}
          </div>
        )}
        {accion === '2' && !loading && !error && !exitorechazo && (
          <div style={{ textAlign: 'center' }}>
            <img style={{
              width: '40%',
              margin: '0 auto', // Esto centrará la imagen horizontalmente
            }} src={logodhl}></img>
            {sincausa &&
              <Alert severity="error" style={{ padding: "0px 16px", marginTop: '40px' }}>
                <Typography className="pl-2" variant="caption">
                  {"La causa de rechazo para el documento es obligatoria."}
                </Typography>
              </Alert>
            }
            <p style={{ marginTop: '40px' }}>Ingrese la causa de rechazo del documento:</p>


            {/* <TextField id="standard-basic" label="Causa" variant="standard" fullWidth style={{ marginTop: '20px' }}
              value={rechazoCausa}
              onChange={(e) => { setRechazoCausa(e.target.value) }} /> */}

            <Autocomplete
              loading={loading}
              loadingText="Cargando..."
              noOptionsText="No se encontraron motivos de rechazo"
              options={rejectionCauses.filter(r => r.active).map(r => r.name)}
              getOptionLabel={(option) => option}
              value={valueOfRejection()}
              openOnFocus
              fullWidth
              freeSolo
              size={"small"}
              onChange={(e, selectedValue, reason) => {
                setRejectionCause(selectedValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  label={"Motivo de rechazo"}
                  autoFocus
                  required
                  onBlur={(event) => {
                    setRejectionCause(event.target.value)

                  }}
                />
              )}
            />


            <button
              type='button'
              onClick={handleRechazar}
              style={{
                backgroundColor: '#D40511',
                color: 'white',
                padding: '8px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
                marginTop: '40px'
              }}
            >
              Rechazar documento
            </button>
          </div>
        )}
        {!loading && error && (
          <div style={{ textAlign: 'center' }}>
            <img style={{
              width: '30%',
              margin: '0 auto', // Esto centrará la imagen horizontalmente
            }} src={errorlogo}></img>
            <p style={{ marginTop: '40px' }}>Ooops !! Ocurrio un error en el proceso, vuelve a intentarlo mas tarde.</p>
            {/* <Link to={'https://pruebas.alterbios.com/received'}>
              <button
                type='button'
                style={{
                  backgroundColor: '#f5c800',
                  color: 'black',
                  padding: '8px 20px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  marginTop: '40px'
                }}
              >
                Ir al Inicio
              </button>
            </Link> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default ManualRequest;
