// WEB Y API EN LOCAL
//export const CONFIGURATION_API_SERVICE_DOMAIN = process.env.NODE_ENV === "production" ? "https://dhl-db.alterbios.com/recibidos-api/config" : "http://localhost:8080"
//export const AUTHENTICATION_API_SERVICE_DOMAIN = process.env.NODE_ENV === "production" ? "https://dhl-db.alterbios.com/recibidos-api/usuarios" : "http://localhost:8081"
//export const DOCUMENTS_API_SERVICE_DOMAIN = process.env.NODE_ENV === "production" ? "https://dhl-app.alterbios.com/recibidos-api/documentos" : "http://localhost:8082"

// WEB Y API EN PRUEBAS
export const CONFIGURATION_API_SERVICE_DOMAIN = process.env.NODE_ENV === "production" ? "https://pruebas.alterbios.com/config" : "http://localhost:8080"
export const AUTHENTICATION_API_SERVICE_DOMAIN = process.env.NODE_ENV === "production" ? "https://pruebas.alterbios.com/credentials" : "http://localhost:8081"
export const DOCUMENTS_API_SERVICE_DOMAIN = process.env.NODE_ENV === "production" ? "https://pruebas.alterbios.com/transactional" : "http://localhost:8082"



