import { Icon, Paper, Typography } from "@material-ui/core"
import { DocumentContext } from "context"
import {
  COMPLETED_PATH,
  DOCUMENT_PATH,
  ENTERED_PATH,
  PENDING_PATH,
  REJECTED_PATH,
} from "lib"
import React from "react"
import CountUp from "react-countup"
import { useHistory } from "react-router-dom"

interface Props {
  countPath?: string
  handleNotifyError: () => void
  handleLoadingFilter: (state: boolean) => void
  handleClearFilters: () => Promise<void>
}

const CountDocumentsRetenciones = React.memo((props: Props) => {
  const {
    countPath,
    handleNotifyError,
    handleLoadingFilter,
    handleClearFilters,
  } = props
  const {
    count,
    conditions,
    documentsModel,
    getDocumentsByFiltersRetentions,
    setFilters,
    documentFilters: filters,
  } = React.useContext(DocumentContext)
  const { handleChangePage } = documentsModel
  const {
    completed,
    rejected,
    pending,
    total,
    entered,
    error,
    unsend,
    onhold,
    anuled,
    processes,
    acounted,
    value,
  } = count
  const [recount, setRecount] = React.useState<number>(0)
  const history = useHistory()

  React.useEffect(() => {
    setRecount(Math.random())
  }, [])

  const handleFilter = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    conditionName: string
  ) => {
    e.preventDefault()
    try {
      const condiFind = conditions.find((c) => c.name === conditionName)
      if (condiFind) {
        const newFilters = { ...filters, conditions: [condiFind] }
        setFilters(newFilters)
        handleLoadingFilter(true)
        await getDocumentsByFiltersRetentions(undefined, undefined, newFilters)
      } else {
        setFilters({ ...filters, conditions: [] })
      }
      handleChangePage(undefined, 0)
      handleLoadingFilter(false)
    } catch (error) {
      handleNotifyError()
      console.log("Error", error)
    } finally {
      handleLoadingFilter(false)
    }
  }

  return (
    <div className="flex items-center w-full pb-4 justify-between">
      <Paper
        className="px-4 py-3 flex flex-grow mr-2 items-center justify-start relative overflow-hidden cursor-pointer"
        style={{
          backgroundColor: "#ffd54f",
          flex: 1,
        }}
        elevation={0}
      >
        <div className="ml-2" style={{ textAlign: "center" }}>
          <div>
            <Typography
              color="textSecondary"
              variant="h5"
              style={{ fontWeight: 900 }}
            >
              {value ? value : "$ 0.00"}
            </Typography>
          </div>

          <Typography variant="caption" className="opacity-75 font-bold">
            {"Monto Retenciones"}
          </Typography>
        </div>
      </Paper>

      <Paper
        className="px-4 py-3 flex flex-grow mr-2 items-center justify-start relative overflow-hidden cursor-pointer"
        style={{
          // backgroundColor: "#ffa000",
          backgroundColor: "#ffd54f",
          flex: 1,
          maxWidth: "50%",
        }}
        elevation={0}
        onClick={async (e) => {
          await handleFilter(e, "Contabilizado")
        }}
      >
        <div className="glass-md flex items-center justify-center p-2">
          <Icon className="opacity-50">flag</Icon>
        </div>
        <div className="ml-4">
          <CountUp start={recount} duration={1} end={acounted}>
            {({ countUpRef }) => (
              <div>
                <Typography
                  color="textSecondary"
                  variant="h5"
                  style={{ fontWeight: 900 }}
                >
                  <span ref={countUpRef} />
                </Typography>
              </div>
            )}
          </CountUp>
          <Typography variant="caption" className="opacity-75 font-bold">
            {"Contabilizadas"}
          </Typography>
        </div>
      </Paper>

      <Paper
        className="px-4 py-3 flex flex-grow mr-2 items-center justify-start relative overflow-hidden cursor-pointer"
        style={{
          backgroundColor: "#FFD54F",
          flex: 1,
        }}
        elevation={0}
        onClick={async (e) => {
          await handleFilter(e, "En Espera")
        }}
      >
        <div className="glass-md flex items-center justify-center p-2">
          <Icon className="opacity-50">close</Icon>
        </div>
        <div className="ml-4">
          <CountUp start={recount} duration={1} end={onhold}>
            {({ countUpRef }) => (
              <div>
                <Typography
                  color="textSecondary"
                  variant="h5"
                  style={{ fontWeight: 900 }}
                >
                  <span ref={countUpRef} />
                </Typography>
              </div>
            )}
          </CountUp>
          <Typography variant="caption" className="opacity-75 font-bold">
            {"En Espera"}
          </Typography>
        </div>
      </Paper>

      <Paper
        className="px-4 py-3 flex flex-grow mr-2 items-center justify-start relative overflow-hidden cursor-pointer"
        style={{
          backgroundColor: "#FABF2D",
          flex: 1,
        }}
        elevation={0}
        onClick={async (e) => {
          await handleFilter(e, "Pendiente")
        }}
      >
        <div className="glass-md flex items-center justify-center p-2">
          <Icon className="opacity-50">arrow_upward</Icon>
        </div>
        <div className="ml-4">
          <CountUp start={recount} duration={1} end={pending}>
            {({ countUpRef }) => (
              <div>
                <Typography
                  color="textSecondary"
                  variant="h5"
                  style={{ fontWeight: 900 }}
                >
                  <span ref={countUpRef} />
                </Typography>
              </div>
            )}
          </CountUp>
          <Typography variant="caption" className="opacity-75 font-bold">
            {"Pendientes"}
          </Typography>
        </div>
      </Paper>

      <Paper
        className="px-4 py-3 flex flex-grow mr-2 items-center justify-start relative overflow-hidden cursor-pointer"
        style={{
          backgroundColor: "#F9A825",
          flex: 1,
          maxWidth: "50%",
        }}
        elevation={0}
        onClick={async (e) => {
          await handleFilter(e, "En Proceso")
        }}
      >
        <div className="glass-md flex items-center justify-center p-2">
          <Icon className="opacity-50">flag</Icon>
        </div>
        <div className="ml-4">
          <CountUp start={recount} duration={1} end={processes}>
            {({ countUpRef }) => (
              <div>
                <Typography
                  color="textSecondary"
                  variant="h5"
                  style={{ fontWeight: 900 }}
                >
                  <span ref={countUpRef} />
                </Typography>
              </div>
            )}
          </CountUp>
          <Typography variant="caption" className="opacity-75 font-bold">
            {"Por Contabilizar"}
          </Typography>
        </div>
      </Paper>

      <Paper
        className="px-4 py-3 flex flex-grow mr-2 items-center justify-start relative overflow-hidden cursor-pointer"
        style={{
          backgroundColor: "#FFA000",
          flex: 1,
        }}
        elevation={0}
        onClick={async (e) => {
          await handleFilter(e, "Rechazado QF")
        }}
      >
        <div className="glass-md flex items-center justify-center p-2">
          <Icon className="opacity-50">timelapse</Icon>
        </div>
        <div className="ml-4">
          <CountUp start={recount} duration={1} end={rejected}>
            {({ countUpRef }) => (
              <div>
                <Typography
                  color="textSecondary"
                  variant="h5"
                  style={{ fontWeight: 900 }}
                >
                  <span ref={countUpRef} />
                </Typography>
              </div>
            )}
          </CountUp>
          <Typography variant="caption" className="opacity-75 font-bold">
            {"Rechazados"}
          </Typography>
        </div>
      </Paper>

      <Paper
        className="px-4 py-3 flex items-center justify-start relative overflow-hidden"
        style={{
          backgroundColor: "#FFA000",
        }}
        elevation={0}
        onClick={async () => {
          await handleClearFilters()
        }}
      >
        <div className="mx-4">
          <CountUp start={recount} duration={1} end={total}>
            {({ countUpRef }) => (
              <div>
                <Typography
                  color="textSecondary"
                  variant="h5"
                  style={{ fontWeight: 900 }}
                  align="center"
                >
                  <span ref={countUpRef} />
                </Typography>
              </div>
            )}
          </CountUp>
          <Typography variant="caption" className="opacity-75 font-bold">
            {"Total"}
          </Typography>
        </div>
      </Paper>
    </div>
  )
})

export default CountDocumentsRetenciones
