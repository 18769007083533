import { AppBar, Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Paper, Snackbar, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from "@material-ui/core"
import { blue } from "@material-ui/core/colors"
import AllInboxIcon from '@material-ui/icons/AllInbox'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import MailIcon from '@material-ui/icons/Mail'
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox'
import { Alert } from "@material-ui/lab"
import { FixedLoadingIndicator } from "components"
import { demonsAllProcessAPI, demonsAuxiliarNo, demonsAuxiliarReverso, demonsCreditNoteProcessAPI, demonsDocumentAPI, demonsDownloadEmitidosAPI, demonsInvoiceProcessAPI, demonsMailIncompleteRetention, demonsMailNoRetention, demonsRetentionProcessAPI, demonsVerifyDownloadAPI, DEMONS_TITLE, ROOT_TITLE } from "lib"
import React from "react"
import { Helmet } from "react-helmet"
import Closing from "routes/Closing/Closing"
import { IMessageConfig } from "types"

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}


const Demons = () => {

    const [openDialog, setOpenDialog] = React.useState<boolean>(false)
    const [id, setId] = React.useState<number>(0)
    const [loading, setLoading] = React.useState(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })

    const [value, setValue] = React.useState(0);

    const handleOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIDValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (newValue.match(/^\d*$/)) {
          setId(parseInt(newValue));
        }
      };


    const handleRunDemon = async (tipo: string, id?: number) => {
        setLoading(true)
        setOpenDialog(false)
        try {
            switch (tipo) {
                case 'download':
                    await demonsDownloadEmitidosAPI()
                    break;
                case 'verify':
                    await demonsVerifyDownloadAPI()
                    break;
                case 'document':
                    await demonsDocumentAPI(id!)
                    setId(0)
                    break;
                case '3all':
                    await demonsAllProcessAPI()
                    break;
                case 'invoice':
                    await demonsInvoiceProcessAPI()
                    break;
                case 'retention':
                    await demonsRetentionProcessAPI()
                    break;
                case 'creditnote':
                    await demonsCreditNoteProcessAPI()
                    break;
                case 'noretention':
                    await demonsMailNoRetention()
                    break;
                case 'incomplete':
                    await demonsMailIncompleteRetention()
                    break;
                case 'noauxiliar':
                    await demonsAuxiliarNo()
                    break;
                case 'reverso':
                    await demonsAuxiliarReverso()
                    break;
                default:
                    console.log("No matching value");
                    break;
            }
        } catch (error) {
            console.log(error)
            setMessageConfig({ open: true, message: "No se pudo obtener información del documento selecionado...", severity: "error" })
            setLoading(false)
        }
        console.log('SE EJECUTO CON EXITO');
        setMessageConfig({ open: true, message: "Proceso ejecutado con exito", severity: "success" })
        setLoading(false)
    }

    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${DEMONS_TITLE}`}</title>
            </Helmet>
            <div>
                <AppBar position="static" color="default" style={{ marginLeft: '20px', marginRight: '20px', width: 'auto' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="scrollable force tabs example"
                    >
                        <Tab label="Emitidos DHL" icon={<MoveToInboxIcon />} {...a11yProps(0)} />
                        <Tab label="Documentos" icon={<MailIcon />} {...a11yProps(1)} />
                        <Tab label="Correos" icon={<FileCopyIcon />} {...a11yProps(2)} />
                        <Tab label="Auxiliar" icon={<AllInboxIcon />} {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Demon</TableCell>
                                    <TableCell>Descripcion</TableCell>
                                    <TableCell>Accion</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Descarga TXT Emitidos</TableCell>
                                    <TableCell >Descarga el archivos con las claves de acceso de facturas y notas de credito emitidas por DHL.</TableCell>
                                    <TableCell ><Button size="small" variant="contained" color="primary" onClick={() => { handleRunDemon('download') }} disableElevation>{"EJECUTAR"}</Button></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Verificar Descarga Diaria</TableCell>
                                    <TableCell >Verifica que ingreso los documentos emitidos desde el servidor de DHL Emitidos</TableCell>
                                    <TableCell ><Button size="small" variant="contained" color="primary" onClick={() => { handleRunDemon('verify') }} disableElevation>{"EJECUTAR"}</Button></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Demon</TableCell>
                                    <TableCell>Descripcion</TableCell>
                                    <TableCell>Accion</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Documento por ID</TableCell>
                                    <TableCell >Ingresa a flujo un documento por su ID, puede ser emitido o recibido</TableCell>
                                    <TableCell ><Button size="small" variant="contained" color="primary" onClick={handleOpen} disableElevation>{"EJECUTAR"}</Button></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>3 Procesos Principales</TableCell>
                                    <TableCell >Match de retenciones, facturas y notas de credito entre emitidos y recibidos</TableCell>
                                    <TableCell ><Button size="small" variant="contained" color="primary" onClick={() => { handleRunDemon('3all') }} disableElevation>{"EJECUTAR"}</Button></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Match Retenciones</TableCell>
                                    <TableCell >Match unico de retenciones recibidas con facturas emitidas</TableCell>
                                    <TableCell ><Button size="small" variant="contained" color="primary" onClick={() => { handleRunDemon('invoice') }} disableElevation>{"EJECUTAR"}</Button></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Match Facturas</TableCell>
                                    <TableCell >Match unico de facturas emitidas para cambiar a estado de espera</TableCell>
                                    <TableCell ><Button size="small" variant="contained" color="primary" onClick={() => { handleRunDemon('retention') }} disableElevation>{"EJECUTAR"}</Button></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Match Noras de Credito</TableCell>
                                    <TableCell >Match unico de notas de credito en busca de retenciones para anulacion</TableCell>
                                    <TableCell ><Button size="small" variant="contained" color="primary" onClick={() => { handleRunDemon('creditnote') }} disableElevation>{"EJECUTAR"}</Button></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Demon</TableCell>
                                    <TableCell>Descripcion</TableCell>
                                    <TableCell>Accion</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Sin Retencion</TableCell>
                                    <TableCell >Envio de correo a cada cliente que aun no emite la retencion hacia DHL</TableCell>
                                    <TableCell ><Button size="small" variant="contained" color="primary" onClick={() => { handleRunDemon('noretention') }} disableElevation>{"EJECUTAR"}</Button></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Retencion Incompleta</TableCell>
                                    <TableCell >Envio de correo a cada cleinte que no emitio la retencion completa</TableCell>
                                    <TableCell ><Button size="small" variant="contained" color="primary" onClick={() => { handleRunDemon('incomplete') }} disableElevation>{"EJECUTAR"}</Button></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Demon</TableCell>
                                    <TableCell>Descripcion</TableCell>
                                    <TableCell>Accion</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Match sin Auxiliar</TableCell>
                                    <TableCell >Matcheo de data sin cargar el auxiliar, (emitidos-recibidos)</TableCell>
                                    <TableCell ><Button size="small" variant="contained" color="primary" onClick={() => { handleRunDemon('noauxiliar') }} disableElevation>{"EJECUTAR"}</Button></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Matcheo Reversos</TableCell>
                                    <TableCell >Matcheo de reversos (emitidos-recibidos), sin la carga del auxiliar</TableCell>
                                    <TableCell ><Button size="small" variant="contained" color="primary" onClick={() => { handleRunDemon('reverso') }} disableElevation>{"EJECUTAR"}</Button></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            </div>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading} />
            <Dialog onClose={handleClose} open={openDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Documento a procesar:"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TextField id="outlined-basic" label="ID" variant="outlined" type="number" value={id} fullWidth onChange={handleChangeIDValue}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{padding:'20px'}}>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button autoFocus variant="contained" color="primary" onClick={() => {handleRunDemon('document',id) }}>Enviar</Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

export default Demons