import { Button, Divider, Icon, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { CurrencyInput, FixedLoadingIndicator } from 'components'
import { AuthContext } from 'context'
import { useGetSupplier, useUpdateValues } from 'hooks'
import { formatCurrency, getDetailSummary, getDetailSummaryFromData, getDetailSummaryXML } from 'lib'
import React from 'react'
import { IDetail, IDetailSummary, IMessageConfig } from 'types'

interface Props {
    data: any
    detail: IDetail[]
    showInputs?: boolean
    showAdditionalInfo?: boolean
    additionalInfo?: string
    supplierId?: string
    documentId: number,
    electronicData?: string
}

const labelStyle: any = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1", textAlign: "right" }

const ValuesData = (props: Props) => {

    const { data, detail, showInputs, supplierId, additionalInfo, documentId, showAdditionalInfo, electronicData } = props
    const { user } = React.useContext(AuthContext)
    const getSummaryFromData = React.useCallback((): IDetailSummary[] => { return getDetailSummaryFromData(data) }, [data])
    const { supplier, loading } = useGetSupplier({ ruc: supplierId ?? "" })
    const { taxBase, total, handleChange, loading: updating, handleSubmit: onSubmit } = useUpdateValues({ additionalInfo: additionalInfo ?? "{}", documentId })
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info", })
    const handleSubmit = async (e: React.FormEvent) => {
        if (await onSubmit(e)) {
            setMessageConfig({ open: true, message: "Información de documento actualizada!", severity: "success" })
        } else {
            setMessageConfig({ open: true, message: "No se pudo actualizar el documento...", severity: "error" })
        }
    }
    const getSummary = React.useCallback((): IDetailSummary[] => {
        if (detail && !showAdditionalInfo && !electronicData) {
            return getDetailSummary(detail);
        } else if (detail && electronicData ) {
            return getDetailSummaryXML(electronicData);
        } else {
            return [];
        }
    }, [detail, showAdditionalInfo]);


    /* React.useEffect(() => {
        console.log(showInputs,'Show imputs');
        console.log(Boolean(supplier?.updateTaxBase),'Editar Base');
        console.log(Boolean(supplier?.updateTotal),'Editar Total');
    }, [])
     */

    return (
        <Paper variant="outlined" className="mt-3" elevation={1}>
            <TableContainer>
                <Table size="small">
                    <TableBody>
                        {getSummary().map(summary => (
                            <React.Fragment>
                                {
                                    parseInt(summary.value.toString()) !== 0 &&
                                    <TableRow>
                                        <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{summary.label}</TableCell>
                                        <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${summary.substractsFromTotal ? "(-)" : ""} ${formatCurrency(summary.value)}`}</TableCell>
                                    </TableRow>
                                }
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                (showInputs && ((Boolean(supplier?.updateTaxBase) || Boolean(supplier?.updateTotal)))) &&
                <form onSubmit={handleSubmit}>
                    <div className="w-full pb-4 pt-8 px-16">
                        <Divider />
                    </div>
                    <div className="w-full flex flex-col flex p-4 items-end" >
                        <div className="flex w-full items-center justify-end">
                            <Typography style={labelStyle}>
                                {"Editar base imponible"}
                            </Typography>
                            <TextField
                                size="small"
                                placeholder="0.00"
                                variant="outlined"
                                style={{ width: "40%", marginLeft: 20 }}
                                value={taxBase}
                                disabled={!Boolean(supplier?.updateTaxBase) || !Boolean(user?.canApprove) || loading}
                                onFocus={(e) => e.target.select()}
                                onChange={(e) => handleChange("taxBase", parseFloat(e.target.value))}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon fontSize="small" color="action">attach_money</Icon>
                                        </InputAdornment>
                                    ),
                                    inputComponent: CurrencyInput as any,
                                }}
                            />
                        </div>
                        <div className="flex w-full items-center justify-end my-2">
                            <Typography style={labelStyle}>
                                {"Editar total"}
                            </Typography>
                            <TextField
                                size="small"
                                placeholder="0.00"
                                variant="outlined"
                                disabled={!Boolean(supplier?.updateTotal) || !Boolean(user?.canApprove) || loading}
                                onFocus={(e) => e.target.select()}
                                style={{ width: "40%", marginLeft: 20 }}
                                value={total}
                                onChange={(e) => handleChange("total", parseFloat(e.target.value))}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon fontSize="small" color="action">attach_money</Icon>
                                        </InputAdornment>
                                    ),
                                    inputComponent: CurrencyInput as any,
                                }}
                            />
                        </div>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ width: "40%", marginTop: 15 }}
                            disableElevation
                            type="submit"
                            disabled={!Boolean(user?.canApprove) || (!Boolean(supplier?.updateTotal) && !Boolean(supplier?.updateTaxBase)) || loading || updating}
                        >
                            {"Guardar"}
                        </Button>
                    </div>
                </form>
            }
            {
                showAdditionalInfo &&
                <div>
                    <div className="w-full pb-4 pt-4 pr-4 items-center">
                        <Typography style={labelStyle}>
                            {"Editados"}
                        </Typography>
                    </div>
                    <Divider />
                    <div className="w-full flex flex-col flex p-4 items-end">
                        <div className="flex w-full items-center justify-end">
                            <Typography style={labelStyle}>
                                {"Base imponible"}
                            </Typography>
                            <Typography variant="caption" align="right" style={{ width: "35%", fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }}>
                                {formatCurrency(JSON.parse(additionalInfo ?? "{}").taxBase ?? 0)}
                            </Typography>
                        </div>
                        <div className="flex w-full items-center justify-end my-2">
                            <Typography style={labelStyle}>
                                {"Total"}
                            </Typography>
                            <Typography variant="caption" align="right" style={{ width: "35%", fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }}>
                                {formatCurrency(JSON.parse(additionalInfo ?? "{}").total ?? 0)}
                            </Typography>
                        </div>
                    </div>
                </div>
            }
            <FixedLoadingIndicator loading={updating} />
            <Snackbar
                open={messageConfig.open}
                autoHideDuration={6000}
                onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Alert
                    variant="filled"
                    onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                    severity={messageConfig.severity}
                >
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </Paper>
    )
}

export default ValuesData
