import { ISupplier, ISupplierFileRequest, ISupplierValidation, IUploadDocumentFile, SupplierFileMap, SupplierUploadedFileMap } from "types"

export const validateSupplierFields = (supplier: ISupplier): ISupplierValidation => {
    const validatedTabs: ISupplierValidation = { valid: true, tab: 0 }
    if (!Boolean(supplier.identification)
        || !Boolean(supplier.identificationType)
        || !Boolean(supplier.companyName)
        || !Boolean(supplier.address)
        || !Boolean(supplier.paymentTerms)
        || !Boolean(supplier.keepAccounting)) {
        validatedTabs.valid = false
        validatedTabs.tab = 0
    }
    return validatedTabs
}

// export const getSupplierFilesWithTag = (codeOfConductAgreement: File | undefined, antiCorruptionAgreement: File | undefined, dueDilligenceAgreement: File | undefined, rucFile: File | undefined, appointmentFile: File | undefined, bankCertificateFile: File | undefined): ISupplierFileRequest[] => {
//     const files: ISupplierFileRequest[] = []
//     if (codeOfConductAgreement) { files.push({ file: codeOfConductAgreement, tag: FIELDS.tagCodeConduct.key }) }
//     if (antiCorruptionAgreement) { files.push({ file: antiCorruptionAgreement, tag: FIELDS.tagAntiCorruption.key }) }
//     if (dueDilligenceAgreement) { files.push({ file: dueDilligenceAgreement, tag: FIELDS.tagDilligence.key }) }
//     if (rucFile) { files.push({ file: rucFile, tag: FIELDS.tagRuc.key }) }
//     if (appointmentFile) { files.push({ file: appointmentFile, tag: FIELDS.tagAppointment.key }) }
//     if (bankCertificateFile) { files.push({ file: bankCertificateFile, tag: FIELDS.tagBankCertificate.key }) }
//     return files
// }

export const getSupplierFilesWithTag = (supplierFileMap: SupplierFileMap, supplierUploadedFileMap: SupplierUploadedFileMap): ISupplierFileRequest[] => {
    const files: ISupplierFileRequest[] = []
    for (const key in supplierFileMap) {
        if (Object.prototype.hasOwnProperty.call(supplierFileMap, key)) {
            const element:IUploadDocumentFile[] = supplierFileMap[key]
            element.map((file, index) => (
                files.push({ file: file.file, tag: key + "-" + (index + supplierUploadedFileMap[key].length)})
            ))
        }
    }
    return files
}
