export const COUNTRIES = [
  { name: "AFGANISTAN", value: "301" },
  { name: "AGUAS INTERNACIONALES", value: "594" },
  { name: "ALBANIA", value: "201" },
  { name: "ALBORAN", value: "207" },
  { name: "ALEMANIA", value: "202" },
  { name: "ALTO VOLTA", value: "595" },
  { name: "AMERICAN SAMOA", value: "016" },
  { name: "ANDORRA", value: "232" },
  { name: "ANDORRA", value: "233" },
  { name: "ANGOLA", value: "454" },
  { name: "ANGUILA", value: "109" },
  { name: "ANTIGUA Y BARBUDA", value: "134" },
  { name: "ANTILLAS HOLANDESAS", value: "140" },
  { name: "ARABIA SAUDITA", value: "302" },
  { name: "ARGELIA", value: "403" },
  { name: "ARGENTINA", value: "101" },
  { name: "ARMENIA", value: "356" },
  { name: "ARUBA", value: "141" },
  { name: "AUSTRALIA", value: "501" },
  { name: "AUSTRIA", value: "203" },
  { name: "AZERBAIJAN", value: "347" },
  { name: "BAHAMAS ", value: "129" },
  { name: "BAHREIN", value: "327" },
  { name: "BANGLADESH", value: "328" },
  { name: "BARBADOS", value: "130" },
  { name: "BELARUS", value: "241" },
  { name: "BELICE 416 MAURITANIA", value: "135" },
  { name: "BENIN", value: "429" },
  { name: "BERMUDA", value: "142" },
  { name: "BIELORRUSIA", value: "596" },
  { name: "BOLIVIA", value: "102" },
  { name: "BOSNIA Y HERZEGOVINA", value: "242" },
  { name: "BOTSWANA", value: "430" },
  { name: "BOUVET ISLAND", value: "074" },
  { name: "BRASIL", value: "103" },
  { name: "BRUNEI DARUSSALAM", value: "344" },
  { name: "BULGARIA", value: "205" },
  { name: "BURKINA FASO", value: "402" },
  { name: "BURUNDI", value: "404" },
  { name: "BUTAN", value: "329" },
  { name: "BÉLGICA", value: "204" },
  { name: "CABO VERDE", value: "456" },
  { name: "CAMBOYA", value: "304" },
  { name: "CAMERUN", value: "405" },
  { name: "CANADÁ", value: "104" },
  { name: "CANARIAS ISLAS", value: "228" },
  { name: "CHAD", value: "433" },
  { name: "CHILE", value: "108" },
  { name: "COLOMBIA", value: "105" },
  { name: "COMORAS", value: "458" },
  { name: "CONGO", value: "406" },
  { name: "COREA NORTE", value: "306" },
  { name: "COSTA", value: "106" },
  { name: "COSTA DE MARFIL", value: "432" },
  { name: "COTE DÍVOIRE", value: "597" },
  { name: "CROACIA", value: "243" },
  { name: "CUBA", value: "107" },
  { name: "CURAZAO", value: "127" },
  { name: "DINAMARCA", value: "208" },
  { name: "DJIBOUTI", value: "459" },
  { name: "DOMINICA 417 NIGERIA", value: "136" },
  { name: "ECUADOR", value: "593" },
  { name: "EGIPTO", value: "434" },
  { name: "EL SALVADOR", value: "123" },
  { name: "EPUBLICA CENTROAFRICANA", value: "431" },
  { name: "ERITREA", value: "463" },
  { name: "ESLOVAQUIA", value: "252" },
  { name: "ESLOVENIA", value: "244" },
  { name: "ESPAÑA", value: "209" },
  { name: "ESTADOS", value: "110" },
  { name: "ESTONIA", value: "245" },
  { name: "ETIOPIA", value: "407" },
  { name: "FALKLAND ISLANDS", value: "600" },
  { name: "FIJI", value: "506" },
  { name: "FILIPINAS", value: "308" },
  { name: "FINLANDIA", value: "212" },
  { name: "FRANCIA", value: "211" },
  { name: "FRENCH SOUTHERN TERRITORIES", value: "260" },
  { name: "GABON", value: "435" },
  { name: "GAMBIA", value: "408" },
  { name: "GEORGIA ", value: "246" },
  { name: "GHANA", value: "436" },
  { name: "GIBRALTAR", value: "239" },
  { name: "GRANADA", value: "131" },
  { name: "GRECIA", value: "214" },
  { name: "GROENLANDIA", value: "247" },
  { name: "GUADALUPE", value: "143" },
  { name: "GUAM", value: "517" },
  { name: "GUATEMALA", value: "111" },
  { name: "GUERNSEY", value: "831" },
  { name: "GUINEA", value: "409" },
  { name: "GUINEA ECUATORIAL", value: "438" },
  { name: "GUINEA-BISSAU", value: "437" },
  { name: "GUYANA", value: "132" },
  { name: "GUYANA", value: "144" },
  { name: "HAITÍ", value: "112" },
  { name: "HONDURAS", value: "113" },
  { name: "HONG KONG", value: "354" },
  { name: "HUNGRIA", value: "216" },
  { name: "INDIA", value: "309" },
  { name: "INDONESIA", value: "310" },
  { name: "IRAK", value: "311" },
  { name: "IRAN (REPUBLICA ISLAMICA)", value: "312" },
  { name: "IRLANDA", value: "217" },
  { name: "ISLANDIA ", value: "218" },
  { name: "ISLAS CAIMÁN", value: "145" },
  { name: "ISLAS COCOS (KEELING)", value: "518" },
  { name: "ISLAS COOK", value: "519" },
  { name: "ISLAS FAROE", value: "253" },
  { name: "ISLAS HEARD Y MCDONALD", value: "343" },
  { name: "ISLAS NAVIDAD", value: "520" },
  { name: "ISLAS VIRGENES", value: "146" },
  { name: "ISLE OF MAN", value: "833" },
  { name: "ISRAEL", value: "313" },
  { name: "ITALIA", value: "219" },
  { name: "JAMAICA", value: "114" },
  { name: "JAPON", value: "314" },
  { name: "JERSEY", value: "499" },
  { name: "JERSEY", value: "832" },
  { name: "JOHNSTON ISLA", value: "147" },
  { name: "JORDANIA", value: "315" },
  { name: "KAZAJSTAN", value: "348" },
  { name: "KENIA", value: "439" },
  { name: "KIRGUIZISTAN", value: "349" },
  { name: "KIRIBATI", value: "510" },
  { name: "LAOS, REP. POP. DEMOC.", value: "317" },
  { name: "LATVIA", value: "601" },
  { name: "LESOTHO", value: "440" },
  { name: "LETONIA", value: "248" },
  { name: "LIBANO", value: "318" },
  { name: "LIBERIA", value: "410" },
  { name: "LIBIA", value: "602" },
  { name: "LIECHTENSTEIN", value: "234" },
  { name: "LITUANIA", value: "249" },
  { name: "LUXEMBURGO", value: "220" },
  { name: "MACAO", value: "355" },
  { name: "MACEDONIA", value: "251" },
  { name: "MADAGASCAR", value: "412" },
  { name: "MALASIA", value: "319" },
  { name: "MALAWI", value: "413" },
  { name: "MALDIVAS", value: "335" },
  { name: "MALI", value: "414" },
  { name: "MALTA", value: "221" },
  { name: "MALVINAS", value: "115" },
  { name: "MARRUECOS", value: "415" },
  { name: "MARSHALL ISLAS", value: "511" },
  { name: "MARTINICA", value: "148" },
  { name: "MAURICIO", value: "441" },
  { name: "MAURITANIA", value: "416" },
  { name: "MAYOTTE", value: "443" },
  { name: "MICRONESIA", value: "512" },
  { name: "MIDWAY ISLAS", value: "521" },
  { name: "MOLDOVA", value: "250" },
  { name: "MONACO", value: "235" },
  { name: "MONGOLIA (MANCHURIA)", value: "321" },
  { name: "MONTENEGRO", value: "382" },
  { name: "MONTSERRAT ISLA", value: "149" },
  { name: "MOROCCO", value: "464" },
  { name: "MOZAMBIQUE", value: "442" },
  { name: "MYANMAR (BURMA)", value: "303" },
  { name: "MÉXICO", value: "116" },
  { name: "NAMIBIA", value: "460" },
  { name: "NAURU", value: "513" },
  { name: "NEPAL", value: "336" },
  { name: "NICARAGUA", value: "117" },
  { name: "NIGER", value: "444" },
  { name: "NIGERIA", value: "417" },
  { name: "NIUE ISLA", value: "522" },
  { name: "NORFOLK ISLA", value: "523" },
  { name: "NORTHERN MARIANA ISL", value: "603" },
  { name: "NORUEGA", value: "222" },
  { name: "NUEVA CALEDONIA", value: "524" },
  { name: "NUEVA ZELANDA", value: "503" },
  { name: "OMAN", value: "337" },
  { name: "PAISES BAJOS", value: "215" },
  { name: "PAKISTAN", value: "322" },
  { name: "PALAO (BELAU) ISLAS", value: "509" },
  { name: "PALESTINA", value: "353" },
  { name: "PANAMÁ", value: "118" },
  { name: "PAPUA NUEVA GUINEA", value: "507" },
  { name: "PARAGUAY", value: "119" },
  { name: "PERÚ", value: "120" },
  { name: "PITCAIRN, ISLA", value: "525" },
  { name: "POLINESIA FRANCESA", value: "526" },
  { name: "POLONIA", value: "223" },
  { name: "PORTUGAL ", value: "224" },
  { name: "PUERTO RICO", value: "121" },
  { name: "QATAR", value: "334" },
  { name: "REINO UNIDO", value: "213" },
  { name: "REPUBLICA CHECA", value: "599" },
  { name: "REPÚBLICA DOMINICANA", value: "122" },
  { name: "REUNION", value: "465" },
  { name: "RUMANIA", value: "225" },
  { name: "RUSIA", value: "230" },
  { name: "RWANDA", value: "445" },
  { name: "SAHARA OCCIDENTAL", value: "447" },
  { name: "SAINT BARTHELEMY", value: "590" },
  { name: "SALOMON ISLAS", value: "514" },
  { name: "SAMOA OCCIDENTAL", value: "504" },
  { name: "SAN CRISTOBAL Y NEVIS", value: "137" },
  { name: "SAN MARINO", value: "237" },
  { name: "SAN VICENTE Y LAS GRANAD.", value: "139" },
  { name: "SANTA ELENA", value: "466" },
  { name: "SANTA LUCÍA", value: "138" },
  { name: "SANTO TOME Y PRINCIPE", value: "449" },
  { name: "SENEGAL", value: "420" },
  { name: "SERBIA", value: "608" },
  { name: "SEYCHELLES", value: "446" },
  { name: "SIERRA LEONA", value: "423" },
  { name: "SINGAPUR", value: "338" },
  { name: "SIRIA", value: "323" },
  { name: "SOMALIA", value: "448" },
  { name: "SRI LANKA (CEILAN)", value: "339" },
  { name: "ST. PIERRE AND MIQUE", value: "604" },
  { name: "SUDAFRICA (CISKEI)", value: "422" },
  { name: "SUDAN", value: "421" },
  { name: "SUECIA", value: "226" },
  { name: "SUIZA ", value: "227" },
  { name: "SURINAM", value: "133" },
  { name: "SWAZILANDIA", value: "450" },
  { name: "SYRIAN ARAB REPUBLIC", value: "605" },
  { name: "TAILANDIA", value: "325" },
  { name: "TAIWAN (CHINA)", value: "307" },
  { name: "TAJIKISTAN", value: "350" },
  { name: "TANZANIA", value: "425" },
  { name: "TERRITORIO ANTARTICO BRITANICO", value: "606" },
  { name: "TERRITORIO BRITANICO OCEANO IN", value: "607" },
  { name: "TIMOR DEL ESTE", value: "529" },
  { name: "TOGO", value: "451" },
  { name: "TOKELAI", value: "530" },
  { name: "TONGA", value: "508" },
  { name: "TRINIDAD Y TOBAGO", value: "124" },
  { name: "TUNEZ", value: "452" },
  { name: "TURCAS Y CAICOS ISLAS", value: "151" },
  { name: "TURKMENISTAN", value: "351" },
  { name: "TURQUIA", value: "346" },
  { name: "TUVALU", value: "515" },
  { name: "UCRANIA", value: "229" },
  { name: "UGANDA", value: "426" },
  { name: "URUGUAY", value: "125" },
  { name: "UZBEKISTAN", value: "352" },
  { name: "VANUATU", value: "516" },
  { name: "VATICANO", value: "238" },
  { name: "VENEZUELA", value: "126" },
  { name: "VIETNAM", value: "341" },
  { name: "VIRGENES, ISLAS (NORT.AMER.)", value: "152" },
  { name: "WAKE ISLA", value: "531" },
  { name: "WALLIS Y FUTUNA, ISLAS", value: "532" },
  { name: "YEMEN", value: "342" },
  { name: "YUGOSLAVIA", value: "231" },
  { name: "ZAIRE", value: "453" },
  { name: "ZAMBIA", value: "427" },
  { name: "ZIMBABWE (RHODESIA)", value: "419" },
  { name: "ÅLAND ISLANDS", value: "428" },
];
