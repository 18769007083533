import { uniqueListByKey, FIELDS } from "lib"
import { IDetailSummary, ITax, IDetail, IElectronicInvoice } from "types"

export const getDetailSummary = (detail: IDetail[]): IDetailSummary[] => {
    let detailSummary: IDetailSummary[] = []
    let taxes: ITax[] = []
    for (const d of detail) { if (d.tax) { taxes.push(d.tax) } }
    taxes = uniqueListByKey(taxes, FIELDS.taxId.key)
    const taxesSubtotal: IDetailSummary[] = []
    for (const tax of taxes) {
        const summary: IDetailSummary = { label: tax.description, value: 0, addsToTotal: true, substractsFromTotal: false }
        for (const d of detail) {
            if (d.tax && d.tax.taxId === tax.taxId) {
                const taxValue = summary.value + (d.taxesValue ? d.taxesValue : (d.unitPrice && d.quantity ? (d.unitPrice * d.quantity) * (tax.percentage / 100) : 0))
                summary.value = parseFloat(taxValue.toFixed(2))
            }
        }
        taxesSubtotal.push(summary)
    }
    detailSummary = [...detailSummary].concat(taxesSubtotal)
    const subtotalSummary: IDetailSummary[] = []
    for (const tax of taxes) {
        const summary: IDetailSummary = { label: `Subtotal ${tax.percentage}%`, value: 0, addsToTotal: true, substractsFromTotal: false }
        for (const d of detail) {
            if (d.tax && d.tax.taxId === tax.taxId) {
                const totalValue = summary.value + (d.taxesValue ? d.taxesValue : (d.unitPrice && d.quantity ? (d.unitPrice * d.quantity) : 0))
                summary.value = parseFloat(totalValue.toFixed(2))
            }
        }
        subtotalSummary.push(summary)
    }
    detailSummary = [...detailSummary].concat(subtotalSummary)
    const valueNoTaxesSummary: IDetailSummary = { label: "Subtotal sin impuestos", value: 0, addsToTotal: subtotalSummary.length === 0, substractsFromTotal: false }
    for (const d of detail) {
        const totalValue = (d.subtotalNoTaxes ? parseFloat(d.subtotalNoTaxes.toString()) : (d.unitPrice && d.quantity ? (d.unitPrice * d.quantity) : 0))
        valueNoTaxesSummary.value = valueNoTaxesSummary.value + parseFloat(totalValue.toFixed(2))
    }
    detailSummary.push(valueNoTaxesSummary)
    const taxesSummary: IDetailSummary = { label: "Valor impuestos", value: 0, addsToTotal: taxesSubtotal.length === 0, substractsFromTotal: false }
    for (const d of detail) {
        if (!d.tax) {
            taxesSummary.value = taxesSummary.value + (d.taxesValue ? parseFloat(d.taxesValue.toString()) : 0)
        }
    }
    detailSummary.push({ ...taxesSummary, value: parseFloat(taxesSummary.value.toFixed(2)) })
    const discountSummary: IDetailSummary = { label: "Descuento", value: 0, addsToTotal: false, substractsFromTotal: true }
    for (const d of detail) {
        discountSummary.value = discountSummary.value + (d.discount ? parseFloat(d.discount.toString()) : 0)
    }
    detailSummary.push(discountSummary)
    const total: IDetailSummary = { label: "Total", value: 0, addsToTotal: false, substractsFromTotal: false }
    for (const summary of detailSummary) {
        if (summary.addsToTotal) {
            total.value = total.value + summary.value
        }
        if (summary.substractsFromTotal) {
            total.value = total.value - summary.value
        }
    }
    detailSummary.push({ ...total, value: parseFloat(total.value.toFixed(2)) })
    return detailSummary
}



export const getDetailSummaryXML = (electronicData: string): IDetailSummary[] => {

    let detailSummary: IDetailSummary[] = []
    const data = JSON.parse(electronicData)
    const invoice: IElectronicInvoice = { ...data }

    if (invoice.infoFactura.totalConImpuestos) {
        const totalImpuesto = invoice.infoFactura.totalConImpuestos.totalImpuesto;
        if (totalImpuesto && Array.isArray(totalImpuesto)) {
            for (let i = 0; i < totalImpuesto.length; i++) {
                const impuesto = totalImpuesto[i];
                if (impuesto.codigo === "2" && impuesto.codigoPorcentaje === "2") {
                    const summaryIva12: IDetailSummary = { label: 'IVA 12%', value: 0, addsToTotal: false, substractsFromTotal: false }
                    summaryIva12.value = parseFloat(impuesto.valor.toString())
                    detailSummary.push(summaryIva12)

                    const summarySubtotal12: IDetailSummary = { label: 'Subtotal 12%', value: 0, addsToTotal: false, substractsFromTotal: false }
                    summarySubtotal12.value = parseFloat(impuesto.baseImponible.toString())
                    detailSummary.push(summarySubtotal12)
                }
                if (impuesto.codigo === "2" && impuesto.codigoPorcentaje === "4") {
                    const summaryIva15: IDetailSummary = { label: 'IVA 15%', value: 0, addsToTotal: false, substractsFromTotal: false }
                    summaryIva15.value = parseFloat(impuesto.valor.toString())
                    detailSummary.push(summaryIva15)

                    const summarySubtotal15: IDetailSummary = { label: 'Subtotal 15%', value: 0, addsToTotal: false, substractsFromTotal: false }
                    summarySubtotal15.value = parseFloat(impuesto.baseImponible.toString())
                    detailSummary.push(summarySubtotal15)
                }
                if (impuesto.codigo === "2" && impuesto.codigoPorcentaje === "0") {
                    const summaryIva0: IDetailSummary = { label: 'IVA 0%', value: 0, addsToTotal: false, substractsFromTotal: false }
                    summaryIva0.value = parseFloat(impuesto.valor.toString())
                    detailSummary.push(summaryIva0)

                    const summarySubtotal0: IDetailSummary = { label: 'Subtotal 0%', value: 0, addsToTotal: false, substractsFromTotal: false }
                    summarySubtotal0.value = parseFloat(impuesto.baseImponible.toString())
                    detailSummary.push(summarySubtotal0)
                }
                if (impuesto.codigo === "2" && impuesto.codigoPorcentaje === "6") {
                    const summaryIvaNoSujeto: IDetailSummary = { label: 'IVA No Sujeto', value: 0, addsToTotal: false, substractsFromTotal: false }
                    summaryIvaNoSujeto.value = parseFloat(impuesto.valor.toString())
                    detailSummary.push(summaryIvaNoSujeto)

                    const summarySubtotaNoSujeto: IDetailSummary = { label: 'Subtotal No Sujeto', value: 0, addsToTotal: false, substractsFromTotal: false }
                    summarySubtotaNoSujeto.value = parseFloat(impuesto.baseImponible.toString())
                    detailSummary.push(summarySubtotaNoSujeto)
                }
            }
        }
    }
    if (invoice.infoFactura.propina) {
        const summaryPropina: IDetailSummary = { label: 'Propina', value: 0, addsToTotal: false, substractsFromTotal: false }
        summaryPropina.value = parseFloat(invoice.infoFactura.propina.toString())
        detailSummary.push(summaryPropina)
    }
    if (invoice.otrosRubrosTerceros) {
        const summaryOtrosRubrosTerceros: IDetailSummary = { label: 'Otros Rubros Terceros', value: 0, addsToTotal: false, substractsFromTotal: false }
        summaryOtrosRubrosTerceros.value = parseFloat('0')
        detailSummary.push(summaryOtrosRubrosTerceros)
    }
    if (invoice.infoFactura.totalSinImpuestos) {
        const summarySubtotalSinImpuestos: IDetailSummary = { label: 'Subtotal sin impuestos', value: 0, addsToTotal: false, substractsFromTotal: false }
        summarySubtotalSinImpuestos.value = parseFloat(invoice.infoFactura.totalSinImpuestos.toString())
        detailSummary.push(summarySubtotalSinImpuestos)
    }
    if (invoice.infoFactura.importeTotal) {
        const summaryTotal: IDetailSummary = { label: 'Total', value: 0, addsToTotal: false, substractsFromTotal: false }
        summaryTotal.value = parseFloat(invoice.infoFactura.importeTotal.toString())
        detailSummary.push(summaryTotal)
    }

    return detailSummary
}



export const getDetailSummaryFromData = (data: any): IDetailSummary[] => {
    let detailSummary: IDetailSummary[] = []
    return detailSummary
}