import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, FormGroup, FormControlLabel, Divider } from '@material-ui/core'
import { useGetCustomer, useGetSupplier } from 'hooks'
import { approveDocumentService, DOCUMENT_APPROVE_MANUALLY_ACTION_ID, DOCUMENT_MANAGE_ACTION_ID, DOCUMENT_REJECT_MANUALLY_ACTION_ID, DOCUMENT_SEND_REIMBURSEMENT_ACTION_ID, DOCUMENT_STATES, FIELDS, manageDocumentByIdService } from 'lib'
import React from 'react'
import { IDocumentAction, ISupplier } from 'types'
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { Autocomplete } from '@material-ui/lab'
import isEmail from 'validator/lib/isEmail'
import { AuthContext } from 'context'
import { FixedLoadingIndicator } from 'components'
import moment from 'moment'
import { ITemplateEmail } from 'model/TemplateEmailModel'
import { getTemplatesEmail } from 'api/APITemplateEmail'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface Props {
    open: boolean
    onClose: () => void
    supplier?: ISupplier
    onSend: () => void
    onError: () => void
    invoiceNumber?: string
    aditionalInfo?: string
    noEmailsMessage: () => void
    documentId: number
    selectedAction: IDocumentAction | undefined
    documentData?: any
    isManual?: boolean
    factserial? : string 
    factdate? : string
    factid? : number
}

const SendMailDialog = (props: Props) => {
    const { onClose, open, onSend, onError, invoiceNumber, aditionalInfo, noEmailsMessage, documentId, selectedAction, documentData, isManual, factserial, factdate, factid} = props
    const { supplier, loading: supplierLoading } = useGetSupplier({ ruc: props.supplier?.identification ?? "" })
    const { customer, loading: customerLoading } = useGetCustomer({ identification: documentData?.header?.supplier?.identification ?? "" })
    const { user } = React.useContext(AuthContext)
    const [emails, setEmails] = React.useState<string[]>([])
    const [message, setMessage] = React.useState<string>("")
    const [subject, setSubject] = React.useState<string>("")
    const [title, setTitle] = React.useState<string>("")
    const [reimbursementMessage, setReimbursementMessage] = React.useState<string>("")
    const [dialogLoading, setDialogLoading] = React.useState<boolean>(false)
    const [emailtemplates, setemailtemplates] = React.useState<ITemplateEmail[]>([])
    const [selectedTemplate, setSelectedTemplate] = React.useState<ITemplateEmail | undefined>(undefined);

    // Checkbox funcionalidad no enviar carta
    const [enviarCarta, setenviarCarta] = React.useState(true)
    const handleChangeEnvioCarta = (event: React.ChangeEvent<{}>, checked: boolean) => {
        setenviarCarta(checked);
    };

    const handleSend = async (e: React.FormEvent) => {
        e.preventDefault()
        /*if (emails.length === 0) {
            noEmailsMessage()
            return
        }*/
        try {
            setDialogLoading(true)
            if (selectedAction?.id === DOCUMENT_APPROVE_MANUALLY_ACTION_ID) {
                await approveDocumentService(
                    {
                        documentId,
                        userId: user?.userId,
                        statusId: DOCUMENT_STATES.inProcess
                    },
                    { documentData: {} }
                )
                await manageDocumentByIdService(documentId, emails.join(";"), subject, message, enviarCarta, documentId, Boolean(factid) ?  factid! : undefined, isManual ? documentData.rejectionCause : JSON.parse(aditionalInfo!)?.rejectionCause.description);
            } else if (selectedAction?.id === DOCUMENT_REJECT_MANUALLY_ACTION_ID) {
                await approveDocumentService(
                    {
                        documentId,
                        userId: user?.userId,
                        statusId: DOCUMENT_STATES.rejected
                    },
                    { documentData: {} }
                )
                await manageDocumentByIdService(documentId, emails.join(";"), subject, message, enviarCarta, documentId, Boolean(factid) ?  factid! : undefined, isManual ? documentData.rejectionCause : JSON.parse(aditionalInfo!)?.rejectionCause.description);
            } else if (selectedAction?.id === DOCUMENT_MANAGE_ACTION_ID) {
                await manageDocumentByIdService(documentId, emails.join(";"), subject, message);
            } else if (selectedAction?.id === DOCUMENT_SEND_REIMBURSEMENT_ACTION_ID) {
                await manageDocumentByIdService(documentId, emails.join(";"), subject, message);
            }
            onSend()
            onClose()
            setDialogLoading(false)
        } catch (error) {
            onError()
            setDialogLoading(false)
        }
    }

    const getEmails = React.useCallback(() => {
        const emailsArray: string[] = []
        if (customer) {
            customer.contacts?.forEach(
                contact => {
                    if (contact.email?.trim() !== "") {
                        contact.email?.split(";").forEach(
                            email => {
                                emailsArray.push(email.trim())
                            }
                        )
                    }
                }
            )
        }
        // if (supplier) {
        //     const emails:string[] = []
        //     if (supplier.email) {
        //         emails.push(supplier.email)
        //     }
        //     if (supplier.documentsEmail) {
        //         emails.push(supplier.documentsEmail)
        //     }
        //     if (supplier.accountExecutiveEmail) {
        //         emails.push(supplier.accountExecutiveEmail)
        //     }
        //     if (supplier.supervisorEmail) {
        //         emails.push(supplier.supervisorEmail)
        //     }
        //     return emails
        // } 
        return emailsArray
    }, [customer],
    )

    React.useEffect(() => {
        if (selectedAction?.id === DOCUMENT_APPROVE_MANUALLY_ACTION_ID) {
            //setTitle(selectedTemplate ? selectedTemplate.tittle : '')
            setMessage(selectedTemplate ? selectedTemplate.text : '')
            setSubject(selectedTemplate ? selectedTemplate.tittle:'')
        } else if (selectedAction?.id === DOCUMENT_REJECT_MANUALLY_ACTION_ID) {
            //setTitle(selectedTemplate ? selectedTemplate.tittle : '')
            setMessage(selectedTemplate ? selectedTemplate.text : '')
            setSubject(selectedTemplate ? selectedTemplate.tittle:'')
        } else if (selectedAction?.id === DOCUMENT_MANAGE_ACTION_ID) {
            setTitle("Gestionar Nota de Crédito")
            setMessage("Por favor responder el correo a grace.rodriguez.r@dhl.com")
            setSubject(`Gestión nota de crédito ${invoiceNumber ?? ""}`)
        } else if (selectedAction?.id === DOCUMENT_SEND_REIMBURSEMENT_ACTION_ID) {
            setTitle("Reembolso de Empleados")
            setMessage(reimbursementMessage)
            setSubject(`Reembolso de Empleados ${invoiceNumber ?? ""}`)
        }
    }, [selectedAction, invoiceNumber, reimbursementMessage])

    React.useEffect(() => {
        let data = `Hola,
            
Has generado una liquidación de reembolsos con el siguiente detalle:
            
DATE, LOCATIONS OR PLACES VISITED, DESCRIPTION, AMOUNT

`
        if (documentData !== undefined) {
            documentData.reimbursements?.forEach((reimbursement) => {
                let v1 = reimbursement.subtotalNoTaxesObject === undefined ? 0 : Number(reimbursement.subtotalNoTaxesObject)
                let v2 = reimbursement.subtotalRateZero === undefined ? 0 : Number(reimbursement.subtotalRateZero)
                let v3 = reimbursement.subtotalRateTwelve === undefined ? 0 : Number(reimbursement.subtotalRateTwelve)
                let v4 = reimbursement.iva === undefined ? 0 : Number(reimbursement.iva)
                let total = v1 + v2 + v3 + v4
                data += `${moment(reimbursement.emissionDate).format("YYYY-MM-DD")}, ${reimbursement.issuerName}, ${reimbursement.description}, ${total.toFixed(2)}
`
            })
            setReimbursementMessage(data)
        }
    }, [documentData])

    React.useEffect(() => {
        getTemplatesEmailService()
    },[])

    const getTemplatesEmailService = async () => {
        const result = await getTemplatesEmail();
        setemailtemplates(result)
    }

    const handleChange = (event) => {
        const causa = isManual ? documentData.rejectionCause : JSON.parse(aditionalInfo!)?.rejectionCause.description
        const selectedTemplateTitle = event.target.value;
        const seleccionado = emailtemplates.find((template) => template.tittle === selectedTemplateTitle);
        const factserialvalue = Boolean(factserial) ?  factserial! : 'NO ENCONTRADO'
        const facdatevalue = Boolean(factdate) ? new Date(factdate!).toISOString().split('T')[0] : 'NO ENCONTRADO'
        setSubject(seleccionado!.tittle.replace('{{FACTSERIAL}}',factserialvalue))
        setMessage(seleccionado!.text.replace('{{FACTSERIAL}}', factserialvalue)
                                     .replace('{{ERROR}}', causa)
                                     .replace('{{RETSERIAL}}', documentData?.header?.invoiceNumber)
                                     .replace('{{FACTFECHA}}', facdatevalue))
        setSelectedTemplate(selectedTemplate);
    };


    return (
        <Dialog open={open} onClose={onClose} maxWidth={"lg"}>
            <form onSubmit={handleSend}>
                <DialogTitle id="form-dialog-title" style={{ padding: '0px', margin: '0px' }}>{title}</DialogTitle>
                <DialogContent style={{ width: 800 }}>
                    <FormGroup>
                        <FormControlLabel
                            checked={enviarCarta}
                            onChange={handleChangeEnvioCarta}
                            control={<Checkbox defaultChecked />}
                            label="Enviar carta de correo a cliente" />
                    </FormGroup>
                    <Divider style={{ marginBottom: enviarCarta ? '20px' : '0' }} />
                    { enviarCarta &&
                    <>
                    <Autocomplete
                        freeSolo
                        multiple
                        options={getEmails()}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option}
                        fullWidth
                        value={emails}
                        openOnFocus
                        loading={supplier !== undefined ? supplierLoading : customerLoading}
                        loadingText={"cargando..."}
                        size="small"
                        ChipProps={{ size: "small" }}
                        onChange={(e, selectedValue, reason, detail) => {
                            if (reason === "create-option" && detail) {
                                if (isEmail(detail.option)) {
                                    setEmails(current => [...current, detail.option])
                                }
                                return
                            }
                            setEmails(selectedValue)
                        }}
                        renderOption={(option, { selected }) => {
                            return (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8, height: 50 }}
                                        checked={selected}
                                    />
                                    {option}
                                </React.Fragment>
                            )
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                label={FIELDS.recipients.name}
                            />
                        )}
                    />
                    {emailtemplates && selectedAction?.id !== DOCUMENT_SEND_REIMBURSEMENT_ACTION_ID && selectedAction?.id !== DOCUMENT_MANAGE_ACTION_ID && (
                        <FormControl fullWidth style={{ marginTop: '20px' }}>
                            <InputLabel id="demo-simple-select-label">Template</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedTemplate ? selectedTemplate?.tittle : ''}
                                label="Template"
                                variant='outlined'
                                onChange={handleChange}
                            >
                                {emailtemplates.map((template, index) => (
                                    <MenuItem key={index} value={template.tittle}>
                                        {template.tittle.replace('{{FACTSERIAL}}', '')} 
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={FIELDS.subject.name}
                        fullWidth
                        variant='outlined'
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        InputProps={{
                            readOnly:true
                         }}
                         inputProps={
                            {
                                style:{cursor:'context-menu'}
                            }}
                        style={{ marginTop: 15, marginBottom: 10 }}
                    />
                    <TextField
                        margin="dense"
                        id="name"
                        label={FIELDS.message.name}
                        fullWidth
                        multiline
                        rows={10}
                        variant='outlined'
                        value={message}
                        onChange={(e) => {
                           // setMessage(e.target.value)
                        }}
                        InputProps={{
                           readOnly:true
                        }}
                       inputProps={
                        {
                            style:{cursor:'context-menu'}
                        }
                       }
                    />
                    </>
                    }
                </DialogContent>
                <DialogActions style={{marginBottom:'10px'}}>
                    <Button
                        onClick={onClose}
                        size='small'
                        disabled={dialogLoading}
                    >
                        {"Cerrar"}
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant='contained'
                        size='small'
                        disableElevation
                        disabled={dialogLoading}
                    >
                        {enviarCarta ? "Enviar" : "Aceptar"}
                    </Button>
                </DialogActions>
            </form>
            <FixedLoadingIndicator loading={dialogLoading} />
        </Dialog>
    )
}

export default SendMailDialog
