import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  Typography,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { MAX_FILE_SIZE } from "lib"
import React, { useState } from "react"
import { IMessageConfig } from "types"
import uploadIcon from "../../assets/img/uploadIcon.png"
import { Alert } from "@material-ui/lab"
import { IUploadXMLResponse } from "types/response/UploadXMLModel.reponse"
import { wsUploadXMLMasiveService } from "api/APIUploadXML"

function UploadXMLPage() {
  const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({
    open: false,
    message: "",
    severity: "info",
  })
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
  const [uploadResponses, setUploadResponses] =
    useState<IUploadXMLResponse | null>(null)
  const [loading, setloading] = useState<boolean>(false)

  const eliminarArchivo = (index: number) => {
    const nuevosArchivos = [
      ...uploadedFiles.slice(0, index),
      ...uploadedFiles.slice(index + 1),
    ]
    setUploadedFiles(nuevosArchivos)
  }

  const handleUploadClick = () => {
    setUploadResponses(null)
    document.getElementById("approval-button-file")?.click()
  }

  const handleUpload = async () => {
    setUploadResponses(null)
    setloading(true)
    try {
      const response = await wsUploadXMLMasiveService(uploadedFiles)
      setUploadResponses(response)
      setUploadedFiles([])
    } catch (error) {
      setMessageConfig({
        open: true,
        message: `Error al subir los archivos: ${error}`,
        severity: "error",
      })
    }
    setloading(false)
  }

  const handleClear = () => {
    setUploadedFiles([])
    setUploadResponses(null)
  }
  console.log("uploadedFiles", uploadedFiles)
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          textAlign: "center",
          marginBottom: "20px",
          padding: "2px 30px",
          backgroundColor: "#6b6b6b",
          borderRadius: "5px",
        }}
        onClick={handleUploadClick}
      >
        <Typography variant="body1" style={{ color: "white" }}>
          CARGA DE ARCHIVOS DE AUTORIZACIÓN
        </Typography>
      </div>
      {!loading && (
        <div
          style={{
            width: "50%",
            border: "2px dashed #949494",
            padding: "20px",
            cursor: "pointer",
            borderRadius: "10px",
            textAlign: "center",
          }}
          onClick={handleUploadClick}
        >
          <img
            src={uploadIcon}
            alt="Upload"
            style={{
              width: "10%",
              display: "block",
              margin: "0 auto",
              cursor: "pointer",
            }}
          />
          <Typography
            variant="body1"
            style={{ marginTop: "10px", cursor: "pointer" }}
          >
            Click aquí para seleccionar archivos xml o zip
          </Typography>
        </div>
      )}
      {loading && (
        <div
          style={{
            width: "50%",
            border: "2px dashed #949494",
            padding: "20px",
            cursor: "pointer",
            borderRadius: "10px",
            textAlign: "center",
          }}
          onClick={handleUploadClick}
        >
          <CircularProgress style={{ marginTop: "20px" }} />
          <Typography
            variant="body1"
            style={{ marginTop: "10px", cursor: "pointer" }}
          >
            Subiendo archivos...
          </Typography>
        </div>
      )}
      <input
        accept=".xml,application/zip,application/x-rar-compressed"
        className="hidden"
        id="approval-button-file"
        type="file"
        style={{ cursor: "pointer" }}
        multiple={true}
        onChange={(e) => {
          if (e.target.files !== null && e.target.files.length > 0) {
            const archivosArray = Array.from(e.target.files)
            const archivosNoPermitidos = archivosArray.some((archivo) => {
              const nombreArchivo = archivo.name.toLowerCase()
              return !(
                nombreArchivo.endsWith(".xml") ||
                nombreArchivo.endsWith(".zip") ||
                nombreArchivo.endsWith(".rar")
              )
            })
            if (archivosNoPermitidos) {
              setMessageConfig({
                message: "Solo se permiten archivos XML y ZIP",
                open: true,
                severity: "error",
              })
              return
            }
            const archivoExcedeTamano = archivosArray.some(
              (archivo) => archivo.size > MAX_FILE_SIZE
            )
            if (archivoExcedeTamano) {
              setMessageConfig({
                message:
                  "El tamaño de uno o más archivos excede el máximo permitido",
                open: true,
                severity: "error",
              })
              return
            }
            setUploadedFiles((prevFiles) => [...prevFiles, ...archivosArray])
          }
        }}
      />
      {Boolean(uploadedFiles.length > 0 && !loading) && (
        <React.Fragment>
          <div
            style={{
              textAlign: "left",
              marginTop: "20px",
              padding: "2px 30px",
              backgroundColor: "#6b6b6b",
              borderRadius: "5px",
              width: "50%",
            }}
          >
            <Typography variant="body1" style={{ color: "white" }}>
              {uploadedFiles.length} archivos listos para la carga
            </Typography>
          </div>
          <div
            style={{
              width: "50%",
              marginTop: "20px",
              border: "2px dashed #949494",
              cursor: "pointer",
              borderRadius: "10px",
              textAlign: "center",
            }}
          >
            <List
              style={{
                width: "100%",
                height: "250px",
                maxHeight: "250px",
                overflowY: "auto",
              }}
            >
              {" "}
              {/* Establece una altura máxima y desplazamiento vertical automático */}
              {uploadedFiles.map((archivo, index) => (
                <div key={index}>
                  <ListItem>
                    <ListItemAvatar>
                      <IconButton onClick={() => eliminarArchivo(index)}>
                        <CloseIcon fontSize="large" style={{ color: "red" }} />
                      </IconButton>
                    </ListItemAvatar>
                    <ListItemText
                      primary={archivo.name}
                      secondary={`Archivo ${archivo.name
                        .split(".")
                        .pop()}`.toUpperCase()}
                      primaryTypographyProps={{ style: { color: "black" } }}
                      secondaryTypographyProps={{
                        style: {
                          color: "green",
                          fontSize: "10px",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </ListItem>
                  {index < uploadedFiles.length - 1 && (
                    <Divider variant="middle" />
                  )}
                </div>
              ))}
            </List>
          </div>
          <Box
            mt={2}
            display="flex"
            justifyContent="flex-end"
            sx={{ width: "50%" }}
          >
            <Button
              endIcon={<Icon>delete</Icon>}
              variant="outlined"
              color="primary"
              onClick={handleClear}
            >
              LIMPIAR
            </Button>
            <Button
              endIcon={<Icon>upload</Icon>}
              variant="contained"
              color="primary"
              onClick={() => {
                handleUpload()
              }}
              style={{ marginLeft: "10px" }}
            >
              SUBIR ARCHIVOS
            </Button>
          </Box>
        </React.Fragment>
      )}
      {uploadResponses && (
        <React.Fragment>
          <div
            style={{
              textAlign: "left",
              marginTop: "20px",
              padding: "2px 30px",
              backgroundColor: "#6b6b6b",
              borderRadius: "5px",
              width: "50%",
            }}
          >
            <Typography variant="body1" style={{ color: "white" }}>
              Respuestas del servicio:
            </Typography>
          </div>
          <div
            style={{
              width: "50%",
              marginTop: "20px",
              border: "2px dashed #949494",
              borderRadius: "10px",
              textAlign: "center",
            }}
          >
            <List
              style={{
                width: "100%",
                height: "230px",
                maxHeight: "230px",
                overflowY: "auto",
              }}
            >
              {" "}
              {/* Establece una altura máxima y desplazamiento vertical automático */}
              <ListItem>
                <ListItemText
                  primary={`Xmls Correctos: ${uploadResponses.correct.length}`}
                  primaryTypographyProps={{
                    style: {
                      color: "green",
                      fontWeight: "bold",
                      fontSize: "14px",
                    },
                  }}
                />
              </ListItem>
              <Divider variant="middle" />
              <ListItem>
                <ListItemText
                  primary={`Xml con Errores: ${uploadResponses.error.length}`}
                  primaryTypographyProps={{
                    style: {
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "14px",
                    },
                  }}
                />
              </ListItem>
              <Typography component="ul" style={{ margin: 0, padding: 0 }}>
                {uploadResponses.error.map((error, index) => (
                  <ListItem
                    key={index}
                    dense
                    style={{ padding: "2px 0", marginLeft: "15px" }}
                  >
                    <div style={{ display: "flex", width: "100%", gap: "4px" }}>
                      <ListItemText primary={error.xml} style={{ flex: 1 }} />
                      <ListItemText
                        style={{
                          color: "#BB3E51",
                          textAlign: "justify",
                          flex: 2,
                        }}
                        primary={error.message}
                      />
                    </div>
                  </ListItem>
                ))}
              </Typography>
            </List>
          </div>
          <Box
            mt={2}
            display="flex"
            justifyContent="flex-end"
            sx={{ width: "50%" }}
          >
            <Button
              endIcon={<Icon>upload</Icon>}
              variant="outlined"
              color="primary"
              onClick={handleClear}
            >
              CARGAR OTROS
            </Button>
          </Box>
        </React.Fragment>
      )}
      {uploadedFiles.length === 0 && (
        <Typography style={{ marginTop: "20px" }} variant="body1"></Typography>
      )}
      <Snackbar
        open={messageConfig.open}
        autoHideDuration={6000}
        onClose={() => setMessageConfig({ ...messageConfig, open: false })}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Alert
          variant="filled"
          onClose={() => setMessageConfig({ ...messageConfig, open: false })}
          severity={messageConfig.severity}
        >
          {messageConfig.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default UploadXMLPage
