import { Autocomplete } from "@material-ui/lab"
import { DocumentContext } from "context"
import React from "react"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import {
  Checkbox,
  Divider,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core"
import { FIELDS, PURCHASE_ORDER_PATH } from "lib"
import { KeyboardDatePicker } from "@material-ui/pickers"

interface Props {
  hideState?: boolean
  hideType?: boolean
  hideProcess?: boolean
  forEmission?: boolean
  forReception?: boolean
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const mediumInputStyle = { width: "49.5%", marginBottom: 15 }

const DocumentFilters = (props: Props) => {
  const { hideState, hideType, hideProcess, forEmission, forReception } = props
  const {
    documentTypes,
    documentFilters: filters,
    conditions,
    setFilters,
    historyTypes,
  } = React.useContext(DocumentContext)
  const allTypesSelected = React.useCallback(() => {
    return (
      documentTypes.filter((d) => d.isVisible).length ===
        filters.documentTypes.length && filters.documentTypes.length !== 0
    )
  }, [documentTypes, filters.documentTypes])

  const allConditionsSelected = React.useCallback(() => {
    return (
      conditions.filter((c) => !PURCHASE_ORDER_PATH.includes(c.operator))
        .length === filters.conditions.length && filters.conditions.length !== 0
    )
  }, [conditions, filters.conditions])

  const allHistoryTypesSelected = React.useCallback(() => {
    return (
      (forEmission
        ? historyTypes.filter((h) => h.forEmission)
        : forReception
        ? historyTypes.filter((h) => h.forReception)
        : historyTypes.filter((h) => h.forReception || h.forEmission)
      ).length === filters.historyTypes.length &&
      filters.historyTypes.length !== 0
    )
  }, [historyTypes, filters.historyTypes, forEmission, forReception])

  return (
    <div className="w-full flex flex-wrap pt-4 justify-between">
      <div className="w-full mr-1 mb-2">
        <Divider />
      </div>
      {!hideType && (
        <div
          style={
            hideType
              ? { ...mediumInputStyle, width: "100%" }
              : { ...mediumInputStyle }
          }
          className="flex items-start"
        >
          <Autocomplete
            multiple
            options={documentTypes.filter((d) => d.isVisible)}
            disableCloseOnSelect
            getOptionLabel={(option) => option.documentName}
            fullWidth
            value={filters.documentTypes}
            openOnFocus
            classes={{ input: "autocomplete-p-s" }}
            size="small"
            limitTags={2}
            ChipProps={{ size: "small", style: { maxWidth: "auto" } }}
            onChange={(_, selectedValue) => {
              setFilters({ ...filters, documentTypes: selectedValue })
            }}
            renderOption={(option) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8, height: 35 }}
                  checked={Boolean(
                    filters.documentTypes.find(
                      (d) => d.documentTypeId === option.documentTypeId
                    )
                  )}
                  size="small"
                />
                <Typography variant="body2">{option.documentName}</Typography>
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                label={FIELDS.documentType.name}
              />
            )}
          />
          <Tooltip
            arrow
            title={allTypesSelected() ? "Quitar todos" : "Selecionar todos"}
          >
            <IconButton
              onClick={() => {
                setFilters({
                  ...filters,
                  documentTypes: allTypesSelected()
                    ? []
                    : documentTypes.filter((d) => d.isVisible),
                })
              }}
            >
              <Icon fontSize="small">
                {allTypesSelected()
                  ? "indeterminate_check_box"
                  : "check_box_outline_blank"}
              </Icon>
            </IconButton>
          </Tooltip>
        </div>
      )}
      {!hideState && (
        <div
          style={
            hideState
              ? { ...mediumInputStyle, width: "100%" }
              : { ...mediumInputStyle }
          }
          className="flex items-start"
        >
          <Autocomplete
            multiple
            options={conditions.filter((c) => c.active)}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            fullWidth
            value={filters.conditions}
            limitTags={2}
            openOnFocus
            classes={{ input: "autocomplete-p-s" }}
            size="small"
            ChipProps={{ size: "small" }}
            onChange={(_, selectedValue) => {
              setFilters({ ...filters, conditions: selectedValue })
            }}
            renderOption={(option) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8, height: 35 }}
                  checked={Boolean(
                    filters.conditions.find(
                      (c) => c.conditionId === option.conditionId
                    )
                  )}
                  size="small"
                />
                <Typography variant="body2">{option.name}</Typography>
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                label={FIELDS.flowStatus.name}
                autoComplete="off"
              />
            )}
          />
          <Tooltip
            arrow
            title={
              allConditionsSelected() ? "Quitar todos" : "Selecionar todos"
            }
          >
            <IconButton
              onClick={() => {
                setFilters({
                  ...filters,
                  conditions: allConditionsSelected()
                    ? []
                    : conditions.filter(
                        (c) => !PURCHASE_ORDER_PATH.includes(c.operator)
                      ),
                })
              }}
            >
              <Icon fontSize="small">
                {allConditionsSelected()
                  ? "indeterminate_check_box"
                  : "check_box_outline_blank"}
              </Icon>
            </IconButton>
          </Tooltip>
        </div>
      )}

      {!hideProcess && (
        <div
          style={
            hideProcess
              ? { ...mediumInputStyle, width: "100%" }
              : { ...mediumInputStyle }
          }
          className="flex items-start"
        >
          {/* <div
                style={mediumInputStyle}
                className="flex items-start"
            > */}
          <Autocomplete
            multiple
            options={
              forEmission
                ? historyTypes.filter((h) => h.forEmission)
                : forReception
                ? historyTypes.filter((h) => h.forReception)
                : historyTypes.filter((h) => h.forReception || h.forEmission)
            }
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            value={filters.historyTypes}
            openOnFocus
            fullWidth
            classes={{ input: "autocomplete-p-s" }}
            size="small"
            ChipProps={{ size: "small", style: { maxWidth: "auto" } }}
            onChange={(_, selectedValue) => {
              setFilters({ ...filters, historyTypes: selectedValue })
            }}
            renderOption={(option) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8, height: 35 }}
                  checked={Boolean(
                    filters.historyTypes.find(
                      (h) => h.historyTypeId === option.historyTypeId
                    )
                  )}
                  size="small"
                />
                <Typography variant="body2">{option.name}</Typography>
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                label={FIELDS.processStatus.name}
              />
            )}
          />
          <Tooltip
            arrow
            title={
              allHistoryTypesSelected() ? "Quitar todos" : "Selecionar todos"
            }
          >
            <IconButton
              onClick={() => {
                setFilters({
                  ...filters,
                  historyTypes: allHistoryTypesSelected()
                    ? []
                    : forEmission
                    ? historyTypes.filter((h) => h.forEmission)
                    : forReception
                    ? historyTypes.filter((h) => h.forReception)
                    : historyTypes.filter(
                        (h) => h.forReception || h.forEmission
                      ),
                })
              }}
            >
              <Icon fontSize="small">
                {allHistoryTypesSelected()
                  ? "indeterminate_check_box"
                  : "check_box_outline_blank"}
              </Icon>
            </IconButton>
          </Tooltip>
        </div>
      )}
      <div
        className="flex justify-between items-start"
        style={{ ...mediumInputStyle }}
      >
        <KeyboardDatePicker
          value={filters.documentRange.start}
          onChange={(date) =>
            setFilters({
              ...filters,
              documentRange: {
                ...filters.documentRange,
                start: date?.toDateString() ?? "",
              },
            })
          }
          style={{ ...mediumInputStyle, marginTop: 0 }}
          emptyLabel={FIELDS.emptyDate.name}
          inputVariant="outlined"
          fullWidth
          maxDate={filters.documentRange.end}
          size="small"
          label={FIELDS.startDate.name}
          variant="inline"
          format="dd/MM/yyyy"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon fontSize="small" color="action">
                  event
                </Icon>
              </InputAdornment>
            ),
          }}
          helperText={FIELDS.emissionDate.name}
        />
        <KeyboardDatePicker
          value={filters.documentRange.end}
          minDate={filters.documentRange.start}
          emptyLabel={FIELDS.emptyDate.name}
          onChange={(date) =>
            setFilters({
              ...filters,
              documentRange: {
                ...filters.documentRange,
                end: date?.toDateString() ?? "",
              },
            })
          }
          style={{ ...mediumInputStyle, marginTop: 0, marginLeft: "1%" }}
          inputVariant="outlined"
          fullWidth
          size="small"
          label={FIELDS.endDate.name}
          variant="inline"
          format="dd/MM/yyyy"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon fontSize="small" color="action">
                  event
                </Icon>
              </InputAdornment>
            ),
          }}
        />
        <Tooltip arrow title={"Borrar fechas"}>
          <IconButton
            onClick={() => {
              setFilters({
                ...filters,
                documentRange: { start: null, end: null },
              })
            }}
          >
            <Icon fontSize="small">{"clear_all"}</Icon>
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}

export default DocumentFilters
