export const BANKS = [
  {
    value: 'Bolivariano',
  },
  {
    value: 'Pacífico',
  },
  {
    value: 'Pichincha',
  },
  {
    value: 'Produbanco',
  },
  {
    value: 'Guayaquil',
  },
  {
    value: 'Citibank',
  },
  {
    value: 'Banco del Austro',
  },
  {
    value: 'Banco General Rumiñahui',
  },
  {
    value: 'Banco Internacional',
  },
  {
    value: 'Banco de Machala',
  }
]

export const BANK_ACCOUNTS = [
  {
    value: 'Ahorro',
  },
  {
    value: 'Corriente',
  },
  {
    value: 'Otros',
  },
]