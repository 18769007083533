import { Button, Icon, IconButton, Popover, Typography } from '@material-ui/core'
import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'
import React from 'react'

interface Props {
    openingDate?: Date
    closingDate?: Date
    selectedMonth: number
    selectedYear: number
    onMonthChange: (selected: number) => Promise<void>
    disabled: boolean
    onOpeningChange: (date: Date) => void
    onClosingChange: (date: Date) => void
    onOpenMonth: () => void
}

const widthStyle: React.CSSProperties = { width: 180 }

type ChangeTypes = "opening" | "closing"

const DisabledDay = ({ day, dayInCurrentMonth }: { day: MaterialUiPickersDate, dayInCurrentMonth: boolean }) => {
    return (
        <IconButton
            className={`MuiButtonBase-root MuiIconButton-root MuiPickersDay-day ${!dayInCurrentMonth ? "MuiPickersDay-hidden" : ""}`}
            disableRipple
            style={{ cursor: "default", width: 36, height: 36 }}
            disabled
        >
            <span className='MuiIconButton-label'>
                <Typography color="textSecondary" variant="body2">
                    {day?.getDate()}
                </Typography>
            </span>
        </IconButton>
    )
}

const NonSelectedDateDay = ({ day, dayInCurrentMonth }: { day: MaterialUiPickersDate, dayInCurrentMonth: boolean }) => {
    return (
        <IconButton
            className={`MuiButtonBase-root MuiIconButton-root MuiPickersDay-day ${!dayInCurrentMonth ? "MuiPickersDay-hidden" : ""}`}
            style={{ width: 36, height: 36 }}

        >
            <span className='MuiIconButton-label'>
                <Typography variant="body2">
                    {day?.getDate()}
                </Typography>
            </span>
        </IconButton>
    )
}

const OpeningToolbar = ({ openingDate, selectedMonth, selectedYear, onOpening: handleOpening }: { openingDate: Date | undefined, selectedMonth: number, selectedYear: number, onOpening: () => void }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    return (
        <div
            className='h-full py-4 px-6 h-auto flex flex-col'
        >
            <Typography
                className='opacity-75 font-bold uppercase'
                variant="body1"
                style={widthStyle}
            >
                {"Fecha de apertura"}
            </Typography>
            {
                openingDate ?
                    <div className='flex items-center' style={widthStyle}>
                        <Icon className="my-4 mr-2" color="action">
                            event_available
                        </Icon>
                        <KeyboardDatePicker
                            value={openingDate}
                            onChange={date => { }}
                            disabled={true}
                            format="dd-MM-yyyy"
                            KeyboardButtonProps={{ style: { display: "none" } }}
                            inputVariant='outlined'
                            size='small'
                            placeholder='dd-mm-yyyy'
                            FormHelperTextProps={{ style: { display: 'none' } }}
                        />
                    </div>
                    :
                    <Button
                        endIcon={<Icon fontSize="small">event_available</Icon>}
                        style={{ marginTop: 10 }}
                        color='primary'
                        disableElevation
                        variant='contained'
                        disabled={new Date().getMonth() + 1 !== selectedMonth || selectedYear !== new Date().getFullYear()}
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                    >
                        {"Abrir mes"}
                    </Button>
            }
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className='flex flex-col items-center justify-center px-4 pb-2 pt-3'>
                    <Typography>{`¿Estás seguro de abrir el mes en el dia ${moment((new Date()).toISOString()).format('DD-MM-YYYY').toString()}?`}</Typography>
                    <div className='flex items-cenyer py-2'>
                        <Button
                            onClick={() => {
                                setAnchorEl(null)
                                handleOpening()
                            }}
                            color="primary"
                            variant="contained"
                            disableElevation
                        >
                            {"Si"}
                        </Button>
                        <Button
                            variant="contained"
                            disableElevation
                            onClick={() => setAnchorEl(null)}
                            style={{ marginLeft: 8 }}
                        >
                            {"No"}
                        </Button>
                    </div>
                </div>
            </Popover>
        </div>
    )
}

const ClosingToolbar = ({ closingDate, disabled, selectedMonth, selectedYear, onChange: handleChange }: { closingDate: Date | undefined, disabled: boolean, selectedMonth: number, selectedYear: number, onChange: (date: MaterialUiPickersDate, type: ChangeTypes) => void }) => {

    const [internalDate, setInternalDate] = React.useState<Date | null>(closingDate ?? null)
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        handleBlur()
    }
    const handleBlur = () => {
        const date = new Date(internalDate ?? "") 
        if (isNaN(date.getTime())) {
            setInternalDate(closingDate ?? null)
            return
        } else {
            if (date.getMonth() !== selectedMonth - 1 || date.getFullYear() !== selectedYear) {
                setInternalDate(closingDate ?? null)
                return
            } else {
                handleChange(date, "closing")
            }
        }
    }

    return (

        <div
            className='h-full py-4 px-6 h-auto flex flex-col'
        >
            <Typography
                className='opacity-75 font-bold uppercase'
                variant="body1"
                style={widthStyle}
            >
                {"Fecha de cierre"}
            </Typography>
            <form onSubmit={handleSubmit} style={widthStyle} className='flex items-center'>
                <Icon className="my-4 mr-2" color="action">
                    event_busy
                </Icon>
                <KeyboardDatePicker
                    value={internalDate}
                    onChange={date => setInternalDate(date)}
                    disabled={disabled}
                    format="dd-MM-yyyy"
                    fullWidth
                    KeyboardButtonProps={{ style: { display: "none" } }}
                    inputVariant='outlined'
                    size='small'
                    placeholder='dd-mm-yyyy'
                    FormHelperTextProps={{ style: { display: 'none' } }}
                    onBlur={handleBlur}
                />
            </form>
        </div>
    )
}

const StartEndStaticPicker = (props: Props) => {
    const { closingDate, openingDate, onMonthChange, selectedMonth, selectedYear, disabled, onOpeningChange, onClosingChange, onOpenMonth } = props

    const handleMonthChange = async (date: MaterialUiPickersDate) => {
        if (date !== null) {
            onMonthChange(date.getMonth())
        }
    }

    const handleDateChange = (date: MaterialUiPickersDate, type: ChangeTypes) => {
        if (type === "opening") {
            onOpeningChange(new Date(date?.getTime() ?? ""))
        }
        if (type === "closing") {
            onClosingChange(new Date(date?.getTime() ?? ""))
        }
    }

    return (
        <div className='flex flex-grow flex-col items-center px-4 justify-evenly'>
            <DatePicker
                autoOk
                orientation="landscape"
                variant="static"
                openTo="date"
                disabled={true}
                value={openingDate ?? null}
                onMonthChange={handleMonthChange}
                renderDay={!Boolean(openingDate) ? (day, _, dayInCurrentMonth) => <DisabledDay day={day} dayInCurrentMonth={dayInCurrentMonth} /> : undefined}
                initialFocusedDate={new Date(`${selectedMonth}/01/${selectedYear}`)}
                onChange={(date) => { }}
                ToolbarComponent={() => <OpeningToolbar selectedYear={selectedYear} onOpening={onOpenMonth} selectedMonth={selectedMonth} openingDate={openingDate} />}
            />
            <DatePicker
                autoOk
                orientation="landscape"
                variant="static"
                openTo="date"
                renderDay={disabled ? (day, _, dayInCurrentMonth) => <DisabledDay day={day} dayInCurrentMonth={dayInCurrentMonth} /> : closingDate === null ? (day, _, dayInCurrentMonth) => <NonSelectedDateDay day={day} dayInCurrentMonth={dayInCurrentMonth} /> : undefined}
                disabled={disabled}
                value={closingDate ?? null}
                initialFocusedDate={new Date(`${selectedMonth}/01/${selectedYear}`)}
                onChange={(date) => handleDateChange(date, "closing")}
                onMonthChange={handleMonthChange}
                ToolbarComponent={() => <ClosingToolbar selectedYear={selectedYear} onChange={handleDateChange} selectedMonth={selectedMonth} disabled={disabled} closingDate={closingDate} />}
            />
        </div>
    )
}

export default StartEndStaticPicker
