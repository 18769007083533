import React, { useEffect } from "react"
import {
    Button,
    Collapse,
    Icon,
    IconButton,
    LinearProgress,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tooltip,
} from "@material-ui/core"
import {
    DocumentPicker,
    FixedLoadingIndicator,
    LabelDisplayedRows,
    ServerErrorHandler,
    Search,
    DocumentFilters,
    RefundsCell,
    CountDocuments,
} from "components"
import {
    DOCUMENTS_TABLE_COLUMNS,
    MEDIUM_ROWS_PER_PAGINATION_OPTIONS,
    DOCUMENT_PATH,
    ROOT_TITLE,
    DOCUMENT_TITLE,
    FIELDS,
    DOCUMENT_VIEW_PATH,
    getDocumentByIdService,
    DOCUMENT_TYPES,
    NEW_DOCUMENT_PATH,
    DOCUMENT_STATES,
    DOCUMENT_TYPE_CODES,
    FINISHED_PRINTED_INVOICES_COLUMNS,
    getPurchaseOrderByIdService,
    PURCHASE_ORDER_PATH,
    PURCHASE_ORDER_VIEW_PATH,
    SINGLE_COMPANY_DOCUMENTS_TABLE_COLUMNS,
    COUPA_STATES,
    queueDocumentByIdService,
    COUPA_QUEUEABLE_DOCUMENT_STATUS,
    DOCUMENT_TYPE_IDS,
    SRI_AUTHORIZED_ID,
    approveDocumentService,
    RETENTION_PATH,
    queueResendDocumentByIdService,
    MY_DOCUMENTS_TABLE_COLUMNS,
} from "lib"
import { useHistory } from "react-router"
import { IMessageConfig, IDocument, IDocumentAdditionalInfo } from "types"
import { AuthContext, DocumentContext, ManagementContext } from "context"
import { Alert, AlertTitle } from "@material-ui/lab"
import { Helmet } from "react-helmet"
import moment from 'moment'
import { wsReenviarCorreoAprobador } from "api/APIDraft"

interface Props {
    initialCode?: string
    initialState?: number
    initialHistoryType?: number
    typePath?: string
    redirectOnApprove?: string
    redirectOnReject?: string
    hideState?: boolean
    hideType?: boolean
    hideProcess?: boolean
    forEmission?: boolean
    forReception?: boolean
    showCounts?: boolean
    issuingCompany?: boolean
    receivingCompany?: boolean
    countPath?: string
    createRetentionOnly?: boolean
}

const MyDocumentsDashboard = (props: Props) => {
    const {
        initialCode,
        typePath,
        issuingCompany,
        initialState,
        redirectOnApprove,
        hideState,
        hideType,
        hideProcess,
        forEmission,
        forReception,
        showCounts,
        redirectOnReject,
        initialHistoryType,
        receivingCompany,
        countPath,
        createRetentionOnly
    } = props
    const {
        documents,
        company,
        conditions,
        documentFilters: filters,
        setFilters,
        documentsModel,
        documentTypes,
        getDocumentsByFilters,
        totalDocuments,
        getNextDocumentsByFilters,
        getDocumentsByInitialFilters,
        historyTypes
    } = React.useContext(DocumentContext)
    const { user } = React.useContext(AuthContext)
    const { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage, } = documentsModel
    const history = useHistory()
    const { refreshIdentificationTypes, refreshTaxes, refreshTaxTypes, identificationTypes, taxTypes, taxes } = React.useContext(ManagementContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info", })
    const [openPicker, setOpenPicker] = React.useState<boolean>(false)
    const [openFilters, setOpenFilters] = React.useState<boolean>(false)

    const handleFilter = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setLoading(true)
            await getDocumentsByFilters(true, user?.userId)
            handleChangePage(undefined, 0)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(true)
        }
    }
    const handleNewPage = async (pageNumber: number, offset: number) => {
        try {
            setLoading(true)
            await getNextDocumentsByFilters(offset, pageNumber, true, user?.userId)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(true)
        }
    }
    const handleSelect = async (document: IDocument) => {
        try {
            setLoading(true)
            if (identificationTypes.length === 0) { refreshIdentificationTypes() }
            if (taxTypes.length === 0) { refreshTaxTypes() }
            if (taxes.length === 0) { refreshTaxes() }
            const response = await getDocumentByIdService(document.documentId)
            history.push({
                state: { document: response, redirectOnApprove, redirectOnReject },
                pathname: `${DOCUMENT_PATH}${DOCUMENT_VIEW_PATH}/${document.documentId}`
            })
            setLoading(false)
        } catch (error) {
            console.log(error)
            setMessageConfig({ open: true, message: "No se pudo obtener información del documento selecionado...", severity: "error" })
            setLoading(false)
        }
    }
    const handleClearFilters = async () => {
        try {
            setLoading(true)
            await getDocumentsByInitialFilters(initialCode, initialState, issuingCompany ? company?.identification : undefined, initialHistoryType, undefined, true, user?.userId)
            handleChangePage(undefined, 0)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(true)
        }
    }
    const getModel = React.useCallback((): any[] => { return (initialLoading ? [] : documents) }, [documents, initialLoading])
    const handleNewDocument = async () => {
        if (typePath) {
            const selected = DOCUMENT_TYPES.find(d => d.type === typePath.replace("/", ""))
            if (selected) {
                history.push({
                    pathname: `${DOCUMENT_PATH}${NEW_DOCUMENT_PATH}/${selected.type}`,
                    state: { type: selected }
                })
                return
            }
        }
        if (createRetentionOnly) {
            history.push({
                pathname: `${RETENTION_PATH}${NEW_DOCUMENT_PATH}`,
            })
            return
        }
        setOpenPicker(true)
    }
    const isFinishedInvoice = React.useCallback(() => {
        return initialState === DOCUMENT_STATES.finished && (initialCode === DOCUMENT_TYPE_CODES.printedInvoice || initialCode === DOCUMENT_TYPE_CODES.electronicInvoice)
    }, [initialState, initialCode])
    const isRetentionReceipt = React.useCallback(() => {
        return initialCode === DOCUMENT_TYPE_CODES.retentionReceipt
    }, [initialCode])
    const isFinishedForeignExpensesLiquidation = React.useCallback(() => {
        return initialCode === DOCUMENT_TYPE_CODES.foreignExpensesLiquidation && initialState === DOCUMENT_STATES.finished
    }, [initialCode, initialState])
    const isReimbursementInvoice = React.useCallback(() => {
        return initialCode === DOCUMENT_TYPE_CODES.reimbursementInvoice
    }, [initialCode])
    const isForeignExpensesLiquidationNotFinished = React.useCallback(() => {
        return initialCode === DOCUMENT_TYPE_CODES.foreignExpensesLiquidation && initialState !== DOCUMENT_STATES.finished
    }, [initialCode, initialState])
    const isPurchaseSettlement = React.useCallback(() => {
        return initialCode === DOCUMENT_TYPE_CODES.purchaseSettlement
    }, [initialCode])
    const applyingFilters = React.useCallback((): boolean => {
        return Boolean((
            filters.conditions.length > 0
            || filters.documentTypes.length > 0
            || Boolean(filters.rucOrName) || Boolean(filters.serialNumber)
            || filters.documentRange.start !== null
            || filters.documentRange.end !== null
            || filters.receptionRange.start !== null
            || filters.receptionRange.end !== null
            || filters.rucOrNameReceptor !== null
        ))
    }, [filters])

    const handlePurchaseOrder = async (id: number) => {
        try {
            setLoading(true)
            const purchaseOrder = await getPurchaseOrderByIdService(id)
            history.push({
                state: { purchaseOrder },
                pathname: `${PURCHASE_ORDER_PATH}${PURCHASE_ORDER_VIEW_PATH}/${purchaseOrder.document.documentId}`
            })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo obtener información del documento selecionado...", severity: "error" })
        }
    }

    const handleQueue = async (id: number, noCoupaMessage?: boolean) => {
        try {
            setLoading(true)
            await queueDocumentByIdService(id)
            setLoading(false)
            setMessageConfig({ open: true, message: noCoupaMessage ? "El documento se encuentra en proceso de ser autorizado" : "El documento selecionado se encuentra en proceso de enviar a COUPA!", severity: "success" })
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo enviar a COUPA el documento seleccionado...", severity: "error" })
        }
    }

    const handleResendMail = async (id: number) => {
        try {
            setLoading(true)
            await wsReenviarCorreoAprobador(id)
            setLoading(false)
            setMessageConfig({ open: true, message: "El correo se reenvio con exito!", severity: "success" })
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "Ocurrio un error en el proceso", severity: "error" })
        }
    }

    const handleQueueResendSRI = async (id: number, noCoupaMessage?: boolean) => {
        try {
            setLoading(true)
            await queueResendDocumentByIdService(id)
            setLoading(false)
            setMessageConfig({ open: true, message: "Documento enviado al SRI", severity: "success" })
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo enviar el documento seleccionado...", severity: "error" })
        }
    }

    React.useEffect(() => {
        const initScreen = async () => {
            try {
                if (history.action === "REPLACE" || history.action === "PUSH") {
                    setInitialLoading(true)
                    await getDocumentsByInitialFilters(initialCode, initialState, issuingCompany ? company?.identification : undefined, initialHistoryType, receivingCompany ? company?.identification : undefined, true, user?.userId)
                    handleChangePage(undefined, 0)
                    setInitialLoading(false)
                }
            } catch (error) {
                setInitialLoading(false)
                setError(true)
            }
        }
        initScreen()
    }, [initialCode, getDocumentsByInitialFilters, history, initialState, handleChangePage, issuingCompany, company, initialHistoryType, receivingCompany])
    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - Mis Documentos`}</title>
            </Helmet>
            <div className="flex items-center">
                <form className="flex flex-col w-full" onSubmit={handleFilter}>
                    <div className="flex items-center justify-between w-full">
                        <div className="flex">
                            <Search
                                onChange={(e) => setFilters({ ...filters, serialNumber: e.target.value })}
                                query={filters.serialNumber ?? ""}
                                onClear={() => setFilters({ ...filters, serialNumber: "" })}
                                placeholer="Buscar por número de serie..."
                                width={250}
                            />
                            {
                                !issuingCompany ?
                                    <div className="ml-2">
                                        <Search
                                            onChange={(e) => setFilters({ ...filters, rucOrName: e.target.value })}
                                            query={filters.rucOrName}
                                            onClear={() => setFilters({ ...filters, rucOrName: "" })}
                                            placeholer="RUC o nombre..."
                                            width={250}
                                        />
                                    </div>
                                    :
                                    <div className="ml-2">
                                        <Search
                                            onChange={(e) => setFilters({ ...filters, rucOrNameReceptor: e.target.value })}
                                            query={filters.rucOrNameReceptor}
                                            onClear={() => setFilters({ ...filters, rucOrNameReceptor: "" })}
                                            placeholer="RUC o nombre de receptor..."
                                            width={250}
                                        />
                                    </div>
                            }
                            <Button
                                size="small"
                                style={{ marginLeft: 20 }}
                                disableElevation
                                color={applyingFilters() ? "primary" : undefined}
                                onClick={() => setOpenFilters(current => !current)}
                            >
                                <Icon fontSize="small" style={{ marginRight: 5 }}>{openFilters ? "expand_less" : "expand_more"}</Icon>
                                <span className="pr-2">{`${openFilters ? "Menos filtros" : "Más filtros"}`}</span>
                            </Button>
                            {
                                applyingFilters() &&
                                <Tooltip
                                    arrow
                                    title="Resetear filtros por defecto"
                                >
                                    <IconButton
                                        size="small"
                                        onClick={handleClearFilters}
                                        disabled={loading || initialLoading}
                                    >
                                        <Icon fontSize="small" className="mx-2">
                                            restart_alt
                                        </Icon>
                                    </IconButton>
                                </Tooltip>
                            }
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginLeft: 20 }}
                                disableElevation
                                type="submit"
                                disabled={loading || initialLoading}
                            >
                                {"Aplicar"}
                            </Button>
                        </div>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 20 }}
                            disableElevation
                            onClick={handleNewDocument}
                        >
                            <Icon fontSize="small" style={{ marginRight: 5 }}>add</Icon>
                            {"Nuevo documento"}
                        </Button>
                    </div>
                    <Collapse in={openFilters}>
                        <DocumentFilters
                            hideState={hideState}
                            hideType={hideType}
                            hideProcess={hideProcess}
                            forEmission={forEmission}
                            forReception={forReception}
                        />
                    </Collapse>
                </form>
            </div>
            <div className="flex h-full flex-col mt-4 overflow-hidden">
                {
                    initialLoading &&
                    <LinearProgress />
                }
                <div className="flex flex-grow overflow-auto">
                    <TableContainer>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {(MY_DOCUMENTS_TABLE_COLUMNS).map((headCell) => (
                                        (
                                            (createRetentionOnly && headCell.id !== FIELDS.processStatus.key) || !createRetentionOnly
                                        ) &&
                                        <TableCell
                                            key={headCell.id}
                                            align={"left"}
                                            padding={"default"}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : "asc"}
                                                onClick={createSortHandler(headCell.id as any)}
                                                style={{ fontSize: "0.85em", width: (headCell.id === FIELDS.companyName.key || headCell.id === FIELDS.receiverName.key) ? 320 : (headCell.id === FIELDS.flowStatus.key || headCell.id === FIELDS.processStatus.key) ? 100 : "max-content" }}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className="hidden">
                                                        {order === "desc"
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}

                                    <TableCell padding={"default"} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rows(getModel()) as IDocument[]).map(
                                    (row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.documentId.toString()}
                                                className="cursor-pointer"
                                                onClick={(loading || initialLoading) ? undefined : () => handleSelect(row)}
                                            >
                                                <TableCell className="truncate" component="th" scope="row" padding="none">
                                                    {documentTypes.find(d => d.documentTypeId === row.documentTypeId)?.documentName}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {row.serialNumber}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {moment(new Date(row.documentDate)).add(1, "hour").format('DD-MM-YYYY').toString()}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {issuingCompany ? row.receiverRuc : row.companyRuc}
                                                </TableCell>
                                                <TableCell className="truncate" padding="default" align="left">
                                                    {issuingCompany ? row.receiverName : row.companyName}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {conditions.find(c => c.conditionId === row.flowStatus)?.name}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {row.creador}
                                                </TableCell>
                                                <TableCell padding="default" align="left">
                                                    {row.aprovador}
                                                </TableCell>
                                                {row.aprovadorid !== user?.userId && row.flowStatus == 5 ?
                                                    <Tooltip
                                                        enterNextDelay={500}
                                                        classes={{
                                                            arrow: row.processStatus === COUPA_STATES.error ? "warning-arrow-tooltip" : "info-arrow-tooltip",
                                                            tooltip: row.processStatus === COUPA_STATES.error ? "warning-tooltip" : "info-tooltip"
                                                        }}
                                                        title={
                                                            <Alert
                                                                severity={"info"}
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                }}
                                                            >
                                                                <AlertTitle style={{ fontWeight: 900, fontSize: "0.8em", letterSpacing: 1, marginBottom: 10 }}>{'¿Reenviar correo a: ' + row.aprovador + '?'}</AlertTitle>
                                                                <Button
                                                                    color={row.processStatus === COUPA_STATES.error ? "secondary" : undefined}
                                                                    variant="contained"
                                                                    size="small"
                                                                    style={{ marginTop: 10 }}
                                                                    onClick={(e) => {
                                                                        handleResendMail(row.documentId)
                                                                    }}
                                                                    disabled={loading}
                                                                    disableElevation
                                                                >
                                                                    {"Volver a enviar"}
                                                                </Button>

                                                            </Alert>
                                                        }
                                                        interactive
                                                        arrow
                                                    >
                                                        <TableCell padding="default" align="left">
                                                            {row.aprovadorid !== user?.userId && row.flowStatus == 5 ? 'Reenviar Correo' : '-'}
                                                        </TableCell>
                                                    </Tooltip>
                                                    :
                                                    <Tooltip
                                                        enterNextDelay={500}
                                                        classes={{
                                                            arrow: row.processStatus === COUPA_STATES.error ? "warning-arrow-tooltip" : "info-arrow-tooltip",
                                                            tooltip: row.processStatus === COUPA_STATES.error ? "warning-tooltip" : "info-tooltip"
                                                        }}
                                                        title={
                                                            <Alert
                                                                severity={"info"}
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                }}
                                                            >
                                                                <AlertTitle style={{ fontWeight: 900, fontSize: "0.8em", letterSpacing: 1, marginBottom: 10 }}>
                                                                    {'Documento finalizado'}
                                                                </AlertTitle>
                                                            </Alert>
                                                        }
                                                        interactive
                                                        arrow
                                                    >
                                                        <TableCell padding="default" align="left">
                                                            {row.aprovadorid !== user?.userId && row.flowStatus == 5 ? 'Reenviar Correo' : '-'}
                                                        </TableCell>
                                                    </Tooltip>}
                                            </TableRow>
                                        )
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    <TablePagination
                        rowsPerPageOptions={MEDIUM_ROWS_PER_PAGINATION_OPTIONS}
                        component="div"
                        count={totalDocuments}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={async (event, pageNumber) => {
                            if (pageNumber > page && ((pageNumber + 1) * rowsPerPage) > getModel().length && getModel().length < totalDocuments) {
                                await handleNewPage(pageNumber, rowsPerPage)
                            }
                            handleChangePage(event, pageNumber)
                        }}
                        onRowsPerPageChange={async (event) => {
                            if (parseInt(event.target.value, 10) > rowsPerPage && parseInt(event.target.value, 10) > getModel().length && getModel().length < totalDocuments) {
                                await handleNewPage(page, parseInt(event.target.value, 10))
                            }
                            handleChangeRowsPerPage(event as any)
                        }}
                        labelRowsPerPage="Documentos por página"
                        labelDisplayedRows={LabelDisplayedRows}
                    />
                </div>
            </div >
            <Snackbar
                open={messageConfig.open}
                autoHideDuration={6000}
                onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Alert
                    variant="filled"
                    onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                    severity={messageConfig.severity}
                >
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <FixedLoadingIndicator loading={loading} />
            <DocumentPicker
                open={openPicker}
                onClose={() => setOpenPicker(false)}
            />
            <ServerErrorHandler
                error={error}
                onSuccess={() => setError(false)}
                tryAgain={getDocumentsByInitialFilters}
            />
        </Paper >
    )
}

export default MyDocumentsDashboard