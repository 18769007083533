import React from 'react'
import { Button, Icon, IconButton, Menu, MenuItem, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip } from '@material-ui/core'
import { ConfirmDialog, SupplierForm, Search, ServerErrorHandler, LabelDisplayedRows, FixedLoadingIndicator, FullSizeDialog, TaxPlanSupplier, FormDialog } from 'components'
import { useInputValue, useTableModel } from 'hooks'
import { SUPPLIER_PATH, NEW_PATH, RESOURCES_PATH, SUPPPLIER_TABLE_COLUMNS, EDIT_PATH, getSuppliersFileService, deleteSupplierService, SMALL_ROWS_PER_PAGINATION_OPTIONS, SUPPLIER_FILE_PATH, ROOT_TITLE, SUPPLIER_TITLE, getTaxPlanSupplierBySupplierIdService, getSupplierFileByIdService } from 'lib'
import { useHistory, useLocation } from 'react-router'
import { IMessageConfig, ISupplier, SupplierStateFilter, ITaxPlanSupplier, ISupplierFile, IIdentificationType, IAccountType } from 'types'
import { ManagementContext } from 'context'
import { Alert } from '@material-ui/lab'
import { Helmet } from 'react-helmet'

const Row = (props: { row: ISupplier, index: number, setActionsAnchorEl: React.Dispatch<null | HTMLElement>, setSelected: React.Dispatch<any>, identificationTypes: IIdentificationType[], accountTypes: IAccountType[] }) => {
    const { row, index, setSelected, setActionsAnchorEl, accountTypes, identificationTypes } = props
    const [taxPlanCount, setTaxPlanCount] = React.useState<number | undefined>(undefined)
    React.useEffect(() => {
        const init = async () => {
            const result: any[] = await getTaxPlanSupplierBySupplierIdService(row.supplierId)
            if (row.supplierId === 628) {
                console.log(result)                
            }
            setTaxPlanCount(result.length)
        }
        init()
    }, [row.supplierId])
    return (
        <TableRow
            hover
            tabIndex={-1}
            key={row.supplierId + index.toString()}
        >
            <Tooltip placement="top" arrow title={row.comercialName}>
                <TableCell className="truncate" style={{ maxWidth: 180, paddingRight: 20 }} component="th" scope="row" padding="none">{row.comercialName}</TableCell>
            </Tooltip>
            <TableCell padding="none" align="left">{row.identification}</TableCell>
            <TableCell padding="none" align="left">{identificationTypes.find(i => i.identificationTypeId.toString() === row.identificationType.toString())?.name}</TableCell>
            <TableCell padding="none" align="left">{accountTypes.find(a => a.accountTypeId.toString() === row.keepAccounting)?.description}</TableCell>
            <TableCell className="truncate" style={{ maxWidth: 125, paddingRight: 25 }} padding="none" align="left">{row.requirePo ? "SI" : "NO"}</TableCell>
            <TableCell padding="none" align="left"><span>{taxPlanCount !== undefined ? taxPlanCount : ""}</span></TableCell>
            <TableCell padding="none" align="left"><span className={row.active ? "active-state-label" : "inactive-state-label"}>{row.active ? "Activo" : "Inactivo"}</span></TableCell>
            <TableCell align="left">
                <IconButton onClick={(e) => { setActionsAnchorEl(e.currentTarget); setSelected(row) }} size="small">
                    <Icon fontSize="small">more_vert</Icon>
                </IconButton>
            </TableCell>
        </TableRow>
    )
}


const Suppliers = () => {
    const { suppliers, refreshSuppliers, refreshAccountTypes, refreshIdentificationTypes, refreshTaxPlans, accountTypes, identificationTypes } = React.useContext(ManagementContext)
    const [filter, setFilter] = React.useState<SupplierStateFilter>("active")
    const { value: query, clearValue: handleClear, onChange: handleChange } = useInputValue()
    const { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage } = useTableModel()
    const location = useLocation()
    const history = useHistory()
    const [selected, setSelected] = React.useState<any | undefined>(undefined)
    const [actionsAnchorEl, setActionsAnchorEl] = React.useState<null | HTMLElement>(null)
    const [deleteId, setDeleteId] = React.useState<number>(-1)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [openTaxPlan, setOpenTaxPlan] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)
    const [taxPlanSupplier, setTaxPlanSupplier] = React.useState<ITaxPlanSupplier[]>([])
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const getModel = React.useCallback((): any[] => {
        const src = filter === "all" ? [...suppliers] : [...suppliers].filter(o => o.active === (filter === "active") ? true : false)
        if (Boolean(query)) {
            return src.filter((s: any) => {
                return s.companyName?.toUpperCase().includes(query.toUpperCase()) || s.identification?.toUpperCase().includes(query.toUpperCase())
                    || s.comercialName?.toUpperCase().includes(query.toUpperCase())
            })
        }
        return src
    }, [suppliers, query, filter])

    const handleConfirmDelete = (id: number) => {
        setActionsAnchorEl(null)
        setDeleteId(id)
    }

    const handleEdit = async (id: number) => {
        setActionsAnchorEl(null)
        try {
            setLoading(true)
            const files: ISupplierFile[] = await getSuppliersFileService(id)
            for (let i = 0; i < files.length; i++) {
                const file = await getSupplierFileByIdService(files[i].supplierFileId ?? -1)
                files[i] = { ...files[i], file: { ...file, name: files[i].fileName } }
            }
            setLoading(false)
            history.push({
                state: {
                    edit: true,
                    values: selected,
                    files: files
                },
                pathname: `${RESOURCES_PATH}${SUPPLIER_PATH}${EDIT_PATH}`
            })
        } catch (error) {
            setMessageConfig({ open: true, message: "No se pudo obtener información del proveedor...!", severity: "error" })
            setLoading(false)
            return false
        }
    }

    const handleDelete = async () => {
        try {
            setLoading(true)
            await deleteSupplierService(deleteId)
            setMessageConfig({ open: true, message: "Proveedor eliminado!", severity: "success" })
            await refreshSuppliers()
            setLoading(false)
            return true
        } catch (error) {
            setMessageConfig({ open: true, message: "No se pudo eliminar el Proveedor...!", severity: "error" })
            setLoading(false)
            return false
        }
    }

    const handleTaxPlan = async (supplierId: number) => {
        try {
            setLoading(true)
            await refreshTaxPlans()
            const result = await getTaxPlanSupplierBySupplierIdService(supplierId)
            setSelected(supplierId)
            setTaxPlanSupplier(result)
            setActionsAnchorEl(null)
            setLoading(false)
            setOpenTaxPlan(true)
        } catch (error) {
            setActionsAnchorEl(null)
            setOpenTaxPlan(true)
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo obtener el plan de impuestos del proveedor", severity: "error" })
        }
    }

    React.useEffect(() => {
        const initPage = async () => {
            try {
                setLoading(true)
                await refreshSuppliers()
                await refreshAccountTypes()
                await refreshIdentificationTypes()
                setLoading(false)
            } catch (error) {
                setLoading(false)
                setError(true)
            }
        }
        initPage()
    }, [refreshSuppliers, refreshAccountTypes, refreshIdentificationTypes])
    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${SUPPLIER_TITLE}`}</title>
            </Helmet>
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <Search
                        onChange={(e) => {handleChange(e); handleChangePage(e, 0)}}
                        query={query}
                        onClear={handleClear}
                        placeholer="Buscar por compañia, identificación o razón social.."
                    />
                    <TextField
                        size="small"
                        variant="outlined"
                        style={{ width: 140, marginLeft: 15 }}
                        classes={{ root: "filter-text-field" }}
                        select
                        label="Estado"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value as SupplierStateFilter)}
                    >
                        <MenuItem dense value={"active"}>{"Activos"}</MenuItem>
                        <MenuItem dense value={"inactive"}>{"Inactivos"}</MenuItem>
                        <MenuItem dense value={"all"}>{"Todos"}</MenuItem>
                    </TextField>
                </div>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => history.push(`${RESOURCES_PATH}${SUPPLIER_PATH}${NEW_PATH}`)}
                >
                    <Icon fontSize="small" style={{ marginRight: 5 }}>add</Icon>
                    {"Nuevo"}
                </Button>
                <FullSizeDialog
                    open={Boolean(location.pathname === `${RESOURCES_PATH}${SUPPLIER_PATH}${NEW_PATH}` || location.pathname === `${RESOURCES_PATH}${SUPPLIER_PATH}${EDIT_PATH}`
                        || location.pathname === `${RESOURCES_PATH}${SUPPLIER_PATH}${SUPPLIER_FILE_PATH}`)}
                    onClose={() => history.push(`${RESOURCES_PATH}${SUPPLIER_PATH}`)}
                >
                    <SupplierForm
                        onSuccess={() => { setMessageConfig({ open: true, message: Boolean(selected) ? "Proveedor actualizado!" : "Proveedor registrado!", severity: "success" }); refreshSuppliers(); if (Boolean(selected)) { setSelected(undefined) } }}
                        onError={() => setMessageConfig({ open: true, message: "No se pudo registrar el Proveedor", severity: "error" })}
                        onClose={() => history.push(`${RESOURCES_PATH}${SUPPLIER_PATH}`)}

                    />
                </FullSizeDialog>
                <FormDialog
                    open={openTaxPlan}
                    onClose={() => { setOpenTaxPlan(false); setTaxPlanSupplier([]); setSelected(undefined) }}
                >
                    <TaxPlanSupplier
                        taxPlanSupplier={taxPlanSupplier}
                        supplier={suppliers.find(s => s.supplierId === selected)}
                        onClose={() => { setOpenTaxPlan(false); setTaxPlanSupplier([]); setSelected(undefined) }}
                    />
                </FormDialog>
            </div>
            <div className="flex h-full flex-col mt-4 overflow-hidden">
                <div className="flex flex-grow overflow-auto">
                    <TableContainer>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {SUPPPLIER_TABLE_COLUMNS.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={'left'}
                                            padding={"none"}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id as any)}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className="hidden">
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        padding={"default"}
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rows(getModel()) as ISupplier[]).map((row, index) => {
                                    return (
                                        <Row
                                            key={index + `supplier`}
                                            row={row}
                                            index={index}
                                            setActionsAnchorEl={setActionsAnchorEl}
                                            setSelected={setSelected}
                                            accountTypes={accountTypes}
                                            identificationTypes={identificationTypes}
                                        />
                                    )
                                })}
                                <Menu
                                    anchorEl={actionsAnchorEl}
                                    open={Boolean(actionsAnchorEl)}
                                    onClose={() => setActionsAnchorEl(null)}
                                >
                                    <MenuItem onClick={() => handleEdit(selected?.supplierId ?? -1)} dense>{"Editar"}</MenuItem>
                                    <MenuItem onClick={() => handleConfirmDelete(selected?.supplierId ?? -1)} dense>{"Eliminar"}</MenuItem>
                                    <MenuItem onClick={() => handleTaxPlan(selected?.supplierId ?? -1)} dense>{"Plan de impuestos"}</MenuItem>
                                </Menu>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    <TablePagination
                        rowsPerPageOptions={SMALL_ROWS_PER_PAGINATION_OPTIONS}
                        component="div"
                        count={getModel().length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Proveedores por página"
                        labelDisplayedRows={LabelDisplayedRows}
                    />
                </div>
            </div>
            <ConfirmDialog
                onCancel={() => setDeleteId(-1)}
                onConfirm={handleDelete}
                open={Boolean(deleteId !== -1)}
                title={"¿Estás seguro que deseas eliminar?"}
                loading={loading}
            />
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            <ServerErrorHandler
                error={error}
                onSuccess={() => setError(false)}
                tryAgain={refreshSuppliers}
            />
            <FixedLoadingIndicator
                loading={loading}
            />
        </Paper>
    )
}


export default Suppliers
