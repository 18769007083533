import axios, { AxiosError } from 'axios'
import { DOCUMENTS_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { IDocumentProcessedFilters } from 'types'
import { ADDITIONAL_INFO_PATH, ALL_PATH, AND_PARAM, CHANGE_PATH, CONDITIONS_PARAM, COUNT_PATH, DOCUMENT_PATH, DOCUMENT_RANGE_END_PARAM, DOCUMENT_RANGE_START_PARAM, DOCUMENT_TYPES_PARAM, DRAFT_PATH, FLOW_PATH, HISTORY_TYPES_PARAM, MANAGE_PATH, OFFSET_PARAM, PAGE_SIZE_PARAM, RECEIVER_RUC_PARAM, RECEPTION_RANGE_END_PARAM, RECEPTION_RANGE_START_PARAM, REIMBURSEMENT_TAX_PLAN_PATH, RESEND_PATH, RUC_OR_NAME_PARAM, RUC_OR_NAME_RECEPTOR_PARAM, SEARCH_PARAM, SERIAL_NUMBER_PARAM, STATUS_PATH, SUPPORT_PATH } from '../../constants/paths'

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })

export const getDocumentsService = async (filters: IDocumentProcessedFilters, pageSize: number, offset: number, mydocsvalue: boolean, userid?: number) => {
    try {
        let uservalue:number = 0;
        if(Boolean(userid) && userid !==undefined){
            uservalue = userid;
        }

        const result = await intance.get(
            DOCUMENT_PATH + SEARCH_PARAM
            + DOCUMENT_TYPES_PARAM + filters.documentTypes + AND_PARAM
            + CONDITIONS_PARAM + filters.conditions + AND_PARAM
            + HISTORY_TYPES_PARAM + filters.historyTypes + AND_PARAM
            + RUC_OR_NAME_PARAM + filters.rucOrName + AND_PARAM
            + RUC_OR_NAME_RECEPTOR_PARAM + filters.rucOrNameReceptor + AND_PARAM
            + DOCUMENT_RANGE_END_PARAM + filters.documentRangeEnd + AND_PARAM
            + DOCUMENT_RANGE_START_PARAM + filters.documentRangeStart + AND_PARAM
            + SERIAL_NUMBER_PARAM + filters.serialNumber + AND_PARAM
            + RECEPTION_RANGE_END_PARAM + filters.receptionRangeEnd + AND_PARAM
            + RECEPTION_RANGE_START_PARAM + filters.receptionRangeStart + AND_PARAM
            + PAGE_SIZE_PARAM + pageSize + AND_PARAM
            + OFFSET_PARAM + offset + AND_PARAM + 'mydocs=' + mydocsvalue + AND_PARAM + 'userId=' + uservalue
        )
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getDraftsService = async (userId: number, pageSize: number, offset: number) => {
    try {
        const result = await intance.get(
            DRAFT_PATH + SEARCH_PARAM
            + 'userId=' + userId + AND_PARAM
            + PAGE_SIZE_PARAM + pageSize + AND_PARAM
            + OFFSET_PARAM + offset
        )
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const createDocumentService = async (document: any, userId: any, approverId?: any) => {
    try {
        const result = await intance.post(DOCUMENT_PATH, { ...document, userId, approverId })
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const sendApproverId = async (documentId: any) => {
    try {
        const result = await intance.get(`/notificate/${documentId}`)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const saveDraftService = async (document: any, userId: any) => {
    try {
        const result = await intance.post(DRAFT_PATH, { ...document, userId })
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const updateDocumentAdditionalInfoService = async (documentId: any, additionalInfo: string) => {
    try {
        const result = await intance.put(`${DOCUMENT_PATH}/${documentId}${ADDITIONAL_INFO_PATH}`, { additionalInfo })
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getDocumentByIdService = async (id: any) => {
    try {
        const result = await intance.get(DOCUMENT_PATH + "/" + id)
        return result.data
    } catch (error: any) {
        console.log(error)
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const queueDocumentByIdService = async (id: any) => {
    try {
        const result = await intance.get(DOCUMENT_PATH + STATUS_PATH + FLOW_PATH + "/" + id)
        return result.data
    } catch (error: any) {
        console.log(error)
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

// METODO PARA REENVIAR AL SRI CAMBIANDO EL ESTADO A 102, ESTO LO HACE EL BACK SOLO CAMBIA EL PATH EN FRONT
export const queueResendDocumentByIdService = async (id: any) => {
    try {
        const result = await intance.get(DOCUMENT_PATH + STATUS_PATH + FLOW_PATH + RESEND_PATH + "/" + id)
        return result.data
    } catch (error: any) {
        console.log(error)
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const manageDocumentByIdService = async (id: any, emails: string, subject: string, message: string, sendcarta?: boolean, idRetention?: number, idInvoice?:number, cause?:string) => {

    try {
        const result = await intance.post(DOCUMENT_PATH + MANAGE_PATH + "/" + id, { emails, subject, message, idRetention, idInvoice, sendcarta:Boolean(sendcarta), cause  })
        return result.data
    } catch (error: any) {
        console.log(error)
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getSupportDocumentByAuthorizationNumberService = async (authorizationNumber: any) => {
    try {
        const result = await intance.get(DOCUMENT_PATH + SUPPORT_PATH + "/" + authorizationNumber)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data?.message)
    }
}

export const updateDocumentStatusService = async (documentId: any, statusId: any, userId: any) => {
    try {
        const result = await intance.post(DOCUMENT_PATH + STATUS_PATH, { documentId, statusId, userId })
        return result.data
    } catch (error: any) {
        console.log(JSON.stringify(error))
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

function transformDate(inputDate: string): string {
    // Convertir la cadena de fecha en un objeto de fecha
    const dateObject = new Date(inputDate);

    // Obtener el año, mes y día del objeto de fecha
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');

    // Crear la cadena de fecha en el nuevo formato
    const transformedDate = `${year}-${month}-${day}`;

    return transformedDate;
}

type Reembolos = {
    authorizationNumber: string
    issuerIdentification: string
    issuerName: string
    documentNumber: string
    emissionDate: string
    subtotalNoTaxesObject: number
    subtotalRateZero: number
    subtotalRateTwelve: number
    iva: number
}


export const approveDocumentService = async (documentStatus: any, documentData: any) => {
    console.log('ENTRO LISTADO REEMBOLSOS');
    console.log('DOCUMENT DATA', documentData);
    let newList: Reembolos[] = []
    if (documentData.hasOwnProperty('reimbursements')) {
        if (documentData.reimbursements !== undefined && documentData.reimbursements !== null) {
            console.log('Entra if tiene nodo reembolsos');
            const list = documentData.reimbursements as Reembolos[]
            newList = list.map((r) => {
                return ({
                    ...r,
                    emissionDate: transformDate(r.emissionDate)
                })
            })
        }
    }
    if (newList.length > 0) {
        console.log('Lista vacia');
        documentData.reimbursements = newList
    }
    console.log(documentData, 'DOCUMENT DATA');

    try {
        const result = await intance.post(DOCUMENT_PATH + STATUS_PATH + CHANGE_PATH, { documentStatus, documentData })
        return result.data
    } catch (error: any) {
        console.log(error)
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const rejectDocumentService = async (documentStatus: any, documentData: any) => {
    try {

        if(documentData.hasOwnProperty('reimbursements') && Array.isArray(documentData.reimbursements)){
        
        const formattedDocumentData = documentData.reimbursements.map((reimbursement: any) => {
            const date = new Date(reimbursement.emissionDate);
            const formattedDate = date.toISOString().split('T')[0];
            return {
                ...reimbursement,
                emissionDate: formattedDate,
            };
        });

        // Actualizamos el objeto documentData con los reembolsos formateados
        const updatedDocumentData = {
            ...documentData,
            reimbursements: formattedDocumentData,
        };
        const result = await intance.post(DOCUMENT_PATH + STATUS_PATH + CHANGE_PATH, { documentStatus, documentData: updatedDocumentData });
        return result.data;
        }else{
            const result = await intance.post(DOCUMENT_PATH + STATUS_PATH + CHANGE_PATH, { documentStatus, documentData });
            return result.data; 
        }
        
        
    } catch (error: any) {
        console.log(error)
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getReimbursementTaxPlan = async () => {
    try {
        const result = await intance.get(DOCUMENT_PATH + REIMBURSEMENT_TAX_PLAN_PATH)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const countDashboardDocumentsService = async () => {
    try {
        const result = await intance.get(DOCUMENT_PATH + COUNT_PATH)
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const getAllDocumentsByParamsService = async (types: number[], receiverRuc: string) => {
    try {
        const result = await intance.get(
            DOCUMENT_PATH + ALL_PATH + SEARCH_PARAM
            + DOCUMENT_TYPES_PARAM + types.join(",") + AND_PARAM
            + RECEIVER_RUC_PARAM + receiverRuc
        )
        return result.data
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}