import moment from "moment"
import { IDateParams, ISimpleParams, IStateParams, IYearMonthConciliarParams, IYearMonthParams } from "types"

moment.locale("es")

export const transformDocumentReportParams = (emails: string[], reportTypes: number[], simpleParams?: ISimpleParams, taxParams?: IYearMonthParams, atsParams?: IYearMonthParams) => {
    const tax = taxParams ? {
        year: taxParams?.yearMonth?.getFullYear()?.toString(),
        month: (taxParams?.yearMonth?.getMonth() + 1)?.toString().padStart(2, '0')
    } : undefined
    const ats = atsParams ? {
        year: atsParams?.yearMonth?.getFullYear()?.toString(),
        month: (atsParams?.yearMonth?.getMonth() + 1)?.toString().padStart(2, '0')
    } : undefined
    const simple = simpleParams ? {
        types: simpleParams?.types,
        conditions: simpleParams?.conditions,
        documentEnd: simpleParams?.documentEnd,
        documentStart: simpleParams?.documentStart
    } : undefined
    const reportParams = {
        emails,
        reportTypes,
        tax,
        simple,
        ats
    }
    return reportParams
}

export const transformRejectedReportParams = (emails: string[], states: number[], rejectedStart: Date | null, rejectedEnd: Date | null) => {
    const reportParams = {
        emails,
        states,
        rejectedStart,
        rejectedEnd
    }
    return reportParams
}

export const transformRetentionReportParams = (user: string, reportTypes: number[], mailsToSend: string[], stateParams?: IStateParams, 
    retentionParams?: IDateParams, 
    accountingParams?: IDateParams, 
    reclaimParams?: IDateParams, 
    test1Params?: IDateParams, 
    test2Params?: IDateParams,
    conciliarParams?: IYearMonthConciliarParams,
    otcParams?: IDateParams, 
    rtrParams?: IDateParams,) => {

    const currentInquiryDate = moment(new Date())?.format('yyyy-MM-DD hh:mm:ss')?.toString()
    const currentUserRequest = user
    const state = stateParams ? {
        reportType: 1,
        conditions: stateParams?.conditions,
        initialDate: moment(stateParams?.documentStart)?.format('yyyy-MM-DD')?.toString(),
        endDate: moment(stateParams?.documentEnd)?.format('yyyy-MM-DD')?.toString(),
    } : undefined
    const retention = retentionParams ? {
        reportType: 2,
        conditions: null,
        initialDate: moment(retentionParams?.documentStart)?.format('yyyy-MM-DD')?.toString(),
        endDate: moment(retentionParams?.documentEnd)?.format('yyyy-MM-DD')?.toString(),
    } : undefined
    const accounting = accountingParams ? {
        reportType: 3,
        conditions: null,
        initialDate: moment(accountingParams?.documentStart)?.format('yyyy-MM-DD')?.toString(),
        endDate: moment(accountingParams?.documentEnd)?.format('yyyy-MM-DD')?.toString(),
    } : undefined
    const reclaim = reclaimParams ? {
        reportType: 4,
        conditions: null,
        initialDate: moment(reclaimParams?.documentStart)?.format('yyyy-MM-DD')?.toString(),
        endDate: moment(reclaimParams?.documentEnd)?.format('yyyy-MM-DD')?.toString(),
    } : undefined
    const test1 = test1Params ? {
        reportType: 5,
        conditions: null,
        initialDate: moment(test1Params?.documentStart)?.format('yyyy-MM-DD')?.toString(),
        endDate: moment(test1Params?.documentEnd)?.format('yyyy-MM-DD')?.toString(),
    } : undefined
    const test2 = test2Params ? {
        reportType: 6,
        conditions: null,
        initialDate: moment(test2Params?.documentStart)?.format('yyyy-MM-DD')?.toString(),
        endDate: moment(test2Params?.documentEnd)?.format('yyyy-MM-DD')?.toString(),
    } : undefined
    const otc = otcParams ? {
        reportType: 8,
        conditions: null,
        initialDate: moment(otcParams?.documentStart)?.format('yyyy-MM-DD')?.toString(),
        endDate: moment(otcParams?.documentEnd)?.format('yyyy-MM-DD')?.toString(),
    } : undefined
    const rtr = rtrParams ? {
        reportType: 9,
        conditions: null,
        initialDate: moment(rtrParams?.documentStart)?.format('yyyy-MM-DD')?.toString(),
        endDate: moment(rtrParams?.documentEnd)?.format('yyyy-MM-DD')?.toString(),
    } : undefined
    const conciliar = conciliarParams ? {
        reportType: 7,
        conditions: null,
        inityear: conciliarParams?.yearMonthInit?.getFullYear()?.toString(),
        initmonth: (conciliarParams?.yearMonthInit?.getMonth() + 1)?.toString().padStart(2, '0'),
        endyear: conciliarParams?.yearMonthEnd?.getFullYear()?.toString(),
        endmonth: (conciliarParams?.yearMonthEnd?.getMonth() + 1)?.toString().padStart(2, '0')
    } : undefined

    const reports : any[] = []
    console.log(reportTypes)
    if (reportTypes.includes(1)) { reports.push(state) }
    if (reportTypes.includes(2)) { reports.push(retention) }
    if (reportTypes.includes(3)) { reports.push(accounting) }
    if (reportTypes.includes(4)) { reports.push(reclaim) }
    if (reportTypes.includes(5)) { reports.push(test1) }
    if (reportTypes.includes(6)) { reports.push(test2) }
    if (reportTypes.includes(7)) { reports.push(conciliar) }
    if (reportTypes.includes(8)) { reports.push(otc) }
    if (reportTypes.includes(9)) { reports.push(rtr) }

    const reportParams = {
        currentInquiryDate,
        currentUserRequest,
        mailsToSend,
        reports
    }
    return reportParams
}