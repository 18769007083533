import { getAllDocumentsByParamsService } from 'lib'
import React from 'react'
import { IDocument } from 'types'

type Hook = {
    documents: IDocument[]
    loading: boolean
    getDocuments: (types: number[], receiverRuc: string) => Promise<void>
    clear: () => void
}

const useGetDocuments = (): Hook => {
    const [documents, setDocuments] = React.useState<IDocument[]>([])
    const [loading, setLoading] = React.useState(false)

    const getDocuments = React.useCallback(async (types: number[], receiverRuc: string) => {
        try {
            setLoading(true)
            const result = await getAllDocumentsByParamsService(types, receiverRuc)
            setDocuments(result)
            setLoading(false)
        } catch (error) {
            console.log(JSON.stringify(error))
        }
    }, [])

    const clear = React.useCallback(() => { setDocuments([]) }, [])

    return {
        documents,
        loading,
        getDocuments,
        clear
    }
}

export default useGetDocuments