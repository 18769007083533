import { Dialog, DialogTitle, DialogActions, Button, TextField, DialogContent } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ManagementContext } from 'context'
import { FIELDS } from 'lib'
import React from 'react'
import { IRejectionCause } from 'types'

interface Props {
    open: boolean
    onClose: () => void
    onReject: (rejectionCause: IRejectionCause | string) => void
    freesolo ?: boolean
}

const RejectionDialog = (props: Props) => {
    const { refreshRejectionCauses, rejectionCauses } = React.useContext(ManagementContext)
    const { open, onClose, onReject, freesolo } = props
    const [loading, setLoading] = React.useState<boolean>(false)
    const [rejectionCause, setRejectionCause] = React.useState<IRejectionCause | null | string>(null)
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (rejectionCause) {
            onReject(rejectionCause)
        }
        handleConfirm()
    }
    const handleConfirm = () => {
        setRejectionCause(null)
        onClose()
    }

    const valueOfRejection = () => {
        const isstring = typeof rejectionCause === 'string'
        if (rejectionCause === null) {
            return ''
        }
        if (!isstring) {
            return (rejectionCause as IRejectionCause).name
        }

        return (rejectionCause as string)
    }

    React.useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                await refreshRejectionCauses()
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        init()
    }, [refreshRejectionCauses])
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle color="textSecondary">{freesolo?"Escriba o seleccione el motivo de rechazo":"Rechazar documento"}</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <div className="py-1">

                        {/* <Autocomplete
                            loading={loading}
                            loadingText="Cargando..."
                            noOptionsText="No se encontraron motivos de rechazo"
                            options={rejectionCauses.filter(r => r.active).map(r => r.name)}
                            getOptionLabel={(option) => option}
                            value={valueOfRejection()}
                            openOnFocus
                            size="small"
                            onChange={(e, selectedValue) => setRejectionCause(selectedValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    label={FIELDS.rejectionCause.name}
                                    style={{ width: 350 }}
                                    autoFocus
                                    required
                                />
                            )}
                        />
 */}
                        <Autocomplete
                            loading={loading}
                            loadingText="Cargando..."
                            noOptionsText="No se encontraron motivos de rechazo"
                            options={rejectionCauses.filter(r => r.active).map(r => r.name)}
                            getOptionLabel={(option) => option}
                            value={valueOfRejection()}
                            openOnFocus
                            fullWidth
                            freeSolo = {freesolo}
                            size={"small"}
                            onChange={(e, selectedValue, reason) => {
                                setRejectionCause(selectedValue)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    label={FIELDS.rejectionCause.name}
                                    autoFocus
                                    required
                                    onBlur={(event) => {
                                        setRejectionCause(event.target.value)
        
                                    }}
                                />
                            )}
                        />

                    </div>
                </DialogContent>
                <DialogActions className="mt-8">
                    <Button onClick={onClose}>
                        {"Cancelar"}
                    </Button>
                    <Button type="submit" disableElevation variant="contained" color="primary">
                        {"Aceptar"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default RejectionDialog
