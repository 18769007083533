import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { FIELDS, formatCurrency } from 'lib'
import React from 'react'
import { IDetail, IDocumentAdditionalInfo } from 'types'

import 'moment/locale/es'
import moment from 'moment'

moment.locale("es")

interface Props {
    data: IDocumentAdditionalInfo
}

const headerCellStyle = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2 }
const bodyCellStyle = { fontSize: "0.8em", letterSpacing: 0.2 }

const getTotalValue = (percentage: number, taxBase: number) => {
    const total = (percentage / 100)*taxBase
    return total
}

const DetailDataPrinted = (props: Props) => {
    const { data } = props
    return (
        <React.Fragment>
            {data.taxes!.length > 0 &&
                <Paper variant="outlined" className={`mt-3 w-full mx-8`} elevation={1}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={headerCellStyle} align="left">{FIELDS.voucher.name}</TableCell>
                                    <TableCell style={headerCellStyle} align="left">{FIELDS.documentNumber.name}</TableCell>
                                    <TableCell style={headerCellStyle} align="left">{FIELDS.emissionDate.name}</TableCell>
                                    <TableCell style={headerCellStyle} align="left">{FIELDS.fiscalExercise.name}</TableCell>
                                    <TableCell style={headerCellStyle} align="left">{FIELDS.tax.name}</TableCell>
                                    <TableCell style={headerCellStyle} align="right">{FIELDS.taxBase.name}</TableCell>
                                    <TableCell style={headerCellStyle} align="right">{FIELDS.retainedPercentage.name}</TableCell>
                                    <TableCell style={headerCellStyle} align="right">{FIELDS.retainedValue.name}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.taxes?.map(row => (
                                        <React.Fragment>
                                            <TableRow>
                                                <TableCell style={bodyCellStyle} align="left">{'FACTURA'}</TableCell>
                                                <TableCell style={bodyCellStyle} align="left">{data.header.associatedDocument.serialNumber}</TableCell>
                                                <TableCell style={bodyCellStyle} align="left">{moment(data.header.associatedDocument.emissionDate)?.format('DD-MM-YYYY')?.toString()}</TableCell>
                                                <TableCell style={bodyCellStyle} align="left">{moment(data.header.associatedDocument.emissionDate)?.format('MM-YYYY')?.toString()}</TableCell>
                                                <TableCell style={bodyCellStyle} align="left">{row.description}</TableCell>
                                                <TableCell style={bodyCellStyle} align="right">{formatCurrency(row.taxBase ?? 0)}</TableCell>
                                                <TableCell style={bodyCellStyle} align="right">{(row.percentage ?? 0)+'%'}</TableCell>
                                                <TableCell style={bodyCellStyle} align="right">{formatCurrency(getTotalValue(row.percentage ?? 0,row.taxBase ?? 0))}</TableCell>
                                            </TableRow>

                                        </React.Fragment>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            }
        </React.Fragment >
    )
}

export default DetailDataPrinted