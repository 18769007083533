import axios, { AxiosError } from "axios"
import { AUTHENTICATION_API_SERVICE_DOMAIN, DOCUMENTS_API_SERVICE_DOMAIN, LIST_PATH, USER_PATH } from "lib"
import { IAprobarORechazar } from "model/EmialAprobarORechazar"

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })
const intanceUsers = axios.create({ baseURL: AUTHENTICATION_API_SERVICE_DOMAIN })

export const wsObtenerFileDraft = async (id : number) => {
    try {
        const result = await intance.get(`/draft-file/${id}`)
        return result.data
    } catch (error:any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const wsObtenerArchivosDraft = async (id : number) => {
    try {
        const result = await intance.get(`/draft-archivos/${id}`)
        return result.data
    } catch (error:any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const wsObtenerUsuarios = async () => {
    try {
        const result = await intanceUsers.get(USER_PATH + LIST_PATH + '/refund')
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const wsUsuarioPorId = async (id : number) => {
    try {
        const result = await intanceUsers.get(USER_PATH + '/' +id)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const wsReenviarCorreoAprobador = async (id : number) => {
    try {
        const result = await intance.get('/document/resendmail/'+id)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const wsObtenerIDAprobador = async (id : number) => {
    try {
        const result = await intance.get('/document/getaprobador/'+id)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const wsAprobarORechazarPorMail = async (request : IAprobarORechazar) => {
    try {
        const result = await intance.post('/document/manualrequest',request)
        return result.data
    } catch (error) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}