import { Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { IAuth, useAuth } from "hooks"
import React from "react"
import { IMessageConfig } from "types"

export const AuthContext = React.createContext({} as IAuth)

const AuthProvider: React.FC = ({ children }) => {
    const { login, logout, searchingUser, user, navigation, selectedNav, setSelectedNav, userApproval, authError } = useAuth()
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    React.useEffect(() => {       
        if (authError) {
            setMessageConfig({ message: "Se dio un error de autenticación", open: true, severity: "error" })
            logout()
        }
    }, [authError, logout])

    return (
        <AuthContext.Provider
            value={{
                login,
                logout,
                searchingUser,
                user,
                userApproval,
                navigation,
                selectedNav,
                setSelectedNav
            }}
        >
            {children}
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </AuthContext.Provider>
    )
}

export default AuthProvider