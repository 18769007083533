import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'
import { formatCurrency } from 'lib'
import React, { useEffect } from 'react'
import { IReimbursement } from 'types'

interface Props {
    reimbursements: IReimbursement[]
}


const labelStyle: any = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1", textAlign: "right" }
const headerCellStyle = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2 }
const bodyCellStyle = { fontSize: "0.8em", letterSpacing: 0.2 }

const ValuesEnviadosCoupa = (props: Props) => {

    const { reimbursements } = props

    function calcularValores(refunds: IReimbursement[]): { totalNoObjeto: number, totalIva0: number, totalIva12: number, 
        totalIva: number,  totalIva15: number, totalI15:number} {
        let totalNoObjeto = 0;
        let totalIva0 = 0;
        let totalIva12 = 0;
        let totalIva15 = 0;
        let totalIva = 0;
        let totalI15 = 0;
        
        const getNumber=(numberTransform:string|number)=>{
            const isString = typeof numberTransform==='string'
            if(isString){
                return Number(numberTransform as string)
            }
            return numberTransform as number
        }
        refunds.forEach((refund) => {

            totalNoObjeto += getNumber(refund.subtotalNoTaxesObject);
            totalIva0 += getNumber(refund.subtotalRateZero);
            totalIva12 += getNumber(refund.subtotalRateTwelve);
            totalIva15 += getNumber(refund.subtotalRateQuince);
            totalIva += getNumber(refund.iva);
            totalI15  += getNumber(refund.iva15);
        });
        /* console.log('TOTAL NO OBJETO: ',totalNoObjeto);
        console.log('TOTAL IVA 0: ',totalIva0);
        console.log('TOTAL IVA 12: ',totalIva12);
        console.log('IVA: ',totalIva); */
        return { totalNoObjeto, totalIva0, totalIva12, totalIva, totalIva15, totalI15 };
    }

    const totales = React.useMemo(()=>calcularValores(reimbursements),[reimbursements])


    return (
        <React.Fragment>

            <Paper variant="outlined" className={`mt-3 w-full`} elevation={1}>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{'Total no Objeto'}</TableCell>
                                <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(totales.totalNoObjeto)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{'Total IVA 0% '}</TableCell>
                                <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(totales.totalIva0)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{'Total IVA 12% '}</TableCell>
                                <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(totales.totalIva12)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{'Total IVA 15% '}</TableCell>
                                <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(totales.totalIva15)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{'IVA 12%'}</TableCell>
                                <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(totales.totalIva)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{'IVA 15%'}</TableCell>
                                <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(totales.totalI15)}`}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </React.Fragment>
    )
}

export default ValuesEnviadosCoupa
