import { IReimbursement } from "types"
import { FIELDS } from "./fields"

export const REIMBURSEMENT_TABLE_COLUMNS = [
    { id: FIELDS.authorizationNumber.key, label: FIELDS.authorizationNumber.name },
    { id: FIELDS.issuerName.key, label: FIELDS.issuerName.name },
    { id: FIELDS.receiverName.key, label: FIELDS.receiverName.name },
    { id: FIELDS.documentNumber.key, label: FIELDS.documentNumber.name },
    { id: FIELDS.emissionDate.key, label: FIELDS.emissionDate.name },
    { id: FIELDS.subtotalNoObject.key, label: FIELDS.subtotalNoObject.name },
    { id: FIELDS.subtotalRateZero.key, label: FIELDS.subtotalRateZero.name },
    { id: FIELDS.subtotalRateTwelve.key, label: FIELDS.subtotalRateTwelve.name },
    { id: FIELDS.iva.key, label: 'iva 12%' },
    { id: 'subtotalRateQuince', label: 'Subtotal IVA 15%' },
    { id: 'iva15', label: 'IVA 15%' },
]

export const REIMBURSEMENT_DETAIL_TABLE_COLUMNS = [
    { id: FIELDS.description.key, label: FIELDS.description.name },
    { id: FIELDS.accounting.key, label: FIELDS.accounting.name },
    { id: FIELDS.costCenter.key, label: FIELDS.costCenter.name },
    { id: FIELDS.branch.key, label: FIELDS.branch.name },
]

export const INITIAL_REIMBURSEMENT: IReimbursement = {
    authorizationNumber: "",
    documentNumber: "",
    emissionDate: (null as unknown) as Date,
    issuerName: "",
    issuerIdentification: "",
    iva: 0,
    subtotalRateZero: 0,
    subtotalRateTwelve: 0,
    subtotalNoTaxesObject: 0,
    iva15: 0,
    subtotalRateQuince: 0,
    taxSupport: false
}

export const REIMBURSEMENT_RANGE_TOLERANCE = 0.02

export const EXPENSE_ITEMS = [
    {
        name: "Alimentación",
        key: "Personal Meals"
    },
    {
        name: "Hotel",
        key: "HOTEL"
    },
    {
        name: "Movilización",
        key: "AIR, RAIL, TAXI, CAR RENTAL"
    },
    {
        name: "Combustibles",
        key: "GAS"
    },
    {
        name: "Estacionamiento y peajes",
        key: "Parking & Tolls"
    },
    {
        name: "Entretenimiento",
        key: "ENTERTAINMENT"
    },
    {
        name: "Teléfono",
        key: "TELEPHONE"
    },
    {
        name: "Alimentación Training",
        key: "TRAINING MEALS"
    },
    {
        name: "Tickets Aéreos",
        key: "AIRLINE TICKETS"
    },
    {
        name: "Otros Training",
        key: "TRAINING ALL OTHER"
    },
    {
        name: "Otros",
        key: "OTHER (DETAIL)"
    },
    
]

export const AIR_TICKET_AUTH_NUMBER = "7777777777777777777777777777777777777"