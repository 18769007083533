import { getClosingDateByMonthAndYearService } from "lib";
import moment from "moment";
import React from "react";
import { ISingleClosingDate } from "types";

type Hook = { isValid: boolean | undefined }

const useValidCreationDate = (): Hook => {
  const [isValid, setIsValid] = React.useState<boolean | undefined>(undefined)
  React.useEffect(() => {
    const init = async () => {
        const currentDate = new Date()
        const response: ISingleClosingDate = await getClosingDateByMonthAndYearService(currentDate.getMonth(), currentDate.getFullYear())
        if (response) {
            if (Boolean(response.openingDate) && Boolean(response.closingDate)) {
                const openingDate = moment(response.openingDate, "YYYY-MM-DD").toDate()
                const closingDate = moment(response.closingDate, "YYYY-MM-DD").toDate()
                setIsValid(currentDate.getDate() >= openingDate.getDate() && currentDate.getDate() <= closingDate.getDate())
            } else if(Boolean(response.openingDate) && !Boolean(response.closingDate)){
                const openingDate = moment(response.openingDate, "YYYY-MM-DD").toDate()
                setIsValid(currentDate.getDate() >= openingDate.getDate())    
            } else {
                setIsValid(false)    
            }
        } else {
            setIsValid(false)
        }
    }
    init()
  }, [])
  return {isValid}
}

export default useValidCreationDate
