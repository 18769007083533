import {
    Tooltip, IconButton, Icon, Button, Divider, Typography, CircularProgress, Dialog, DialogTitle,
    DialogActions, DialogContent, DialogContentText, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { wsObtenerUsuarios } from 'api/APIDraft'
import { DocumentPicker } from 'components'
import { AuthContext } from 'context'
import { DASHBOARD_PATH, DOCUMENT_PATH } from 'lib'
import React from 'react'
import { useHistory } from 'react-router'
import { IUser } from 'types'

interface Props {
    title: string
    onSubmit: (approverId: number|undefined) => void
    loading: boolean
    draft?: boolean
    onSubmitDraft: () => void
}

const DocumentActions = (props: Props) => {
    const history = useHistory()
    const { user } = React.useContext(AuthContext)
    const [openPicker, setOpenPicker] = React.useState<boolean>(false)
    const { title, loading, onSubmit, draft, onSubmitDraft } = props
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogDraft, setOpenDialogDraft] = React.useState(false);
    const [faltaAprobador, setFaltaAprobador] = React.useState(false);
    const [usuarios, setusuarios] = React.useState<IUser[]>([])
    const [aprobador, setaprobador] = React.useState<IUser | undefined>(undefined);

    const accionGuardarBorrador = () => {
        onSubmitDraft()
        setOpenDialogDraft(false);
    }

    const accionCrear = () => {

        if (aprobador) {
            setFaltaAprobador(false)
            onSubmit(aprobador!==undefined ? aprobador.userId : undefined)
            setOpenDialog(false);
        } else {
            setFaltaAprobador(true)
        }
    }

    // SELECCIONAR APROBADOR

    const handleChange = (event) => {
        const selectedUser = usuarios.find((user) => user.name === event.target.value);
        setaprobador(selectedUser)
    };

    React.useEffect(() => {
        obtenerUusariosAprobacion()
    }, [])

    const obtenerUusariosAprobacion = async () => {
        const result = await wsObtenerUsuarios();
        setusuarios(result)
    }

    return (
        <div className="mt-4 flex justify-between">
            <div className="h-full flex items-center pl-2">
                <Typography variant="caption" color="textSecondary" style={{ fontWeight: 600, letterSpacing: 0.5 }}>
                    {title}
                </Typography>
            </div>
            <div className="flex">
                <div className="mx-3 h-full flex items-center">
                    <Tooltip arrow title="Regresar a dashboard">
                        <IconButton
                            disabled={loading}
                            size="small"
                            onClick={() => history.push(`${DOCUMENT_PATH}${DASHBOARD_PATH}`)}
                        >
                            <Icon>
                                chevron_left
                            </Icon>
                        </IconButton>
                    </Tooltip>
                </div>
                <Button
                    variant="contained"
                    disableElevation
                    disabled={loading}
                    type="submit"
                    onClick={() => setOpenPicker(true)}
                >
                    {"Cambiar tipo de documento"}
                </Button>
                <div className="mx-3">
                    <Divider orientation="vertical" />
                </div>
                {Boolean(draft) &&
                    <>
                        <Button
                            variant="contained"
                            disableElevation
                            disabled={loading}
                            type="submit"
                            /* onClick={() => onSubmitDraft()} */
                            onClick={() => setOpenDialogDraft(true)}
                        >
                            {"Guardar Borrador"}
                        </Button>
                        <div className="mx-3">
                            <Divider orientation="vertical" />
                        </div>
                    </>
                }
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    disabled={loading}
                    /* CAMBIOS PARA REGRESO DE VERSION */
                    onClick={ title.toUpperCase()  === 'REEMBOLSO DE EMPLEADOS' 
                    || title.toUpperCase() === 'REPOSICIÓN DE CAJA CHICA' ? () => setOpenDialog(true) : () => onSubmit(undefined)} 
                    /* CAMBIOS PARA REGRESO DE VERSION */
                    /* onClick={() => onSubmit(undefined)}  */
                >
                    {loading ? <CircularProgress style={{ color: "white" }} size={16} className="my-1" thickness={10} /> : "Crear"}
                </Button>
            </div>
            <DocumentPicker
                open={openPicker}
                onClose={() => setOpenPicker(false)}
            />
            <Dialog open={openDialogDraft} onClose={() => setOpenDialogDraft(false)} maxWidth="xl">
                <div style={{ padding: '20px 30px' }}>
                    <DialogTitle>¿Guardar el documento como borrador?</DialogTitle>
                    <DialogActions style={{ padding: '0px' }}>
                        <Button variant="contained" disableElevation onClick={() => setOpenDialogDraft(false)}>Cancelar</Button>
                        <Button color="primary" variant="contained" disableElevation onClick={() => accionGuardarBorrador()} autoFocus>Guardar</Button>
                    </DialogActions>
                </div>

            </Dialog>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="xl">
                <div style={{ padding: '20px 30px' }}>
                    <DialogContent style={{ padding: '0px', marginBottom: '20px' }}>
                        <Typography>Seleccione el aprobador del documento:</Typography>
                        <br />
                        {faltaAprobador &&
                            <Alert severity="error" style={{ padding: "0px 16px", marginBottom:'20px'}}>
                                <Typography className="pl-2" variant="caption">
                                    {"Para crear el documento, debe seleccionar un aprobador."}
                                </Typography>
                            </Alert>
                        }
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Aprobador</InputLabel>
                            <Select
                                variant='filled'
                                value={aprobador ? aprobador?.name : 'NADA NADA'}
                                fullWidth
                                label="Aprobador"
                                onChange={handleChange}
                            >
                                {usuarios.map((usuario, index) => (
                                    <MenuItem key={index} value={usuario.name}>
                                        {usuario.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" disableElevation onClick={() => setOpenDialog(false)}>Cancelar</Button>
                        <Button
                            color="primary"
                            variant="contained"
                            disableElevation
                            onClick={() => accionCrear()}
                            autoFocus>Aceptar</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div >
    )
}

export default DocumentActions
