import { Grid, TextField, Typography } from '@material-ui/core'
import { FIELDS, SUPPLIER_CONTACT_TYPES } from 'lib'
import React from 'react'
import { ISupplier } from 'types'

interface Props {
    handleChange: (key: string, value: any) => void
    supplier: ISupplier
}

const SupplierPaymentForm = (props: Props) => {
    const { handleChange, supplier } = props
    return (
        <div className="w-full h-auto flex flex-wrap justify-between items-start">
            {SUPPLIER_CONTACT_TYPES.map((type) => {
                    return <Grid container spacing={1} alignItems={"center"} style={{ paddingTop: 8}}>
                    <Grid item xs={4}>
                        <Typography color="textSecondary" variant="body2" style={{ fontWeight: 600, letterSpacing: 0.5, fontSize: 12}}>
                            {type.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            size="small"
                            variant="outlined"
                            placeholder={FIELDS.contactName.name}
                            value={supplier[FIELDS.contacts.key]?.[type.key]?.[FIELDS.contactName.key]}
                            onChange={(e) => {
                                handleChange(FIELDS.contacts.key, 
                                    {
                                        ...supplier.contacts, 
                                        [type.key]: {
                                            ...supplier.contacts?.[type.key], contactName: e.target.value
                                            }
                                        }
                                    )
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            size="small"
                            placeholder={FIELDS.contactEmail.name}
                            variant="outlined"
                            value={supplier[FIELDS.contacts.key]?.[type.key]?.[FIELDS.contactEmail.key]}
                            onChange={(e) => {
                                handleChange(FIELDS.contacts.key, 
                                    {
                                        ...supplier.contacts, 
                                        [type.key]: {
                                            ...supplier.contacts?.[type.key], contactEmail: e.target.value
                                            }
                                        }
                                    )
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            size="small"
                            placeholder={FIELDS.contactPhone.name}
                            variant="outlined"
                            value={supplier[FIELDS.contacts.key]?.[type.key]?.[FIELDS.contactPhone.key]}
                            onChange={(e) => {
                                handleChange(FIELDS.contacts.key, 
                                    {
                                        ...supplier.contacts, 
                                        [type.key]: {
                                            ...supplier.contacts?.[type.key], contactPhone: e.target.value
                                            }
                                        }
                                    )
                            }}
                        />
                    </Grid>
                </Grid>
            })}
        </div>
    )
}

export default SupplierPaymentForm