import { Tooltip, IconButton, Icon, Button, Divider, Typography, CircularProgress } from '@material-ui/core'
import { DASHBOARD_PATH, DOCUMENT_PATH } from 'lib'
import React from 'react'
import { useHistory } from 'react-router'

interface Props {
    onSubmit: () => void
    loading: boolean
}

const RetentionActions = (props: Props) => {
    const history = useHistory()
    const { loading, onSubmit } = props
    return (
        <div className="mt-4 flex justify-between">
            <div className="h-full flex items-center pl-2">
                <Typography variant="caption" color="textSecondary" style={{ fontWeight: 600, letterSpacing: 0.5 }}>
                    {"Retención"}
                </Typography>
            </div>
            <div className="flex">
                <div className="mx-3 h-full flex items-center">
                    <Tooltip arrow title="Regresar a dashboard">
                        <IconButton
                            disabled={loading}
                            size="small"
                            onClick={() => history.push(`${DOCUMENT_PATH}${DASHBOARD_PATH}`)}
                        >
                            <Icon>
                                chevron_left
                            </Icon>
                        </IconButton>
                    </Tooltip>
                </div>
                <div className="mx-3">
                    <Divider orientation="vertical" />
                </div>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    disabled={loading}
                    onClick={() => onSubmit()}
                >
                    {loading ? <CircularProgress style={{ color: "white" }} size={16} className="my-1" thickness={10} /> : "Crear"}
                </Button>
            </div>
        </div>
    )
}

export default RetentionActions
