import { Button, Checkbox, Fab, Icon, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Snackbar, TextField, Typography } from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import { ManagementContext } from 'context'
import React from 'react'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import isEmail from 'validator/lib/isEmail'
import { IMessageConfig } from 'types'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface Props {
    reportTypeSelected: boolean
    onSubmit: (emails: string[]) => void
    supplier ?: boolean
}

const EmailList = (props: Props) => {
    const { reportTypeSelected, onSubmit, supplier } = props
    const { users } = React.useContext(ManagementContext)
    const [emails, setEmails] = React.useState<string[]>([])
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })

    const isEnabled =   (emails.length > 0 && reportTypeSelected) || (emails.length > 0 && supplier);            

    return (
        <Paper style={{ width: 400, maxWidth: 400, minWidth: 400 }} className="flex h-full ml-4 p-4 flex-col overflow-hidden">
            <Typography className="pb-3" color="textSecondary" variant="body2" style={{ fontWeight: 700, letterSpacing: 0.5 }}>
                {"Enviar a:"}
            </Typography>
            <div className="flex items-start w-full mb-4">
                <Autocomplete
                    options={users.map(u => u.email)}
                    freeSolo
                    openOnFocus
                    multiple
                    size="small"
                    getOptionLabel={(option) => option}
                    fullWidth
                    value={emails}
                    ChipProps={{
                        style: { display: "hidden" }
                    }}
                    onChange={(_, value, reason, detail) => {
                        if (reason === "create-option" && (!isEmail(detail?.option ?? ""))) {
                            setMessageConfig({ message: "Ingresa un email válido", severity: "error", open: true })
                            return
                        }
                        setEmails(value)
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            // helperText="Presiona enter o (+) para agregar un correo nuevo"
                            placeholder="Buscar o ingresar correo"
                            variant="outlined"
                            FormHelperTextProps={{ style: { margin: 0, marginTop: 2, marginLeft: 2 } }}
                        />
                    }
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                size="small"
                            />
                            <Typography variant="subtitle2">
                                {option}
                            </Typography>
                        </React.Fragment>
                    )}
                    renderTags={() => <React.Fragment />}
                    disableCloseOnSelect
                />
                <Fab
                    size="small"
                    color="primary"
                    style={{ marginLeft: 10, marginRight: 2, width: 45, height: 40 }}
                >
                    <Icon>
                        add
                    </Icon>
                </Fab>
            </div>
            <List className="flex flex-col flex-grow overflow-y-auto">
                {
                    emails.map(email => (
                        <ListItem
                            key={email}
                            button
                            className="hover-list-item"
                            onClick={() => {
                                setEmails(current => current.filter(c => c !== email))
                            }}
                        >
                            <ListItemText className="truncate" primary={email} />
                            <div className="hover-icon">
                                <ListItemSecondaryAction>
                                    <IconButton size="small" edge="end">
                                        <Icon fontSize="small">close</Icon>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </div>
                        </ListItem>
                    ))
                }
            </List>
            <Button
                fullWidth
                size="small"
                color="primary"
                variant="contained"
                style={{ marginTop: 20 }}
                disableElevation
                disabled={!isEnabled}
                onClick={() => {
                    onSubmit(emails)
                    setEmails([])
                }}
            >
                {"Enviar"}
            </Button>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </Paper>
    )
}

export default EmailList
