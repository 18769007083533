import { Button, Divider, Drawer, Icon, IconButton, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ConfirmDialog, CurrencyInput, FixedLoadingIndicator, ReimbursementForm } from 'components'
import { AuthContext } from 'context'
import { useGetSupplier, useUpdateValues } from 'hooks'
import { FIELDS, REIMBURSEMENT_RANGE_TOLERANCE, formatCurrency, getDetailSummary, getDetailSummaryFromData } from 'lib'
import moment from 'moment'
import React, { useEffect } from 'react'
import { IDetail, IDetailSummary, IMessageConfig, IRefund } from 'types'

interface Props {
    refunds: IRefund[]
}


const labelStyle: any = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1", textAlign: "right" }
const headerCellStyle = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2 }
const bodyCellStyle = { fontSize: "0.8em", letterSpacing: 0.2 }

const ReembolsoResumen = (props: Props) => {

    const { refunds } = props

    function calcularTotales(refunds: IRefund[]): { totalTaxSupport: number, totalTaxRefund: number } {
        let totalTaxSupport = 0;
        let totalTaxRefund = 0;

        refunds.forEach((refund) => {
            totalTaxSupport += refund.taxSupport;
            totalTaxRefund += refund.taxRefund;
        });

        return { totalTaxSupport, totalTaxRefund };
    }

    const totales = React.useMemo(()=>calcularTotales(refunds),[refunds])

    return (
        <React.Fragment>
            <Paper variant="outlined" className={`mt-3 w-full`} elevation={1}>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{'Total Sustentos'}</TableCell>
                                <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(totales.totalTaxSupport)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{'Total Impuestos'}</TableCell>
                                <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency(totales.totalTaxRefund)}`}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2, color: "#a1a1a1" }} align="right">{'TOTAL'}</TableCell>
                                <TableCell style={{ fontSize: "0.95em", fontWeight: 600, letterSpacing: 0.2 }} align="right">{`${formatCurrency((totales.totalTaxRefund + totales.totalTaxSupport))}`}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </React.Fragment>
    )
}

export default ReembolsoResumen
