import { CANCELED_INVOICES_PATH, COMPLETED_INVOICE_PATH, COMPLETED_PATH, COMPLETED_RETENTION_PATH, DOCUMENT_STATES, DOCUMENT_TYPE_CODES, INVOICE_DASHBOARD_PATH, NEW_DOCUMENT_PATH, ON_HOLD_INVOICES_PATH, ON_HOLD_RETENTION_PATH, PENDING_INVOIVE_PATH, PENDING_RETENTION_PATH, REJECTED_PATH, REJECTED_RETENTION_PATH, RETENTION_DASHBOARD_PATH, RETENTION_PATH } from 'lib'
import { DocumentsDashboard, NewRetention } from 'pages'
import DocumentsSelectedInvoice from 'pages/Dashboard/DocumentsSelectedInvoice'
import DocumentsStatics from 'pages/Dashboard/DocumentsStatics'
import React from 'react'
import { Route } from 'react-router-dom'

type Props = {}

const Retentions = (props: Props) => {
    return (
        <React.Fragment>
            <Route
                strict
                path={`${RETENTION_PATH}${INVOICE_DASHBOARD_PATH}`}
                component={
                    () => <DocumentsDashboard
                        initialCode={DOCUMENT_TYPE_CODES.retentions}
                        issuingCompany
                        forEmission
                        redirectOnApprove={`${RETENTION_PATH}${COMPLETED_PATH}`}
                        redirectOnReject={`${RETENTION_PATH}${REJECTED_PATH}`}
                        createRetentionOnly
                    />
                }
            />
            <Route
                strict
                path={'/retention/test'}
                component={
                    () => <DocumentsSelectedInvoice
                        issuingCompany
                        showCounts
                        forEmission
                        redirectOnApprove={`${RETENTION_PATH}${COMPLETED_PATH}`}
                        redirectOnReject={`${RETENTION_PATH}${REJECTED_PATH}`}
                        createRetentionOnly
                    />
                }
            />
            <Route
                strict
                path={'/retention/statics'}
                component={
                    () => <DocumentsStatics
                    />
                }
            />
            <Route
                strict
                path={`${RETENTION_PATH}${RETENTION_DASHBOARD_PATH}`}
                component={
                    () => <DocumentsDashboard
                        initialCode={DOCUMENT_TYPE_CODES.allRetentions}
                        receivingCompany
                        redirectOnApprove={`${RETENTION_PATH}${COMPLETED_PATH}`}
                        redirectOnReject={`${RETENTION_PATH}${REJECTED_PATH}`}
                        createRetentionOnly
                    />
                }
            />
            <Route
                strict
                path={`${RETENTION_PATH}${PENDING_INVOIVE_PATH}`}
                component={
                    () => <DocumentsDashboard
                        initialCode={DOCUMENT_TYPE_CODES.electronicInvoice}
                        initialState={DOCUMENT_STATES.pending}
                        issuingCompany
                        hideState={true}
                        hideType={true}
                        hideProcess={true}
                        redirectOnApprove={`${RETENTION_PATH}${COMPLETED_PATH}`}
                        redirectOnReject={`${RETENTION_PATH}${REJECTED_PATH}`}
                        createRetentionOnly
                    />
                }
            />
            <Route
                strict
                path={`${RETENTION_PATH}${PENDING_RETENTION_PATH}`}
                component={
                    () => <DocumentsDashboard
                        initialCode={DOCUMENT_TYPE_CODES.allRetentions}
                        initialState={DOCUMENT_STATES.pending}
                        receivingCompany
                        hideState={true}
                        hideType={true}
                        hideProcess={true}
                        redirectOnApprove={`${RETENTION_PATH}${COMPLETED_PATH}`}
                        redirectOnReject={`${RETENTION_PATH}${REJECTED_PATH}`}
                        createRetentionOnly
                    />
                }
            />
            <Route
                strict
                path={`${RETENTION_PATH}${CANCELED_INVOICES_PATH}`}
                component={
                    () => <DocumentsDashboard
                        initialCode={DOCUMENT_TYPE_CODES.electronicInvoice}
                        initialState={DOCUMENT_STATES.creditNoteCanceled}
                        issuingCompany
                        hideState={true}
                        hideType={true}
                        hideProcess={true}
                        redirectOnApprove={`${RETENTION_PATH}${COMPLETED_PATH}`}
                        redirectOnReject={`${RETENTION_PATH}${REJECTED_PATH}`}
                        createRetentionOnly
                    />
                }
            />
            <Route
                strict
                path={`${RETENTION_PATH}${REJECTED_RETENTION_PATH}`}
                component={
                    () => <DocumentsDashboard
                        initialCode={DOCUMENT_TYPE_CODES.allRetentions}
                        initialState={DOCUMENT_STATES.rejected}
                        receivingCompany
                        hideState={true}
                        hideType={true}
                        hideProcess={true}
                        redirectOnApprove={`${RETENTION_PATH}${COMPLETED_PATH}`}
                        redirectOnReject={`${RETENTION_PATH}${REJECTED_PATH}`}
                        createRetentionOnly
                    />
                }
            />
            <Route
                strict
                path={`${RETENTION_PATH}${COMPLETED_INVOICE_PATH}`}
                component={
                    () => <DocumentsDashboard
                        initialCode={DOCUMENT_TYPE_CODES.retentions}
                        initialState={DOCUMENT_STATES.inProcess}
                        issuingCompany
                        hideState={true}
                        hideType={true}
                        hideProcess={true}
                        redirectOnApprove={`${RETENTION_PATH}${COMPLETED_PATH}`}
                        redirectOnReject={`${RETENTION_PATH}${REJECTED_PATH}`}
                        createRetentionOnly
                    />
                }
            />
            <Route
                strict
                path={`${RETENTION_PATH}${COMPLETED_RETENTION_PATH}`}
                component={
                    () => <DocumentsDashboard
                        initialCode={DOCUMENT_TYPE_CODES.allRetentions}
                        initialState={DOCUMENT_STATES.inProcess}
                        receivingCompany
                        hideState={true}
                        hideType={true}
                        hideProcess={true}
                        redirectOnApprove={`${RETENTION_PATH}${COMPLETED_PATH}`}
                        redirectOnReject={`${RETENTION_PATH}${REJECTED_PATH}`}
                        createRetentionOnly
                    />
                }
            />
            <Route
                strict
                path={`${RETENTION_PATH}${ON_HOLD_INVOICES_PATH}`}
                component={
                    () => <DocumentsDashboard
                        initialCode={DOCUMENT_TYPE_CODES.retentions}
                        initialState={DOCUMENT_STATES.onHold}
                        issuingCompany
                        hideState={true}
                        hideType={true}
                        hideProcess={true}
                        redirectOnApprove={`${RETENTION_PATH}${COMPLETED_PATH}`}
                        redirectOnReject={`${RETENTION_PATH}${REJECTED_PATH}`}
                        createRetentionOnly
                    />
                }
            />
            <Route
                strict
                path={`${RETENTION_PATH}${ON_HOLD_RETENTION_PATH}`}
                component={
                    () => <DocumentsDashboard
                        initialCode={DOCUMENT_TYPE_CODES.allRetentions}
                        initialState={DOCUMENT_STATES.onHold}
                        receivingCompany
                        hideState={true}
                        hideType={true}
                        hideProcess={true}
                        redirectOnApprove={`${RETENTION_PATH}${COMPLETED_PATH}`}
                        redirectOnReject={`${RETENTION_PATH}${REJECTED_PATH}`}
                        createRetentionOnly
                    />
                }
            />
            <Route
                strict
                path={`${RETENTION_PATH}${NEW_DOCUMENT_PATH}`}
                component={NewRetention}
            />
        </React.Fragment>
    )
}

export default Retentions