import axios, { AxiosError } from 'axios'
import { PATH_DEMONS, PATH_DEMONS_3ALL, PATH_DEMONS_CREDITNOTE, 
    PATH_DEMONS_DOWNLOAD_EMITIDOS, PATH_DEMONS_INVOICE, PATH_DEMONS_MAIL_INCOMPLETE_RETENTION, PATH_DEMONS_MAIL_NO_RETENTION, PATH_DEMONS_NO_AUXILIAR, PATH_DEMONS_RETENTION, PATH_DEMONS_REVERSOS, PATH_DEMONS_VERIFY_DOWNLOAD } from 'lib'
import { DOCUMENTS_API_SERVICE_DOMAIN } from 'lib/constants/api'

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })


export const demonsDownloadEmitidosAPI = async () => {
    try {
        const result = await intance.get(PATH_DEMONS + PATH_DEMONS_DOWNLOAD_EMITIDOS)
        return result
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const demonsVerifyDownloadAPI = async () => {
    try {
        const result = await intance.get(PATH_DEMONS + PATH_DEMONS_VERIFY_DOWNLOAD)
        return result
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const demonsDocumentAPI = async (id:number) => {
    try {
        const result = await intance.get('/document/status/flow/'+id)
        return result
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const demonsAllProcessAPI = async () => {
    try {
        const result = await intance.get(PATH_DEMONS + PATH_DEMONS_3ALL)
        return result
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const demonsInvoiceProcessAPI = async () => {
    try {
        const result = await intance.get(PATH_DEMONS + PATH_DEMONS_INVOICE)
        return result
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const demonsRetentionProcessAPI = async () => {
    try {
        const result = await intance.get(PATH_DEMONS + PATH_DEMONS_RETENTION)
        return result
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const demonsCreditNoteProcessAPI = async () => {
    try {
        const result = await intance.get(PATH_DEMONS + PATH_DEMONS_CREDITNOTE)
        return result
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const demonsMailNoRetention = async () => {
    try {
        const result = await intance.get(PATH_DEMONS + PATH_DEMONS_MAIL_NO_RETENTION)
        return result
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const demonsMailIncompleteRetention = async () => {
    try {
        const result = await intance.get(PATH_DEMONS + PATH_DEMONS_MAIL_INCOMPLETE_RETENTION)
        return result
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const demonsAuxiliarNo = async () => {
    try {
        const result = await intance.get(PATH_DEMONS + PATH_DEMONS_NO_AUXILIAR)
        return result
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}

export const demonsAuxiliarReverso = async () => {
    try {
        const result = await intance.get(PATH_DEMONS + PATH_DEMONS_REVERSOS)
        return result
    } catch (error: any) {
        const e: AxiosError = error
        throw new Error(e.response?.data)
    }
}