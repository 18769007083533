import { Icon, LinearProgress, Snackbar } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import React from 'react'

interface Props {
    total: number
    success: number
    error: boolean
}

const FileUploaderProgress = (props: Props) => {
    const { success, total } = props
    const [progress, setProgress] = React.useState(0)
    const [buffer, setBuffer] = React.useState(10)
    const progressRef = React.useRef(() => { })
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0)
                setBuffer(10)
            } else {
                const diff = Math.random() * 10
                const diff2 = Math.random() * 10
                setProgress(progress + diff)
                setBuffer(progress + diff + diff2)
            }
        }
    })
    React.useEffect(() => {
        const timer = setInterval(() => { progressRef.current() }, 500)
        return () => { clearInterval(timer) }
    }, [])
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
    }
    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={true}
                onClose={handleClose}
            >
                <Alert style={{ padding: "10px 35px" }} onClose={undefined} severity="error" variant="filled" icon={<Icon style={{ marginRight: 15 }}>backup</Icon>}>
                    <AlertTitle style={{ paddingBottom: 5 }}>{`Subiendo archivos`}</AlertTitle>
                    <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default FileUploaderProgress
