import { ITableModelHook, useTableModel } from 'hooks'
import {
    getAccountTypessService,
    getOfficesService,
    getTaxPlansService,
    getRejectionCauseService,
    getSuppliersService,
    getTaxesService,
    getTaxTypesService,
    getUsersService,
    getModuleService,
    getViewsService,
    getMatrixesService,
    getIdentificationTypesService,
    getApprovalLevelsService,
    getUserApprovalsService,
    getCategoriesService,
    getCustomersServicePaginated,
} from 'lib'
import React from 'react'
import {
    IAccountType, IOffice, ITax, ITaxType, ITaxPlan, IRejectionCause,
    ISupplier, IModule, IUser, IView, IMatrix, IIdentificationType, IApprovalLevel, ICategory, ICustomer,
} from 'types'
import { IUserApproval } from 'types/userApproval'


interface Props {
    loading: boolean
    offices: IOffice[]
    accountTypes: IAccountType[]
    taxes: ITax[]
    suppliers: ISupplier[]
    taxTypes: ITaxType[]
    taxPlans: ITaxPlan[]
    rejectionCauses: IRejectionCause[]
    modules: IModule[]
    users: IUser[]
    views: IView[]
    matrixes: IMatrix[]
    approvalLevels: IApprovalLevel[]
    identificationTypes: IIdentificationType[]
    userApprovals: IUserApproval[]
    categories: ICategory[]
    customers: ICustomer[]
    customersNewRetention: ICustomer[]
    customersTotal: number
    customersFilter: string
    customersModel: ITableModelHook
    refreshOffices: () => Promise<void>
    refreshAccountTypes: () => Promise<void>
    refreshTaxes: () => Promise<void>
    refreshTaxTypes: () => Promise<void>
    refreshTaxPlans: () => Promise<void>
    refreshRejectionCauses: () => Promise<void>
    refreshSuppliers: () => Promise<void>
    refreshModules: () => Promise<void>
    refreshUsers: () => Promise<void>
    refreshViews: () => Promise<void>
    refreshMatrixes: () => Promise<void>
    refreshIdentificationTypes: () => Promise<void>
    refreshApprovalLevels: () => Promise<void>
    refreshUserApprovals: () => Promise<void>
    refreshCategories: () => Promise<void>
    loadInitialCustomers: () => Promise<void>
    loadInitialCustomersWithFilter: () => Promise<void>
    refreshCustomers: (pageSize: number, offset: number) => Promise<void>
    setCustomersFilter: (string) => void
    setContextLoading: (boolean) => void
    loadInitialCustomersWithFilterNoPage: () => Promise<void>
}

export const ManagementContext = React.createContext({} as Props)

const ManagementProvider: React.FC = ({ children }) => {
    const [offices, setOffices] = React.useState<IOffice[]>([])
    const [accountTypes, setAccountTypes] = React.useState<IAccountType[]>([])
    const [taxes, setTaxes] = React.useState<ITax[]>([])
    const [taxTypes, setTaxesTypes] = React.useState<ITaxType[]>([])
    const [taxPlans, setTaxPlans] = React.useState<ITaxPlan[]>([])
    const [rejectionCauses, setRejectionCauses] = React.useState<IRejectionCause[]>([])
    const [suppliers, setSuppliers] = React.useState<ISupplier[]>([])
    const [modules, setModules] = React.useState<IModule[]>([])
    const [views, setViews] = React.useState<IView[]>([])
    const [matrixes, setMatrixes] = React.useState<IMatrix[]>([])
    const [users, setUsers] = React.useState<IUser[]>([])
    const [approvalLevels, setApprovalLevels] = React.useState<IApprovalLevel[]>([])
    const [userApprovals, setUserApprovals] = React.useState<IUserApproval[]>([])
    const [identificationTypes, setIdentificationTypes] = React.useState<IIdentificationType[]>([])
    const [categories, setCategories] = React.useState<ICategory[]>([])
    const [customers, setCustomers] = React.useState<ICustomer[]>([])
    const [customersNewRetention, setCustomersNewRetention] = React.useState<ICustomer[]>([])
    const [customersTotal, setCustomersTotal] = React.useState<number>(0)
    const [customersFilter, setCustomersFilter] = React.useState<string>("")
    const { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage } = useTableModel()

    const [loading, setLoading] = React.useState(true)

    const refreshApprovalLevels = React.useCallback(async () => {
        try {
            setApprovalLevels(await getApprovalLevelsService())
        } catch (error) {
            throw new Error()
        }
    }, [])

    const refreshUserApprovals = React.useCallback(async () => {
        try {
            setUserApprovals(await getUserApprovalsService())
        } catch (error) {
            throw new Error()
        }
    }, [])

    const refreshOffices = React.useCallback(async () => {
        try {
            setOffices(await getOfficesService())
        } catch (error) {
            throw new Error()
        }
    }, [])

    const refreshModules = React.useCallback(async () => {
        try {
            setModules(await getModuleService())
        } catch (error) {
            throw new Error()
        }
    }, [])

    const refreshViews = React.useCallback(async () => {
        try {
            setViews(await getViewsService())
        } catch (error) {
            throw new Error()
        }
    }, [])

    const refreshSuppliers = React.useCallback(async () => {
        try {
            setSuppliers(await getSuppliersService())
        } catch (error) {
            console.log(JSON.stringify(error))
        }
    }, [])

    const refreshTaxes = React.useCallback(async () => {
        try {
            setTaxes(await getTaxesService())
        } catch (error) {
            console.log(JSON.stringify(error))
        }
    }, [])

    const refreshTaxTypes = React.useCallback(async () => {
        try {
            setTaxesTypes(await getTaxTypesService())
        } catch (error) {
            console.log(JSON.stringify(error))
        }
    }, [])

    const refreshAccountTypes = React.useCallback(async () => {
        try {
            setAccountTypes(await getAccountTypessService())
        } catch (error) {
            throw new Error()
        }
    }, [])

    const refreshTaxPlans = React.useCallback(async () => {
        try {
            setTaxPlans(await getTaxPlansService())
        } catch (error) {
            throw new Error()
        }
    }, [])

    const refreshRejectionCauses = React.useCallback(async () => {
        try {
            setRejectionCauses(await getRejectionCauseService())
        } catch (error) {
            throw new Error()
        }
    }, [])

    const refreshUsers = React.useCallback(async () => {
        try {
            setUsers(await getUsersService())
        } catch (error) {
            console.log(JSON.stringify(error))
        }
    }, [])

    const refreshMatrixes = React.useCallback(async () => {
        try {
            setMatrixes(await getMatrixesService())
        } catch (error) {
            console.log(JSON.stringify(error))
        }
    }, [])

    const refreshIdentificationTypes = React.useCallback(async () => {
        try {
            setIdentificationTypes(await getIdentificationTypesService())
        } catch (error) {
            console.log(JSON.stringify(error))
        }
    }, [])

    const refreshCategories = React.useCallback(async () => {
        try {
            setCategories(await getCategoriesService())
        } catch (error) {
            console.log(JSON.stringify(error))
        }
    }, [])

    const loadInitialCustomers = React.useCallback(async () => {
        try {
            setLoading(true)
            const response = await getCustomersServicePaginated("", 25, 0)
            setCustomers(response.customers)
            setCustomersTotal(response.count)
            setLoading(false)
        } catch (error) {
            console.log(JSON.stringify(error))
        }
    }, [])

    const loadInitialCustomersWithFilter = React.useCallback(async () => {
        try {
            setLoading(true)
            const response = await getCustomersServicePaginated(customersFilter, 25, 0)
            setCustomers(response.customers)
            setCustomersTotal(response.count)
            setLoading(false)
        } catch (error) {
            console.log(JSON.stringify(error))
        }
    }, [customersFilter])

    const loadInitialCustomersWithFilterNoPage = React.useCallback(async () => {
        try {
            setLoading(true)
            const response = await getCustomersServicePaginated(customersFilter, 999999, 0)
            setCustomersNewRetention(response.customers)
            setLoading(false)
        } catch (error) {
            console.log(JSON.stringify(error))
        }
    }, [customersFilter])

    const refreshCustomers = React.useCallback(async (newPage: number, newRowsPerPage: number) => {
        try {
            setLoading(true)
            const response = await getCustomersServicePaginated(customersFilter, newRowsPerPage, newPage)
            setCustomers(current => current.concat(response.customers))
            setCustomersTotal(response.count)
            setLoading(false)
        } catch (error) {
            console.log(JSON.stringify(error))
        }
    }, [customersFilter])

    const setContextLoading = React.useCallback((state: boolean) => {
        setLoading(state)
    }, [])

    React.useEffect(() => {
        const init = async () => {
            try {
                await refreshApprovalLevels()
                await refreshIdentificationTypes()
                await refreshUsers()
                await refreshAccountTypes()
                await refreshTaxPlans()
                await refreshTaxTypes()
                await refreshTaxes()
                await refreshOffices()
                await refreshSuppliers()
                await refreshCategories()
                await loadInitialCustomers()
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        init()
    }, [refreshApprovalLevels, refreshIdentificationTypes, refreshUsers, refreshAccountTypes, refreshTaxPlans, refreshTaxTypes, refreshOffices, refreshSuppliers, refreshCategories, loadInitialCustomers, refreshTaxes])

    return (
        <ManagementContext.Provider
            value={{
                loading,
                offices,
                accountTypes,
                taxes,
                taxTypes,
                taxPlans,
                rejectionCauses,
                suppliers,
                modules,
                users,
                views,
                matrixes,
                identificationTypes,
                approvalLevels,
                userApprovals,
                categories,
                customers,
                customersNewRetention,
                customersTotal,
                customersFilter,
                customersModel: { createSortHandler, handleChangePage, handleChangeRowsPerPage, order, orderBy, page, rows, rowsPerPage },
                refreshOffices,
                refreshAccountTypes,
                refreshTaxes,
                refreshTaxTypes,
                refreshTaxPlans,
                refreshRejectionCauses,
                refreshSuppliers,
                refreshUsers,
                refreshModules,
                refreshViews,
                refreshMatrixes,
                refreshIdentificationTypes,
                refreshApprovalLevels,
                refreshUserApprovals,
                refreshCategories,
                loadInitialCustomers,
                loadInitialCustomersWithFilter,
                refreshCustomers,
                setCustomersFilter,
                setContextLoading,
                loadInitialCustomersWithFilterNoPage
            }}
            >
            {children}
        </ManagementContext.Provider>
    )
}

export default ManagementProvider
