import { Button, MenuItem, Popover, Snackbar, TextField, Typography } from '@material-ui/core'
import { FIELDS, BANKS, BANK_ACCOUNTS, deleteSupplierFileByIdService } from 'lib'
import React from 'react'
import { IMenu, IMessageConfig, ISupplier, ISupplierFile } from 'types'
import { Alert } from '@material-ui/lab'

interface Props {
    handleChange: (key: string, value: any) => void
    supplier: ISupplier
    bankCertificateFile: ISupplierFile
    setBankCertificateFile: React.Dispatch<ISupplierFile>
}

const inputStyle: React.CSSProperties = { width: "49%", marginBottom: 15 }

const SupplierBankForm = (props: Props) => {
    const { handleChange, supplier, bankCertificateFile, setBankCertificateFile } = props
    const [menu, setMenu] = React.useState<IMenu>({ anchorEl: null, selected: undefined })
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const handleDelete = async (supplierFileId: number) => {
        await deleteSupplierFileByIdService(supplierFileId)
        setMenu({ selected: undefined, anchorEl: null })
        if (supplierFileId === bankCertificateFile.supplierFileId) {
            setBankCertificateFile({} as ISupplierFile)
        }
    }
    return (
        <div className="w-full h-auto flex flex-wrap justify-between items-start">
            <TextField
                size="small"
                label={FIELDS.bankName.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.bankName.key]}
                onChange={(e) => handleChange(FIELDS.bankName.key, e.target.value)}
                select
            >
                {BANKS.map((option) => {
                    return <MenuItem key={option.value} value={option.value}>
                        {option.value}
                    </MenuItem>
                })}
            </TextField>
            <TextField
                size="small"
                label={FIELDS.bankAccountType.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.bankAccountType.key]}
                onChange={(e) => handleChange(FIELDS.bankAccountType.key, e.target.value)}
                select
            >
                {BANK_ACCOUNTS.map((option) => {
                    return <MenuItem key={option.value} value={option.value}>
                        {option.value}
                    </MenuItem>
                })}
            </TextField>
            <TextField
                size="small"
                label={FIELDS.bankAccountNumber.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.bankAccountNumber.key]}
                onChange={(e) => handleChange(FIELDS.bankAccountNumber.key, e.target.value)}
            />
            <TextField
                size="small"
                label={FIELDS.swiftCode.name}
                variant="outlined"
                style={inputStyle}
                value={supplier[FIELDS.swiftCode.key]}
                onChange={(e) => handleChange(FIELDS.swiftCode.key, e.target.value)}
            />
            <Popover
                open={Boolean(menu.anchorEl)}
                anchorEl={menu.anchorEl}
                onClose={() => setMenu({ selected: undefined, anchorEl: null })}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="flex flex-col p-4 items-center justify-center">
                    <Typography>
                        {"¿Estás seguro de eliminar este archivo?"}
                    </Typography>
                    <div className="mt-3">
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            style={{ marginRight: 5 }}
                            onClick={() => handleDelete(menu.selected.supplierFileId)}
                        >
                            {"Si"}
                        </Button>
                        <Button
                            size="small"
                            color="primary"
                            onClick={() => setMenu({ selected: undefined, anchorEl: null })}
                        >
                            {"No"}
                        </Button>
                    </div>
                </div>
            </Popover>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default SupplierBankForm