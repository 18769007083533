export const PAYMENT_TERMS = [
  {
    value: '0 días',
  },
  {
    value: '15 días',
  },
  {
    value: '25 días',
  },
  {
    value: '30 días',
  },
  {
    value: '45 días',
  },
  {
    value: '60 días',
  },
  {
    value: '90 días',
  },
  {
    value: '180 días',
  },
]